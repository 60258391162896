import React, { Component } from 'react';
// import apiPath from '../../Constants/APIPath';
import API from '../../Utils/API';
import * as actionCb from './ActionCB';
import Constants from '../../constants/baseConstants';
import {getSecureData} from '../../Utils/utils';

/*public enum AppType
    {
        Customer = 1,
        Vendor = 2,
        Driver = 3
    }*/

/**
 * call CheckVersion API
 */
// Below Website Use Get Device Info
// https://www.npmjs.com/package/react-native-device-info
export function callApiCheckVersionAPI(customerId,languageType) {
  return dispatch => {
    dispatch(actionCb.CheckAppVersionL(true));
    var postData = {
      "restaurantId":147,
      "languageId":languageType,
      "customerId":customerId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('CheckAppVersion_Front', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.CheckAppVersionS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.CheckAppVersionF(jsonResponse.message, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.CheckAppVersionF('somethings went wrong...'))
      });
  }
}



//INIT LOGIN API CALL
export function initLoginCall(phone,countryCode,password,languageType) {
  return dispatch => {
    dispatch(actionCb.LoginAttempt(true));
    var postData = {
      "username":phone,
      "deviceType":0,
      "password":password,
      "appName":147,
      "deviceName":"WEB",
      "restaurantId":147,
      "deviceToken":"",
      "languageId":languageType,
      "locationId":Constants.locationId,
      "appType":1,
      "countryCode":countryCode,
      "deviceModel":"WEB", //"deviceModel":"iPhone 12.1 x86_64",
      "appVersion":0
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('LoginVerification', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.LoginSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.LoginSuccess(jsonResponse))
          // dispatch(actionCb.LoginFailed(jsonResponse.message, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.LoginFailed('somethings went wrong...'))
      });
  }
}

//Call Forget Password Api
export function CallForgetPasswordApi(phoneno, countryCode,languageType) {
  return dispatch => {
    dispatch(actionCb.ForgetPasswordA(true));
    var postData = {
      username: phoneno,
      restaurantId: 147,
      languageId: languageType,
      countryCode: countryCode,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('ForgottPassword', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.ForgetPasswordS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.ForgetPasswordF(jsonResponse.message, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.ForgetPasswordF('somethings went wrong...'))
      });
  }
}


//Call Company Account Link Api
export function CallCompanyAccountLinkApi(customerId, addressId, companyId, languageType) {
  return dispatch => {
    dispatch(actionCb.CompanyAccountLinkA(true));
    var postData = {
      customerId: customerId,
      restaurantId: 147,
      languageId: languageType,
      companyId: companyId,
      addressId: addressId,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('linkAccountToCompanyV1', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.CompanyAccountLinkS(jsonResponse))
        } else {
          console.log("response :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.CompanyAccountLinkS(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.CompanyAccountLinkF('somethings went wrong...'))
      });
  }
}

//INIT REGISTRATION API CALL
export function initRegistrationCall(locationId,customerId,name,countrycode,phone,email,password,languageType,gender,birthDate) {
  return dispatch => {
    dispatch(actionCb.RegistrationAttempt(true));

    var postData = {
      "firstName":name,
      "emailId":email,
      "phone":phone,
      "countryCode":countrycode,
      "pass":password,
      "customerId":customerId,
      "locationId":locationId,
      "restaurantId":147,
      "deviceToken":"",
      "appVersion":"0",
      "deviceModel":"", //"deviceModel":"iPhone 12.1 x86_64",
      "deviceType":"0",
      "deviceName":"WEB",
      "loyaltynumber":"0",
      "languageId":languageType,
      "birthDate" : birthDate,
      "gender" : gender
    };


    // console.log("posData",postData)
    // return


    console.log("request :" + JSON.stringify(postData));
    return API.post('addUpdateCustomer', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.RegistrationSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.RegistrationSuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.RegistrationFailed('somethings went wrong...'))
      });
  }
}

//INIT VERIFY CUSTOMER API CALL
export function initVerifyCustomerCall(customerId,verificationCode,languageType) {
  return dispatch => {
    dispatch(actionCb.VerifyCustomerAttempt(true));
    var postData = {
      "customerId":customerId,
      "verificationCode":verificationCode,
      "locationId":Constants.locationId,
      "restaurantId":147,
      "languageId":languageType,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('verifyCustomer', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.VerifyCustomerSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.VerifyCustomerSuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.VerifyCustomerFailed('somethings went wrong...'))
      });
  }
}

//INIT RESEND MOBILE VERIFICATION CODE API CALL
export function initResendVerificationCode(customerId,phoneNumber,languageType) {
  return dispatch => {
    dispatch(actionCb.ResendMobileVerificationCodeAttempt(true));
    var postData = {
      "customerId":customerId,
      "locationId":Constants.locationId,
      "restaurantId":147,
      "languageId":languageType,
      "phone":phoneNumber
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('resendCustomerVerificationCode', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.ResendMobileVerificationCodeSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.ResendMobileVerificationCodeSuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR Mobile Verification : ", err);
        dispatch(actionCb.ResendMobileVerificationCodeFailed('somethings went wrong...'))
      });
  }
}


//INIT SOCIAL LOGIN/REGISTRATION API CALL
export function initSocialSignUpCall(email,sMediaFlag,smediaId,languageType) {
  return dispatch => {
    dispatch(actionCb.SocialSignUpAttempt(true));
    var postData = {
      "emailId":email,
      "SocialMediaFlag":sMediaFlag,
      "SocialMediaId":smediaId,
      "locationId":Constants.locationId,
      "restaurantId":147,
      "languageId":languageType,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('checkCustomerDetailsBySocialMediaV1', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.SocialSignUpSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.SocialSignUpSuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.SocialSignUpFailed('somethings went wrong...'))
      });
  }
}


//INIT REGISTRATION API CALL
export function initAddUpdateSocialCustomerCall(name,countrycode,phone,email,password,sMediaFlag,smediaId,languageType) {
  return dispatch => {
    dispatch(actionCb.addUpdateSocialCustomerAttempt(true));
    var postData = {
      "firstName":name,
      "emailId":email,
      "phone":phone,
      "countryCode":countrycode,
      "pass":password,
      "SocialMediaFlag":sMediaFlag,
      "SocialMediaId":smediaId,
      "locationId":Constants.locationId,
      "restaurantId":147,
      "deviceToken":"",
      "appVersion":1,
      "deviceModel":"WEB", //"deviceModel":"iPhone 12.1 x86_64",
      "deviceType":0,
      "deviceName":"iPhone X",
      "loyaltynumber":0,
      "languageId":languageType,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('addUpdateCustomerSocialMedia', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.addUpdateSocialCustomerSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.addUpdateSocialCustomerSuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.addUpdateSocialCustomerFailed('somethings went wrong...'))
      });
  }
}



//INIT GET ALL CATEGORIES API CALL
export function initGetAllCategoriesCall__old(languageType) {
  return dispatch => {
    dispatch(actionCb.GetCategoriesAttempt(true));
    var postData = {
      // "locationId":JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId,
      "locationId":getSecureData(Constants.LOCATIONOBJECT).locationId,
      "restaurantId":147,
      "languageId":languageType,
    };
    console.log("request :" + JSON.stringify(postData)); 
    return API.post('getMenuCategoryWIthPos_Version1', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetCategoriesSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.GetCategoriesSuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetCategoriesFailed('Fetch Categories error : ',err))
      });
  }
}

export function initGetAllCategoriesCall(languageType,customerId = 0) {
  return dispatch => {
    dispatch(actionCb.GetCategoriesAttempt(true));
    var postData = {
      // "locationId":JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId,
      "locationId":getSecureData(Constants.LOCATIONOBJECT).locationId,
      "customerId": customerId,
      "restaurantId":147,
      "languageId":languageType,
      "appVersion":"0",
      "appType":1,
      "orderType": getSecureData(Constants.ORDERTYPEID) ? getSecureData(Constants.ORDERTYPEID) : 2 
    };
    console.log("request :" + JSON.stringify(postData)); 
    return API.post('getMenuCategoryWithMenuItem', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetCategoriesSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.GetCategoriesSuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetCategoriesFailed('Fetch Categories error : ',err))
      });
  }
}

//INIT GET CATEGORIES MENU ITEMS API CALL
export function initGetCategoriesMenuItemsCall(categories,languageType) {
  return dispatch => {
    dispatch(actionCb.GetCategoriesMenuItemsAttempt(true));
    var postData = {
      "customerId":0,
      "locationId":getSecureData(Constants.LOCATIONOBJECT).locationId,
      "restaurantId":147,
      "languageId":languageType,
      "categories":categories,
      /* comment for order type */
      "orderType":(getSecureData(Constants.ORDERTYPE) == 'TakeAway') ? 1 : (getSecureData(Constants.ORDERTYPE) == 'Delivery' ? 2 : 3)
      // "orderType":(getSecureData(Constants.ORDERTYPE) == 'TakeAway') ? 1 : 2
    };
    console.log("request of menus :" + JSON.stringify(postData));
    return API.post('getAllMenuItemsWithPos_Version2_honestgreen_Front', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetCategoriesMenuItemsSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.GetCategoriesMenuItemsSuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        // dispatch(actionCb.GetCategoriesFailed('somethings went wrong...'))
        dispatch(actionCb.GetCategoriesMenuItemsFailed('somethings went wrong...'))
      });
  }
}



//INIT DELETE USER ACCOUNT API CALL
export function initDeleteUserAccountCall(customerId,languageType) {
  return dispatch => {
    dispatch(actionCb.DeleteAccountAttempt(true));
    var postData = {
      "customerId":customerId,
      "restaurantId":147,
      "languageId":languageType,
      "deviceToken":""
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('deleteCustomerAccount', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.DeleteAccountSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.DeleteAccountSuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.DeleteAccountFailed('somethings went wrong...'))
      });
  }
}


//Call Get Order List Api
export function GetOrderListApi(customerId,languageType) {
  return dispatch => {
    dispatch(actionCb.OrderSListA(true));
    var postData = {
      "customerId": customerId,
      "restaurantId": 147,
      "languageId": languageType,
      "parentLoacationId" : 1,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getOrdersV2', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.OrderSListS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.OrderSListF(jsonResponse.message, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.OrderSListF('somethings went wrong...'))
      });
  }
}

//Call Get Order Details Api
export function GetOrderDetailsApi(OrderId,languageType) {
  return dispatch => {
    dispatch(actionCb.OrderDetailsA(true));
    var postData = {
      "OrderId": OrderId,
      "restaurantId": 147,
      "languageId": languageType,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getOrdersInfoV1', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.OrderDetailsS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.OrderDetailsF(jsonResponse.message, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.OrderDetailsF('somethings went wrong...'))
      });
  }
}

//Call Get Location List Api
export function GetLocationListApi(languageType) {
  return dispatch => {
    dispatch(actionCb.GetLocationA(false));
    var postData = {
      "restaurantId": 147,
      "languageId": languageType,
      "latitude":0,
      "longitude":0,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getLocationInfoV1', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetLocationS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.GetLocationF(jsonResponse.message, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetLocationF('Fetch location error : ', err))
      });
  }
}


//CHECK SPECIAL OFFERS
export function initCheckSpecialOffersAPI(amount, customerId,languageType) {
  return dispatch => {
    dispatch(actionCb.CheckSpecialOfferA(false));
    var postData = {
      // "locationId": JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId,
      "locationId":getSecureData(Constants.LOCATIONOBJECT).locationId,
      "amount":amount,
      "restaurantId": 147,
      "languageId": languageType,
      "customerId": customerId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('checkSpecialOfferNewVersion', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.CheckSpecialOfferS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.CheckSpecialOfferS(jsonResponse, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.CheckSpecialOfferF('somethings went wrong...'))
      });
  }
}


//CHECK COMPANY DISCOUNT 
export function initCompanyCreditScoreAPI(customerId,languageType) {
  return dispatch => {
    dispatch(actionCb.CheckCompanyCreditScoreA(false));
    var postData = {
      "restaurantId": 147,
      "languageId": languageType,
      "customerId": customerId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('checkCustomerCompanyCreditScoreV1', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.CheckCompanyCreditScoreS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.CheckCompanyCreditScoreS(jsonResponse, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.CheckCompanyCreditScoreF('somethings went wrong...'))
      });
  }
}


//CHECK COMPANY DISCOUNT 
export function initAddCompanyAddressAPI(locationid,address,languageType) {
  return dispatch => {
    dispatch(actionCb.AddCompanyAddressA(false));
    var postData = {
      "restaurantId": 147,
      "languageId": languageType,
      "locationId": locationid,
      "DeliveryAddressInfo":address
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('addDeliveryAddresss', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("addDeliveryAddresssV1 response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.AddCompanyAddressS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.AddCompanyAddressS(jsonResponse, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.AddCompanyAddressF('somethings went wrong...'))
      });
  }
}

//GET DELIVERY ADDRESS API 
export function initGetDeliveryAddressAPI(locationId,customerId) {
  return dispatch => {
    dispatch(actionCb.GetDeliveryAddressA(false));
    var postData = {
      "restaurantId": 147,
      "languageId": 1,
      "locationId": locationId,
      "customerId":customerId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getDeliveryAddress_Version1', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("getDeliveryAddress_Version1 response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetDeliveryAddressS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetDeliveryAddressS(jsonResponse, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetDeliveryAddressF('somethings went wrong...'))
      });
  }
}

//DELETE DELIVERY ADDRESS API 
export function initDeleteDeliveryAddressAPI(addressId) {
  return dispatch => {
    dispatch(actionCb.DeleteDeliveryAddressA(false));
    var postData = {
      "restaurantId": 147,
      "languageId": 1,
      "deliveryaddressId":addressId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('deleteDeliveryAddress', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("deleteDeliveryAddress response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.DeleteDeliveryAddressS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.DeleteDeliveryAddressS(jsonResponse, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.DeleteDeliveryAddressF('somethings went wrong...'))
      });
  }
}

//ADD DELIVERY ADDRESS API 
export function initAddDeliveryAddressAPI(locationId,DeliveryAddressInfo,languageType) {
  return dispatch => {
    dispatch(actionCb.AddDeliveryAddressA(false));
    var postData = {
      "restaurantId": 147,
      "locationId":locationId,
      "languageId": languageType,
      "DeliveryAddressInfo":DeliveryAddressInfo
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('addDeliveryAddresss_GeoTracker', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("addDeliveryAddresssV1 response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.AddDeliveryAddressS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.AddDeliveryAddressS(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.AddDeliveryAddressF('somethings went wrong...'))
      });
  }
}

//Call Get App Message Api
export function GetAppMessageListApi(languageId) {
  return dispatch => {
    dispatch(actionCb.GetAppMessageA(false));
    var postData = {
      "restaurantId": 147,
      "languageId": languageId,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getAppMessages', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetAppMessageS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.GetAppMessageF(jsonResponse.message, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetAppMessageF('somethings went wrong...'))
      });
  }
}


//ADD ORDER API 
export function initAddOrdersAPI(OrderInfo,languageType) {
  return dispatch => {
    dispatch(actionCb.AddOrdersA(false));
    var postData = {
      "restaurantId": 147,
      "loyaltynumber":0,
      "languageId": languageType,
      "OrderInfo":OrderInfo,
      "appOrderId" : 0
    };
    console.log("request :" + JSON.stringify(postData));
    // return API.post('addOrdersV2_Website ', postData)
    return API.post('addOrdersV2', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("addOrdersV1 response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.AddOrdersS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.AddOrdersS(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.AddOrdersF('somethings went wrong...'))
      });
  }
}


//CHECK MENU ITEMS TIMES API CALL
export function initCheckMenuItemTimeAPI(contactTime,orderDate,catId,languageType) {
  return dispatch => {
    dispatch(actionCb.CheckMenuItemTimesA(false));
    var postData = {
      "restaurantId": 147,
      "contacttime":contactTime,
      "orderdate":orderDate,
      // "locationId": JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId,
      "locationId":getSecureData(Constants.LOCATIONOBJECT).locationId,
      "languageId": languageType,
      "catId":catId,
      // "isCompanyLinked": JSON.parse(localStorage.getItem(Constants.USEROBJECT)).isLinkToCompany
      "isCompanyLinked": false,//getSecureData(Constants.USEROBJECT).isLinkToCompany
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('checkMenuItemTime', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("checkMenuItemTime response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.CheckMenuItemTimesS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.CheckMenuItemTimesS(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.CheckMenuItemTimesF('somethings went wrong...'))
      });
  }
}


//GET SAVED CARDS API CALL
export function initGetSavedCardsAPI(customerId) {
  return dispatch => {
    dispatch(actionCb.GetSavedCardsA(false));
    var postData = {
      "restaurantId": 147,
      "customerId": customerId,
      // "locationId": JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId,
      "locationId":getSecureData(Constants.LOCATIONOBJECT).locationId,
      "languageId": 1
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getCustomerSavedCardDetails', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("getCustomerSavedCardDetails response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetSavedCardsS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetSavedCardsS(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetSavedCardsF('somethings went wrong...'))
      });
  }
}


//GET RE ORDER INFO API CALL
export function initGetReOrderInfoAPI(OrderId,languageType) {
  return dispatch => {
    dispatch(actionCb.GetReOrderInfoA(false));
    var postData = {
      "restaurantId": 147,
      "OrderId":OrderId,
      // "isCompanyLinked": JSON.parse(localStorage.getItem(Constants.USEROBJECT)).isLinkToCompany,
      "isCompanyLinked": getSecureData(Constants.USEROBJECT).isLinkToCompany,
      // "locationId": JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId,
      "locationId":getSecureData(Constants.LOCATIONOBJECT).locationId,
      "languageId": languageType
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getReOrdersInfoV1', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("getReOrdersInfoV1 response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetReOrderInfoS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetReOrderInfoS(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetReOrderInfoF('somethings went wrong...'))
      });
  }
}


//GET RE ORDER INFO API CALL
export function initGetItemDetailsAPI(menuitemId,languageType) {
  return dispatch => {
    dispatch(actionCb.GetItemDetailsA(false));
    var postData = {
      "restaurantId": 147,
      "menuitemId":menuitemId,
      // "customerId": JSON.parse(localStorage.getItem(Constants.USEROBJECT)).customerId,
      // "locationId": JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId,
      "customerId": getSecureData(Constants.USEROBJECT).customerId,
      "locationId":getSecureData(Constants.LOCATIONOBJECT).locationId,
      "languageId": languageType
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getMenuItemDetails_Version2', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("getMenuItemDetails_Version2 response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetItemDetailsS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetItemDetailsS(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetItemDetailsF('somethings went wrong...'))
      });
  }
}

//Call add feedback point api
export function callAddFeedbackApi(request) {
  return dispatch => {
    dispatch(actionCb.AddFeedbackA(true));
    console.log("request :" + JSON.stringify(request));
    return API.post('addFeedback', request)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.AddFeedbackS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.AddFeedbackF(jsonResponse.message, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.AddFeedbackF('somethings went wrong...'))
      });
  }
}

//Call get card listing api
export function callGetCardListApi(customerId, languageType) {
  return dispatch => {
    dispatch(actionCb.GetCardListA(true));
    var postData = {
      restaurantId: 147,
      customerId: customerId,
      languageId: languageType,
      // locationId: JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId,
      "locationId":getSecureData(Constants.LOCATIONOBJECT).locationId,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getCustomerSavedCardDetails', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetCardListS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetCardListF(jsonResponse.message, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetCardListF('somethings went wrong...'))
      });
  }
}


//Call get card details api
export function AddCardDetailsApi(request) {
  return dispatch => {
    dispatch(actionCb.AddCardDetailsA(true));
    console.log("request :" + JSON.stringify(request));
    return API.post('UpdateCustomerSavedCardDetails', request)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.AddCardDetailsS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.AddCardDetailsF(jsonResponse.message, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.AddCardDetailsF('somethings went wrong...'))
      });
  }
}


//Delete card detail api
export function callCardDeleteApi(request) {
  return dispatch => {
    dispatch(actionCb.DeleteCardDetailA(true));
    console.log("request :" + JSON.stringify(request));
    return API.post('deleteCustomerSavedCardDetails', request)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.DeleteCardDetailS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.DeleteCardDetailF(jsonResponse.message, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.DeleteCardDetailF('somethings went wrong...'))
      });
  }
}


//Call Get Waiter Listing Api
export function callGetWaiterListApi(languageType) {
  return dispatch => {
    dispatch(actionCb.WaiterListA(true));
    var postData = {
      restaurantId: 147,
      languageId: languageType,
      // locationId: JSON.parse(localStorage.getItem(Constants.LOCATIONOBJECT)).locationId,
      "locationId":getSecureData(Constants.LOCATIONOBJECT).locationId,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getWaiterDetails', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.WaiterListS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.WaiterListS(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.WaiterListF('somethings went wrong...'))
      });
  }
}

//Call Get Waiter Details Api
export function callAddRatingApi(request) {
  return dispatch => {
    dispatch(actionCb.WaiterDetailsA(true));
    console.log("request :" + JSON.stringify(request));
    return API.post('addWaiterRating', request)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.WaiterDetailsS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.WaiterDetailsF(jsonResponse.message, jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.WaiterDetailsF('somethings went wrong...'))
      });
  }
}



//CALL AVOCADOS API
export function initGetAvocadoDetailsAPI(languageType,customerId) {
  return dispatch => {
    dispatch(actionCb.GetAvocadoDetailsA(false));
    var postData = {
      "restaurantId": 147,
      "customerId": customerId,
      "locationId":getSecureData(Constants.LOCATIONOBJECT).locationId,
      "languageId": languageType
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getAvocadoDetailsByCustomerId', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("getAvocadoDetailsByCustomerId response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetAvocadoDetailsS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetAvocadoDetailsS(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetAvocadoDetailsF('somethings went wrong...'))
      });
  }
}


//CALL CHECK DELIVERY ADDRESS API
export function initCheckDeliveryAddressAPI(languageType,deliveryaddressId) {
  return dispatch => {
    dispatch(actionCb.CheckDeliveryAddressA(false));
    var postData = {
      "restaurantId": 147,
      "customerId": getSecureData(Constants.USEROBJECT).customerId,
      "locationId":getSecureData(Constants.LOCATIONOBJECT).locationId,
      "deliveryaddressId": deliveryaddressId,
      "languageId": languageType
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('isValidDeliveryAddress', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("isValidDeliveryAddress response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.CheckDeliveryAddressS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.CheckDeliveryAddressS(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.CheckDeliveryAddressF(err))
      });
  }
}



//CANCEL ORDER API
export function initCancelOrderAPI(TransactionInfo,languageType) {
  return dispatch => {
    dispatch(actionCb.CancelOrderA(false));
    var postData = {
      "TransactionInfo": TransactionInfo,
      "restaurantId": 147,
      "languageId": languageType
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('addPaymentDetail', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("addPaymentDetail response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.CancelOrderS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.CancelOrderS(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.CancelOrderF(err))
      });
  }
}

//RESTAURANT INFO API
export function initGetRestoInfoAPI(languageType) {
  return dispatch => {
    dispatch(actionCb.GetRestoInfoA(false));
    var postData = {
      "restaurantId": 147,
      "languageId": languageType
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getRestaurantAllInfoV1', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("getRestaurantAllInfo response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetRestoInfoS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetRestoInfoS(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetRestoInfoF(err))
      });
  }
}


//APPLY VOUCHER CODE API
export function initApplyVoucherCode(customerId,totalAmount,offerCode,orderType,OrderedItems,languageType) {
  return dispatch => {
    dispatch(actionCb.ApplyVoucherCodeA(false));
    var postData = {
      "restaurantId": 147,
      "languageId": languageType,
      "customerId": customerId,
      "locationId": getSecureData(Constants.LOCATIONOBJECT).locationId,
      "totalAmount": totalAmount,
      "offerCode": offerCode,
      "orderType":orderType,
      "OrderedItems": OrderedItems,
    };

    console.log("request :" + JSON.stringify(postData));
    return API.post('applyCouponCode', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("applyCouponCode response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.ApplyVoucherCodeS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.ApplyVoucherCodeS(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.ApplyVoucherCodeF(err))
      });
  }
}


//RESET PASSWORD API
export function initResetPasswordAPI(customerId, password, confirmPassword) {
  return dispatch => {
    dispatch(actionCb.ResetPasswordA(false));
    var postData = {
      "customerId":customerId,
      "password":password,
      "confirmPassword":confirmPassword,
      "restaurantId":147,
      "languageId":1    
    };

    console.log("request :" + JSON.stringify(postData));
    return API.post('resetPassword', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("resetPassword response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.ResetPasswordS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.ResetPasswordS(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.ResetPasswordF(err))
      });
  }
}


//INIT OFFICE REGISTRATION API CALL
export function initOfficeRegistrationAPI(name, countrycode, phone, email, address, comment, city, zipcode, languageType) {
  return dispatch => {
    dispatch(actionCb.OfficeRegistrationAttempt(true));
    var postData = {
      "regName":name,
      "regEmail":email,
      "regMobile":phone,
      "regCountryCode":countrycode,
      "restaurantId":147,
      "regAddress":address,
      "regComment":comment,
      "regCity":city,
      "regZipcode": zipcode,
      "languageId":languageType,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('companyRegister', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.OfficeRegistrationSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.OfficeRegistrationSuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.OfficeRegistrationFailed('somethings went wrong...'))
      });
  }
}

//INIT CALCULATE DELIVERY CHARGE API CALL
export function initDeliveryChargeAPI(addressId,customerId,locationId,totalAmount,languageId) {
  return dispatch => {
    dispatch(actionCb.DeliveryChargeAttempt(true));
    var postData = {
      "addressId": addressId,
      "customerId": customerId,
      "restaurantId": 147,
      "locationId": locationId,
      "totalAmount": totalAmount,
      "languageId": languageId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('calculateDeliveryCharges', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.DeliveryChargeSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.DeliveryChargeSuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.DeliveryChargeFailed('somethings went wrong...'))
      });
  }
}

//INIT CHECK NEXT DAY ORDER API CALL
export function initCheckNextDayOrderAPI(customerId, comapanyId, locationId, time, languageId) {
  return dispatch => {
    dispatch(actionCb.CheckNextDayOrderAttempt(true));
    var postData = {
      "customerId": customerId,
      "restaurantId": 147,
      "comapanyId": comapanyId,
      "locationId": locationId,
      "time": time,
      "languageId": languageId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('checkNextDayComanyOrder', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.CheckNextDayOrderSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.CheckNextDayOrderSuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.CheckNextDayOrderFailed('somethings went wrong...'))
      });
  }
}

//INIT GET COMPANY LIST API CALL
export function initGetCompanyListAPI(languageId) {
  return dispatch => {
    dispatch(actionCb.GetCompanyListAttempt(true));
    var postData = {
      "restaurantId": 147,
      "languageId": languageId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getCompanyList', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.GetCompanyListSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.GetCompanyListSuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetCompanyListFailed('somethings went wrong...'))
      });
  }
}

//INIT REMOVE LINKED COMPANY API CALL
export function initRemoveLinkedCompanyAPI(companyId, customerId, languageId) {
  return dispatch => {
    dispatch(actionCb.RemoveLinkedCompanyAttempt(true));
    var postData = {
      "restaurantId": 147,
      "companyId": companyId,
      "customerId": customerId,
      "languageId": languageId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('removeAccountToCompany', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.RemoveLinkedCompanySuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse.message));
          dispatch(actionCb.RemoveLinkedCompanySuccess(jsonResponse))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.RemoveLinkedCompanyFailed('somethings went wrong...'))
      });
  }
}

//INIT GET OFFERS API CALL
export function initGetBestOfferListAPI(customerId, locationId, languageId) {
  return dispatch => {
    dispatch(actionCb.GetBestOffersAttempt(true));
    var postData = {
      "restaurantId": 147,
      "customerId": customerId,
      "locationId": locationId,
      "languageId": languageId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getBestOfferList', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + JSON.stringify(jsonResponse));
        dispatch(actionCb.GetBestOffersSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetBestOffersFailed('somethings went wrong...'))
      });
  }
}


//table Booking Get Time Sloat
export function getTableReservationTimeSlotList(customerId,locationId, languageId, bookingDate, BookingTime, totalPerson){
  return dispatch => {
    dispatch(actionCb.GetTableBookingSloatAttempt(true));
    var postData = {
      "restaurantId": 147,
      "customerId": customerId,
      "locationId": locationId,
      "languageId": languageId,
      "date":bookingDate,
      "time":BookingTime,
      "noOfPeople": totalPerson
    };
    //api endpoint change at 13 july 2021
    console.log("request :" + JSON.stringify(postData));
    return API.post('getTableReservationTimeSlotDetails', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.GetTableBookingSloatSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.GetTableBookingSloatFailed('somethings went wrong...'))
      });
  }
}

//table booking pay now
export function addTableReservation(data){
  return dispatch => {
    dispatch(actionCb.addTableReservationAttempt(true));
    var postData = {
      "restaurantId": 147,
      "customerId": data.customerId,
      "locationId": data.locationId,
      "languageId": data.languageId,
      "date": data.bookingDate,
      "fromTime" : data.fromTime,
      "toTime" : data.toTime,
      "noOfPeople": data.noOfPeople,
      "tableNoOfPeople" : data.tableNoOfPeople,
      "tableAreaType": data.tableAreaType,
      "note": data.note,
    };
    console.log("postData", postData)
        // return
    //change addTableReservation to addTableReservationV1 13 july 2021
    console.log("request :" + JSON.stringify(postData));
    return API.post('addTableReservationV1', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.addTableReservationSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.addTableReservationFailed('somethings went wrong...'))
      });
  }
}

export function getTableBookingHistoryByCustomer(data){
  return dispatch => {
    dispatch(actionCb.getTableBookingHistoryByCustomerAttempt(true));
    var postData = {
      "restaurantId": 147,
      "customerId": data.customerId,
      "locationId": data.locationId,
      "languageId": data.languageId,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getTableBookingHistoryByCustomer', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.getTableBookingHistoryByCustomerSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.getTableBookingHistoryByCustomerFailed('somethings went wrong...'))
      });
  }
}

//Table Booking Cancel reservation

export function updateTableReservationBookingRequest(data){
  return dispatch => {
    dispatch(actionCb.updateTableReservationBookingRequestAttempt(true));
    var postData = {
      "restaurantId": 147,
      "customerId": data.customerId,
      "locationId": data.locationId,
      "languageId": data.languageId,
      bookingId : data.bookingId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('updateTableReservationBookingRequest', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.updateTableReservationBookingRequestSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.updateTableReservationBookingRequestFailed('somethings went wrong...'))
      });
  }
}

//GET CUSTOMER dETAILS
export function getCustomerDetails(customerId,locationId){
  return dispatch => {
    dispatch(actionCb.getCustomerDetailsAttempt(true));
    var postData = {
      "restaurantId": 147,
      "customerId": customerId,
      "locationId" : locationId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getCustomerDetailsV1', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.getCustomerDetailsSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.getCustomerDetailsFailed('somethings went wrong...'))
      });
  }
} 


//Get virtual queue
export function getTableVirtualWaitingByCustomer(data){
  return dispatch => {
    dispatch(actionCb.getTableVirtualWaitingByCustomerAttempt(true));
    var postData = {
      "restaurantId": 147,
      "customerId": data.customerId,
      "locationId": data.locationId,
      "languageId": data.languageId,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getTableVirtualWaitingByCustomer', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.getTableVirtualWaitingByCustomerSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.getTableVirtualWaitingByCustomerFailed('somethings went wrong...'))
      });
  }
}

//Add addTableVirtualWaiting
export function addTableVirtualWaiting(data){
  return dispatch => {
    dispatch(actionCb.addTableVirtualWaitingAttempt(true));
    var postData = {
      "restaurantId": 147,
      "customerId": data.customerId,
      "locationId": data.locationId,
      "languageId": data.languageId,
      "noOfPeople" : data.noOfPeople
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('addTableVirtualWaiting', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.addTableVirtualWaitingSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.addTableVirtualWaitingFailed('somethings went wrong...'))
      });
  }
}


//delete deleteTableVirtualWaiting
export function deleteTableVirtualWaiting(data){
  return dispatch => {
    dispatch(actionCb.deleteTableVirtualWaitingAttempt(true));
    var postData = {
      "restaurantId": 147,
      "customerId": data.customerId,
      "locationId": data.locationId,
      "languageId": data.languageId,
      'Id' : data.Id
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('deleteTableVirtualWaiting', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.deleteTableVirtualWaitingSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.deleteTableVirtualWaitingFailed('somethings went wrong...'))
      });
  }
}


//get getMemberofferList
export function getMemberofferList(data){
  return dispatch => {
    dispatch(actionCb.getMemberofferListAttempt(true));
    var postData = {
      "restaurantId": 147,
      "customerId": data.customerId,
      "locationId": data.locationId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getMemberofferList', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.getMemberofferListSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.getMemberofferListFailed('somethings went wrong...'))
      });
  }
}

//get getLoyaltyRewardLevelList
export function getLoyaltyRewardLevelList(locationId){
  return dispatch => {
    dispatch(actionCb.getLoyaltyRewardLevelListAttempt(true));
    var postData = {
      "restaurantId": 147,
      "locationId": locationId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getLoyaltyRewardLevelList', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.getLoyaltyRewardLevelListSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.getLoyaltyRewardLevelListFailed('somethings went wrong...'))
      });
  }
}

//get getMemberOfferTransactionList loyalty history
export function getMemberOfferTransactionList(locationId,customerId){
  return dispatch => {
    dispatch(actionCb.getMemberOfferTransactionListAttempt(true));
    var postData = {
      "restaurantId": 147,
      "locationId": locationId,
      "customerId" : customerId 
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getMemberOfferTransactionList', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.getMemberOfferTransactionListSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.getMemberOfferTransactionListFailed('somethings went wrong...'))
      });
  }
}

// getLoyaltyPointList
export function getLoyaltyPointList(data){
  return dispatch => {
    dispatch(actionCb.getLoyaltyPointListAttempt(true));
    var postData = {
      "restaurantId": 147,
      "customerId": data.customerId,
      "LocationId": data.locationId,
      "languageId" : data.languageId,
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getLoyaltyPointListV1', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.getLoyaltyPointListSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.getLoyaltyPointListFailed('somethings went wrong...'))
      });
  }
}

//get special getBestOfferList
export function getBestOfferList(data){
  return dispatch => {
    dispatch(actionCb.getBestOfferListAttempt(true));
    var postData = {
      "restaurantId": 147,
      "customerId": data.customerId,
      "locationId": data.locationId,
      "offerType" : data.offerType,
      "languageId":1
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getBestOfferList', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.getBestOfferListSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.getBestOfferListFailed('somethings went wrong...'))
      });
  }
}

//applyCouponCodeByMemberOfferCode
export function applyCouponCodeByMemberOfferCode(customerId,totalAmount,offerCode,orderType,OrderedItems,languageType) {
  return dispatch => {
    dispatch(actionCb.applyCouponCodeByMemberOfferCodeAttempt(false));
    var postData = {
      "restaurantId": 147,
      "languageId": languageType,
      "customerId": customerId,
      "locationId": getSecureData(Constants.LOCATIONOBJECT).locationId,
      "totalAmount": totalAmount,
      "offerCode": offerCode,
      "orderType":orderType,
      "OrderedItems": OrderedItems,
    };

    console.log("request :" + JSON.stringify(postData));
    return API.post('applyCouponCodeByMemberOfferCode', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("applyCouponCode response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.applyCouponCodeByMemberOfferCodeSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.applyCouponCodeByMemberOfferCodeSuccess(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.applyCouponCodeByMemberOfferCodeFailed(err))
      });
  }
}

//get add/delete fav item
export function addRemoveFavItem(data) {
  return dispatch => {
    dispatch(actionCb.addRemoveFavItemAttempt(false));
    var postData = {
      "restaurantId": 147,
      "languageId": 1,
      "customerId": getSecureData(Constants.USEROBJECT) != undefined ? getSecureData(Constants.USEROBJECT).customerId : 0,
      "menuItemId": data.menuitemId,
      "menuitemId" : data.menuitemId,
    };

    console.log("request :" + JSON.stringify(postData));
    return API.post(data.url, postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("applyCouponCode response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.addRemoveFavItemSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.addRemoveFavItemSuccess(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.addRemoveFavItemFailed(err))
      });
  }
}

//get fav items
export function getFavItem(data = {}) {
  return dispatch => {
    dispatch(actionCb.getFavItemAttempt(false));
    var postData = {
      "restaurantId": 147,
      "languageId": 1,
      "customerId": getSecureData(Constants.USEROBJECT) != undefined ? getSecureData(Constants.USEROBJECT).customerId : 0,
      "locationId": getSecureData(Constants.LOCATIONOBJECT) != undefined ? getSecureData(Constants.LOCATIONOBJECT).locationId : 1,
    };

    console.log("request :" + JSON.stringify(postData));
    return API.post('getMenuItemFavorite', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("applyCouponCode response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.getFavItemSuccess(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.getFavItemSuccess(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.getFavItemFailed(err))
      });
  }
}

//ADD ORDER Before Check
export function MenuTimeCheckingBeforeOrderPlace(OrderInfo,languageType) {
  return dispatch => {
    dispatch(actionCb.AddOrdersA(false));
    var postData = {
      "restaurantId": 147,
      "loyaltynumber":0,
      "languageId": languageType,
      "OrderInfo":OrderInfo,
      "appOrderId" : 0
    };
    console.log("request :" + JSON.stringify(postData));
    // return API.post('addOrdersV1', postData)
    return API.post('MenuTimeCheckingBeforeOrderPlace', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        if (jsonResponse.status == 1) {
          console.log("addOrdersV1 response :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.AddOrdersS(jsonResponse))
        } else {
          console.log("error :" + JSON.stringify(jsonResponse));
          dispatch(actionCb.AddOrdersS(jsonResponse, jsonResponse.message))
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.AddOrdersF('somethings went wrong...'))
      });
  }
}

//get special getBestOfferList
export function getContentDataById(data){
  return dispatch => {
    dispatch(actionCb.getContentDataAttempt(true));
    var postData = {
      "restaurantId": 147,
      "contentId":data.contentId
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('getContentDataById', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.getContentDataSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.getContentDataFailed('somethings went wrong...'))
      });
  }
}

//get special getBestOfferList
export function contactUsMail(data){
  return dispatch => {
    dispatch(actionCb.contactUsMailAttempt(true));
    var postData = {
      "name": data.name,
      "email":data.email,
      "phone" : data.phoneNumber,
      "description" : data.description
    };
    console.log("request :" + JSON.stringify(postData));
    return API.post('contactUsMail', postData)
      .then((response) => {
        var stringResponse = response.data.d;
        var jsonResponse = JSON.parse(stringResponse);
        console.log("response :" + jsonResponse);
        dispatch(actionCb.contactUsMailSuccess(jsonResponse))
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        dispatch(actionCb.contactUsMailFailed('somethings went wrong...'))
      });
  }
}

