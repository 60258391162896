import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import HeaderWhiteMenuHalf from '../Components/HeaderWhiteMenuHalf';
import HeaderSignin from '../Components/HeaderSignin';
import FooterMenu from '../Components/FooterMenu';
import { Strings } from '../constants/Localization';
import Constant from '../constants/baseConstants';
import { setSecureData, getSecureData } from '../Utils/utils';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
import { Dropdown } from 'react-bootstrap';


//Honestgreen website reference
// https://honestgreens.smartrestaurants.com/vieworder.aspx

class SignIn extends Component {

    constructor(props) {
        super(props)

        this.state = {
            countryCode: '+44',
            phoneNumber: '',
            password: '',
            forgetPhoneNo: '',
            forgetCountryCode: '+44',
            socialId: '',
            loginType: 0,

            errors: {
                countryCodeError: '',
                phoneNumberError: '',
                passwordError: '',
                forgetCountryCodeError: '',
                forgetPhoneNumberError: '',
            },

            isGoogleSignedIn: false,
            googleUserName: '',

            languageType: 1,
        }
    }

    componentDidMount() {
        // AOS.init({
        //     duration: 1000
        // })

        // if (!localStorage.hasOwnProperty(Constant.CHECKINFO)) {
        //     this.props.callCheckVersion(0, this.state.languageType).then(() => {
        //         if (this.props.data.status == 1) {
        //             setTimeout(() => {
        //                 // localStorage.setItem(Constant.CHECKINFO, JSON.stringify(this.props.data.result))
        //                 setSecureData(Constant.CHECKINFO, this.props.data.result)
        //             }, 500)
        //         } else {
        //         }
        //     })
        // }

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let value = localStorage.getItem(Constant.USEROBJECT);
            let value = getSecureData(Constant.USEROBJECT);
            console.log('value=' + value);
            if (value !== null && value !== undefined)
                // this.props.history.push({ pathname: '/Categories' })
                this.props.history.push({ pathname: '/MenuItems' })
        }
        else {
            console.log('value=');
        }

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        // if (session != null && session.length > 0) {
        //     session = JSON.parse(session);
        if (mLanguageType != null) {
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here english
                // this.setState({ languageType: mLanguageType })
                this.setState({ languageType: mLanguageType, languageTypeCode: "en-GB" })

            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here spanish
                    // this.setState({ languageType: mLanguageType })
                    this.setState({ languageType: mLanguageType, languageTypeCode: "es" })

                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
            this.setState({ languageType: 1, languageTypeCode: "en-GB" })

        }


    }

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("sign in page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST, messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST)
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType, languageTypeCode: "en-GB" })

                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType, languageTypeCode: "es" })

                        }
                        console.log('language type  :' + languageType);
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType });
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    onEnterForgotPassword(event) {
        if (event.key === 'Enter') {
            this.validateForgetPassword()
        }
    }

    onEnterPassword(event) {
        if (event.key === 'Enter') {
            this.onSignInClick()
        }
    }

    onMobileEnter(str, type) {
        if (str.match("^[0-9]*$") != null) {
            if (type == 1) {
                this.setState({ phoneNumber: str })
            } else if (type == 2) {
                this.setState({ forgetPhoneNo: str })
            }
        }
    }

    onSignInClick = () => {

        var ccodeerror = this.state.countryCode == '0' ? Strings.error_msg_country_code_react : '';
        var phError = this.state.phoneNumber.length < 9 ? Strings.error_msg_phone_no_react : '';
        var passError = this.state.password.length == 0 ? Strings.error_msg_password_react : '';

        this.setState({ errors: { countryCodeError: ccodeerror, phoneNumberError: phError, passwordError: passError } })

        setTimeout(() => {
            if (this.state.errors.countryCodeError == '' && this.state.errors.phoneNumberError == '' && this.state.errors.passwordError == '') {
                this.props.SignInAPICall(this.state.phoneNumber, this.state.countryCode, this.state.password, this.state.languageType).then(() => {
                    if (this.props.data.status == 1) {
                        if (this.props.data.result != null) {
                            setTimeout(() => {
                                setSecureData(Constant.USEROBJECT, this.props.data.result)
                                // this.props.history.push({ pathname: '/MenuItems' })
                                window.location.href = '/MenuItems'
                                // this.props.history.goBack()
                                // if (this.props.data.result.isMobileVerify) {
                                //     setSecureData(Constant.USEROBJECT, this.props.data.result)
                                //     this.props.history.push({ pathname: '/MenuItems' })
                                // }
                                // else {
                                //     setSecureData(Constant.USEROBJECT, this.props.data.result)
                                //     this.props.history.push({ pathname: '/MobileVerification' })
                                // }
                            }, 100)
                        }
                    } else {
                        alert(this.props.data.message)
                    }
                })
            } else {
                console.error('Not valid form')
            }
        }, 100)
    }

    //Call Forget Passoword Api
    validateForgetPassword = () => {
        // event.preventDefault();

        var ccodeerror = this.state.forgetCountryCode == '0' ? Strings.error_msg_country_code_react : '';
        var phError = this.state.forgetPhoneNo.length < 9 ? Strings.error_msg_phone_no_react : '';

        this.setState({ errors: { forgetCountryCodeError: ccodeerror, forgetPhoneNumberError: phError } })

        setTimeout(() => {
            if (this.state.errors.forgetCountryCodeError == '' && this.state.errors.forgetPhoneNumberError == '') {
                this.props.forgetPasswordApi(this.state.forgetPhoneNo, this.state.forgetCountryCode, this.state.languageType).then(() => {
                    setTimeout(() => {
                        if (this.props.data.status == 1) {
                            alert(this.props.data.message)
                        } else {
                            alert(this.props.error)
                        }
                    }, 100)
                })
            }
        }, 100)

    }

    render() {
        // The forwardRef is important!!
        // Dropdown needs access to the DOM node in order to position the Menu
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                href=""
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
                className="form-control"
            >
                {children}
            &#x25bc;
            </a>
        ));

        // forwardRef again here!
        // Dropdown needs access to the DOM of the Menu to measure it
        const CustomMenu = React.forwardRef(
            ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
                // const [value, setValue] = useState('');
                const value = "";
                return (
                    <div
                        ref={ref}
                        style={style}
                        className={className}
                        aria-labelledby={labeledBy}
                    >
                        {/* <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => alert(e.target.value)}
                    value={value}
                /> */}
                        <ul>
                            {React.Children.toArray(children).filter(
                                (child) =>
                                    !value || child.props.children.toLowerCase().startsWith(value),
                            )}
                        </ul>
                    </div>
                );
            },
        );

        const responseGoogle = (response) => {
            if (response.error == null) {
                var resObj = response.profileObj;
                console.log("google plus details : ", resObj)
                // return
                if (resObj != null) {
                    this.props.googleSignUpCall(resObj.email, 3, resObj.googleId, this.state.languageType).then(() => {
                        if (this.props.data.status == 1) {
                            setTimeout(() => {
                                if (this.props.data.result != null) {
                                    if (this.props.data.result.isCustomerExists) { //New user redirect in register screen
                                        console.log("google plus api response : " + this.props.data.result)
                                        this.props.history.push({ pathname: '/SignUp', isFromSocialMedia: true, name: resObj.name, emailId: resObj.email })
                                    }
                                    else {
                                        setSecureData(Constant.USEROBJECT, this.props.data.result);
                                        this.props.history.push({ pathname: '/MenuItems' })
                                        // if (this.props.data.result.isMobileVerify) {
                                        //     // localStorage.setItem(Constant.USEROBJECT, JSON.stringify(this.props.data.result))
                                        //     setSecureData(Constant.USEROBJECT,this.props.data.result);
                                        //     this.props.history.push({ pathname: '/MenuItems' })
                                        //     // this.props.history.push({ pathname: '/Categories' })
                                        // }
                                        // else {
                                        //     // localStorage.setItem(Constant.USEROBJECT, JSON.stringify(this.props.data.result))
                                        //     setSecureData(Constant.USEROBJECT,this.props.data.result);
                                        //     this.props.history.push({ pathname: '/MobileVerification' })
                                        // }
                                    }
                                }
                            }, 200)
                        }
                        else {
                            alert(this.props.error)
                        }
                    })
                }
            } else {
                // alert(response.error)
                console.log("google plus fail :" + response.error);
            }
        }

        const loginWithFacebook = (response) => {
            if (response.error == null) {
                console.log("login with facebook  :" + JSON.stringify(response));
                console.log("login with facebook data ------------ ", response.userID, response.name, response.email,);
                if (response.status != "unknown") {
                    this.props.googleSignUpCall(response.email, 1, response.userID, this.state.languageType).then(() => {
                        if (this.props.data.status == 1) {
                            setTimeout(() => {
                                if (this.props.data.result != null) {
                                    if (this.props.data.result.isCustomerExists) { //New user redirect in register screen
                                        console.log("facebook api response : " + this.props.data.result)
                                        this.props.history.push({ pathname: '/SignUp', isFromSocialMedia: true, name: response.name, emailId: response.email })
                                    }
                                    else {
                                        if (this.props.data.result.isMobileVerify) {
                                            // localStorage.setItem(Constant.USEROBJECT, JSON.stringify(this.props.data.result))
                                            setSecureData(Constant.USEROBJECT, this.props.data.result);
                                            // this.props.history.push({ pathname: '/Categories' })
                                            this.props.history.push({ pathname: '/MenuItems' })
                                        }
                                        else {
                                            // localStorage.setItem(Constant.USEROBJECT, JSON.stringify(this.props.data.result))
                                            setSecureData(Constant.USEROBJECT, this.props.data.result);
                                            this.props.history.push({ pathname: '/MobileVerification' })
                                        }
                                    }
                                }
                            }, 200)
                        }
                        else {
                            alert(this.props.error)
                        }
                    })
                }
            }
            else {
                console.log("login with facebook fail  :" + JSON.stringify(response.error));
            }
        }

        return (
            <div className="loginpage white nonsticy" style={{}}>
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}
                {/* <HeaderSignin logoType={1} headerLbl={Strings.strHeaderSignIn} isCategory={true} /> */}
                <HeaderWhiteMenuHalf isHideOrderType={true} logoType={1} headerLbl={'Sign In'} changeLanguageEvent={this.languageChangeClickEvent}/>
                {/* <HeaderHomeMenu /> */}
                {/* <HeaderWhiteMenu logoType={1} changeLanguageEvent={this.languageChangeClickEvent} /> */}
                <div className="content_wrap sgn signinForm">
                    <div className="container">
                        {/* Show forget password dialog*/}
                        <div className="modal fade forgot-modal" id="forgotmodal" role="dialog" >
                            <div className="modal-dialog modal-dialog-wht modal-dialog-centered " role="document">
                                <div className="modal-content">
                                    <div className="modal-header modal-header-wht ">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ forgetPhoneNo: '', errors: '' }) }}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <h3 style={{ marginTop: 15, textTransform: 'uppercase', fontFamily: 'kg_happyregular', color: '#000000' }}>{Strings.lbl_forget_password_react}</h3>
                                        <div className="row">
                                            <div className="col-5 col-md-2 pr-0 smDiv">
                                                <label type="text" className="form-control lblPhone">+44</label>
                                                {/* <select className="form-control" value={this.state.forgetCountryCode} onChange={e => { this.setState({ forgetCountryCode: e.target.value }) }}>
                                                    <option>ES +44</option>
                                                </select> */}
                                                {/* <Dropdown onSelect={(e) => this.setState({ forgetCountryCode: e })}>
                                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                        {this.state.forgetCountryCode == 0 ? "Code " : this.state.forgetCountryCode}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu as={CustomMenu} style={{ height: "200px", overflow: "scroll" }}>
                                                        <Dropdown.Item eventKey="+7">+7</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+30">+30</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+31">+31</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+32">+32</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+33">+33</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+34">+34</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+36">+36</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+39">+39</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+40">+40</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+41">+41</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+43">+43</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+44">+44</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+45">+45</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+46">+46</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+47">+47</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+48">+48</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+49">+49</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+90">+90</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+298">+298</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+350">+350</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+351">+351</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+352">+352</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+353">+353</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+354">+354</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+355">+355</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+356">+356</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+357">+357</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+358">+358</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+359">+359</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+370">+370</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+371">+371</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+372">+372</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+373">+373</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+374">+374</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+375">+375</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+376">+376</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+377">+377</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+378">+378</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+379">+379</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+380">+380</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+381">+381</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+382">+382</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+383">+383</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+385">+385</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+386">+386</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+387">+387</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+389">+389</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+420">+420</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+421">+421</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+423">+423</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+994">+994</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+995">+995</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+3491">+3491</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+91">+91</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown> */}
                                            </div>
                                            <div className="col-7 col-md-8 lmDiv">
                                                <input type="text" placeholder={Strings.lbl_forget_phone_number_react} className="form-control" maxLength={11} onKeyPress={event => this.onEnterForgotPassword(event)} value={this.state.forgetPhoneNo} onChange={e => this.onMobileEnter(e.target.value, 2)} />
                                            </div>
                                        </div>
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.forgetCountryCodeError}</span>
                                            <span className='errorMsg'>{this.state.errors.forgetPhoneNumberError}</span>
                                        </div>
                                    </div>
                                    <div className="modal-footer modal-footer-white" >
                                        {/* <button id='btnForgotPassword' onClick={this.validateForgetPassword} type="submit" className="btn btn-primary">{Strings.Submit_web}</button> */}
                                        <a href="javascript:void(0);" onClick={this.validateForgetPassword} className="btn btn-SignIn btn-price">{Strings.Submit_web}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="loginwrap sign-btm-spc">
                            {/* <h1>{Strings.btn_signin_react}</h1> */}
                            <div className="row">
                                <div className="col-md-7 col-lg-6 loginFormCust max-460">
                                    <div className="form-group">
                                        <label>{Strings.lbl_phone_number_react}</label>
                                        <div className="row">
                                            <div className="col-5 col-md-3 pr-0 smDiv">
                                            <label type="text" className="form-control lblPhone">+44</label>
                                                {/* <Dropdown onSelect={(e) => this.setState({ countryCode: e })}>
                                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                        {this.state.countryCode == 0 ? "Code " : this.state.countryCode}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu as={CustomMenu} style={{ height: "200px", overflow: "scroll" }}>
                                                        <Dropdown.Item eventKey="+7">+7</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+30">+30</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+31">+31</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+32">+32</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+33">+33</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+34">+34</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+36">+36</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+39">+39</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+40">+40</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+41">+41</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+43">+43</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+44">+44</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+45">+45</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+46">+46</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+47">+47</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+48">+48</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+49">+49</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+90">+90</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+298">+298</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+350">+350</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+351">+351</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+352">+352</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+353">+353</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+354">+354</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+355">+355</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+356">+356</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+357">+357</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+358">+358</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+359">+359</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+370">+370</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+371">+371</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+372">+372</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+373">+373</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+374">+374</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+375">+375</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+376">+376</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+377">+377</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+378">+378</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+379">+379</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+380">+380</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+381">+381</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+382">+382</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+383">+383</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+385">+385</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+386">+386</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+387">+387</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+389">+389</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+420">+420</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+421">+421</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+423">+423</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+994">+994</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+995">+995</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+3491">+3491</Dropdown.Item>
                                                        <Dropdown.Item eventKey="+91">+91</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown> */}
                                            </div>
                                            <div className="col-7 col-md-9 lmDiv">
                                                <input type="text" className="form-control" maxLength={11} value={this.state.phoneNumber} onChange={e => this.onMobileEnter(e.target.value, 1)} />
                                            </div>
                                        </div>
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.countryCodeError}</span>
                                            <span className='errorMsg'>{this.state.errors.phoneNumberError}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>{Strings.lbl_password_react_sign_in}</label>
                                        <input type="password" className="form-control" id="txtpw" onKeyPress={event => this.onEnterPassword(event)} maxLength={75} value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.passwordError}</span>
                                        </div>
                                    </div>
                                    <div className="form-group cust_sign_flex  sign-rw">
                                        <a href="#" data-toggle="modal" data-target="#forgotmodal" data-backdrop="static" data-keyboard="false" className="forgot">{Strings.lbl_forget_password_react}</a>
                                        <div className="signupsignin">
                                            {/* <a href="javascript:void(0);" className="btn btn-secondary" id="btnSignIn" onClick={()=>this.onSignInClick()}>{Strings.btn_signin_react}</a> */}
                                            <a href="javascript:void(0);" className="btn btn-primary btn-large" id="btnSignIn" onClick={() => this.onSignInClick()}>{Strings.btn_signin_react}</a>
                                            {/* <a href="javascript:void(0);" className="btn btn-SignIn btn-price" id="btnSignIn" onClick={() => this.onSignInClick()}>{Strings.btn_signin_react}</a> */}
                                            
                                        </div>
                                    </div>
                                    
                                    {/* <div className="loginimg">

                                        {(this.state.isGoogleSignedIn == false) ? <GoogleLogin
                                            // clientId="921834939910-od5oq1r2htpdtftflm7mf55osptmcq7s.apps.googleusercontent.com"
                                            clientId="978221704755-e4a2atvcsfol27ei8n6437pq7c1e514a.apps.googleusercontent.com"
                                            cookiePolicy={"single_host_origin"}
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            render={({ onClick, disabled }) => (
                                                //Old view google plus
                                                //  <a href="" onClick={onClick} disabled={disabled}><img src="/images/gp.png" alt="" /></a>
                                                <a href="javascript:void(0);" onClick={onClick}><img disabled={disabled} src="images/new_gp.png" alt="" />{Strings.btn_google_plus_react}</a>
                                            )} /> : <button onClick={() => this.setState({ isGoogleSignedIn: false })}>{Strings.lbl_title_logout_react} {this.state.googleUserName}</button>}

                                        <FacebookLogin
                                            // appId="240943480330909" //App id from facebook developer 
                                            appId="589346175081071" //App id from facebook developer  from emma account
                                            fields="name,email,picture"
                                            scope="public_profile"
                                            callback={loginWithFacebook}
                                            icon={false}
                                            render={renderProps => (
                                                //Old view facebook
                                                // <a onClick={renderProps.onClick} href="#"><img src="images/fb.png" alt="" /></a>
                                                <a onClick={renderProps.onClick} href="javascript:void(0);"><img src="images/new_fb.png" alt="" />{Strings.btn_facebook_react}</a>
                                            )}
                                        />
                                    </div> */}
                                    <div className="form-group cust_sign_flex signup-rw">
                                        <label  className="dont">{Strings.lbl_no_account_react}</label>
                                        <div className="signupsignin">
                                            {/* <a href="/SignUp" className="btn btn-primary btn-price">{Strings.btn_signup_react}</a> */}
                                            <a href="/SignUp" className="btn btn-primary btn-large">{Strings.btn_signup_react}</a>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMenu />
            </div>
        )
    }
}

function mapStateToProps(state) {
    console.log('isLoaging SignIn ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    callCheckVersion: (customerId, languageType) => dispatch(HGActions.callApiCheckVersionAPI(customerId, languageType)),
    SignInAPICall: (phone, countryCode, password, languageType) => dispatch(HGActions.initLoginCall(phone, countryCode, password, languageType)),
    googleSignUpCall: (email, sMediaFlag, smediaId, languageType) => dispatch(HGActions.initSocialSignUpCall(email, sMediaFlag, smediaId, languageType)),
    forgetPasswordApi: (forgetPhoneNo, forgetCountryCode, languageType) => dispatch(HGActions.CallForgetPasswordApi(forgetPhoneNo, forgetCountryCode, languageType)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);