import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import Constant from '../constants/baseConstants';
import Constants from '../constants/baseConstants';
import HeaderSignin from '../Components/HeaderSignin';
import FooterMenu from '../Components/FooterMenu';
import { Strings } from '../constants/Localization';
import AlertDialog from '../Components/AlertDialog';
import {setSecureData, getSecureData} from '../Utils/utils';
import AOS from 'aos';
import 'aos/dist/aos.css';
import queryString from 'query-string';
import { ThemeProvider } from 'react-bootstrap';
import HeaderWhiteMenuHalf from '../Components/HeaderWhiteMenuHalf';
// import Feed from "react-instagram-authless-feed"
import InstagramEmbed from 'react-instagram-embed';
import axios from "axios";
import cors from 'cors';



const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

class AboutUs extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customerId : 0,
            activeCardID : -1,
            isLoading:false,
            errors: {
                errBookingDate: '',
                errBookingTime: '',  
                errBookingSlotTime : '',              
            },
            isAlertVisible: false,
            alertMessage: '',
            btnLeft: '',
            btnRight: '',
            isMultiButton: false,
            dialogType:1, 
            languageType: 1, //default language type
            minBookingDate : '', 
            minBookingTime : '',
            totalPerson : 1, 
            bookingDate: '',
            bookingTime: '',
            componentLoadStep : 'step2',
            bookingTimeSloat  :[],
            bckBtnTable : '',
            payNowResponse : {},

            fromTime : '',
            toTime : '',
            noOfPeople : 1,
            tableNoOfPeople : 1,
            restaurantId : '',
            locationId : 1,
            languageId: 1,
            date : '',
            tableBookingPerPersonCharge : 5,
            virtualQueData : [],
            offerList : [],
            offerLevelPoint : 0,
            level : 0,
            levelName : '',
            MemberOfferPointAmount : 0,
            ContentData : {},

        }
    }
    componentDidMount(){
        
        let params = queryString.parse(this.props.location.search)
        if (params.customerId) {
            console.log('query string -------------> ', params)
        }
        

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                // this.setState({ languageType: mLanguageType })
                this.setState({ languageType: mLanguageType,languageTypeCode:"en-GB"})
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    // this.setState({ languageType: mLanguageType })
                    this.setState({ languageType: mLanguageType,languageTypeCode:"es"})
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        var today = new Date().toISOString().split('T')[0];
        this.setState({ minBookingDate : today})

        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        this.setState({ minBookingTime : today})

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                if (userObject != null) {
                     this.setState({customerId : userObject.customerId});
                    console.log('login user :' + JSON.stringify(userObject));
                }
            }
        }

        this.getContentDataById()
        // this.getInstaFeed()
    }

    getInstaFeed = () => {
        axios
        .get("https://www.instagram.com/jimmysportsbar?__a=1")
        .then((response) => {
          console.log("getInstaFeed",response);
        })
        .catch((error) => {
            console.log("getInstaFeed",error);
        });
    }

    checkUserLogin = () => {
        // this.setState({isContentLoading:true})
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
            if (userObject !== null) {
                this.getBestOfferList()
            }
            else {
                this.props.history.push({ pathname: '/' })
                return
            }

        }
        else {
            this.props.history.push({ pathname: '/' })
        }
    }

    getContentDataById = () => {
        // let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId
        // let userObject = getSecureData(Constant.USEROBJECT);
        // this.setState({locationId : locationId})
        var data = {
            // locationId: locationId,
            // customerId: userObject.customerId,
            // languageId: this.state.languageType,
            // offerType : 1
            contentId : 3
        }
        console.log("offerList data",data)
        if(data.contentId != ''){
            this.props.getContentDataById(data).then(() => {

                console.log("ContentData",this.props.data)
                if (this.props.data.status == 1) {
                    this.setState({
                        ContentData : this.props.data.result.ContentData.length > 0 ? this.props.data.result.ContentData[0] : {},
                    })
    
    
                }
                else{
                    alert('Something went wrong')
                }
    
                
    
            })
            .catch((err)=>{
                console.log('error ',err)
            })
        }
    }

    GetLocationListApi = () => {
        this.props.AppMessageApi(this.state.languageType).then(() => {

            if (this.props.data.status == 1) {
                this.setState({tableBookingPerPersonCharge : this.props.data.result.tableBookingPerPersonCharge})
            }

        })
    }

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my account page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST,messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }



 

    componentDidUpdate(){
        // var iframe = document.getElementById("embedIFrame_c704cf5a5c08921feaa9d68deafc4b68060341cbfw7rqj");
        // var elmnt = iframe.contentWindow.document.getElementsByTagName("a")[0];
        // console.log("elmnt",elmnt)
        
        // elmnt.style.display = "none";
    }
  

    render() {
        return (
            <div className="loginpage white nonsticy" style={{}}> 
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}
                {/* <HeaderMenu /> */}
                {/* Old header */}
               <HeaderWhiteMenuHalf isHideOrderType={true} headerLbl={'About Us'} isCategory={true} 
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/> 
                <AlertDialog 
                isAlertVisible={this.state.isAlertVisible} 
                msgTitle={this.state.alertMessage} 
                isMultibutton={this.state.isMultiButton} 
                btnLeftClick={() => { 
                    this.setState({ isAlertVisible: false });
                    // window.location.href = Constant.mainURL
                    return
                 }} 
                 btnRightClick={() => { 
                     this.onRightClick() }} 
                btnLeftTitle={this.state.btnLeft} 
                btnRightTitle={this.state.btnRight} />
                {/* <HeaderSignin logoType={1} headerLbl={Strings.reset_pass_web} isCategory={true} /> */}
                
                <div className="content_wrap">
                    <div className="container">
                        {/* <div className="book-tab-backbtn" style={{float: 'right'}}>
                                
                                <a  className="btn btn-primary order-btn book-back-btn" href="/BookTableList" >Back</a>
                        </div> */}
                        {/* <nav class="braedCrumbs" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/Rewards">Rewards</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Best Offer</li>
                            </ol>
                        </nav> */}
                        <div className="user booktable-wrp fo-btm-spc">

                            {/* <div className="row">
                                <div className="col-md-12  col-lg-12 center" style={{margin:'20px auto'}}>
                                    <div className="content" dangerouslySetInnerHTML={{ __html: this.state.ContentData.description }} >
                                    </div>
                                </div>
                            </div> */}

                            <div className="h-welcom-sec section aboutSec">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 lcol">
                                            <img src="images/bg/about-us.png" alt="" />
                                        </div>
                                        <div className="col-md-6 rcol">
                                        <div className="h-welcont-col">
                                            <div className="wel-head">
                                            <h4 class="whead sub-head">About Us</h4>
                                            <h3 class="whead">Jimmy’s Sports Bar</h3>
                                            </div>
                                            <p>
                                            Our founder, Harry, had a big job on his hands with a huge refurb from the former Devonshire Snooker Club. Named ‘Jimmy’s’ after Jimmys End in St James Northampton, Harry’s aim was to offer a new lease of life to the area, taking inspiration from London venues. Our inside bar area has been open since April 2019 with the rooftop being built during Covid-19, where it was encouraged for people to sit in garden areas. 
                                            </p>
                                            <p>
                                            {" "}
                                            Our team believe that we offer a completely different experience to anywhere else in the area and we’re super proud to be able to say we work for Jimmy’s Sports
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="instaFeed" >
                                {/* <Feed userName="jimmysportsbar" className="Feed" classNameLoading="Loading" limit="false"/> */}
                                {/* <InstagramEmbed
                                     url='https://instagr.am/p/CRgFOLSHHiu/'
                                    clientAccessToken='321015223063497|1357745150b9681ddc54fe63cd7d351b'
                                    maxWidth={320}
                                    hideCaption={false}
                                    containerTagName='div'
                                    protocol=''
                                    injectScript
                                    onLoading={() => {}}
                                    onSuccess={() => {}}
                                    onAfterRender={() => {}}
                                    onFailure={() => {}}
                                /> */}

                                

                            {/* <iframe src='https://www.instagram.com/jimmyssportsbar/embed' width='100%' height='100%' style={{top:0}}></iframe> */}

                            <div class="embedsocial-hashtag" data-ref="c704cf5a5c08921feaa9d68deafc4b68060341cb" >
                                <div className="row" >
                                    <div className="col text-center followDiv">
                                        <div class="text-center" >
                                            <h1>Follow On Instagram </h1>
                                        <a href="https://www.instagram.com/jimmysportsbar/?hl=en" target="_blank" className="followIntaBtn" >Follow</a>
                                        </div>   
                                   
                                        
                                    </div>
                                    
                                    
                                    
                                </div>
                                {/* <a class="feed-powered-by-es" href="https://embedsocial.com/social-media-aggregator/" target="_blank" title="Powered by EmbedSocial">Powered by EmbedSocial<span>→</span></a> */}
                            </div>
                                {/* 
                                <blockquote class="instagram-media" data-instgrm-version="7" >
                                <a href="https://www.instagram.com/p/BT8cmZRlkVJ/"></a> 
                                </blockquote> */}

                            </div>
                            

                            <div class=" h-orderbx-rw innerpageThreeCardBoxBtn conatctBox">
                                <div className="row m-0">
                                    <div className="col-md-4 p-0">
                                    <div class="h-orderbx-wrp">
                                        <div class="h-orderbx-img">
                                        <img class="bgcardbdy" src="images/bg/bg-up-1.png" />
                                        </div>
                                        {/* <div class="bgcardbdy" style={{background: `url("images/bg/bg-up-1.png") no-repeat`,display: 'block',height:'210px', width: 'auto', objectFit: 'cover', backgroundColor: 'black', opacity: '0.9'}} > */}
                                        <div class="h-orderbx-cont">
                                        <h4 class="head-h2">Order Online</h4>
                                        <a href="/categories" className="order-btn">
                                            Start Ordering
                                        </a>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                    </div>
                                    <div className="col-md-4 p-0">
                                    <div class="h-orderbx-wrp">
                                        <div class="h-orderbx-img">
                                        {" "}
                                        <img class="bgcardbdy" src="images/bg/bg-up-2.png" />
                                        </div>
                                        {/* <div class="bgcardbdy" style={{background: `url("images/bg/bg-up-2.png") no-repeat`,display: 'block',height:'210px', width: 'auto', objectFit: 'cover', backgroundColor: 'black', opacity: '0.9'}}  > */}
                                        <div class="h-orderbx-cont">
                                        <h4 class="head-h2">Offers</h4>
                                        <a href="/BestOfferList" className="order-btn">
                                            View All Offers
                                        </a>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                    </div>
                                    <div className="col-md-4 p-0">
                                    <div class="h-orderbx-wrp">
                                        <div class="h-orderbx-img">
                                        {" "}
                                        <img class="bgcardbdy" src="images/bg/bg-up-3.png" />
                                        </div>
                                        {/* <div class="bgcardbdy" style={{background: `url("images/bg/bg-up-3.png") no-repeat`,display: 'block',height:'210px', width: 'auto', objectFit: 'cover', backgroundColor: 'black', opacity: '0.9'}} > */}
                                        <div class="h-orderbx-cont">
                                        <h4 class="head-h2">Book a Table</h4>
                                        {
                                            getSecureData(Constants.LOCATIONOBJECT) != undefined && getSecureData(Constants.LOCATIONOBJECT).isTableBooking == true
                                            ?
                                            <a href="/BookTable" className="order-btn">
                                                Book A Table
                                            </a>
                                            : 
                                            <a href="javascript: void(0)" className="order-btn diableBtn">
                                                Now Closed
                                            </a>
                                        }
                                        </div>
                                    </div>
                                    {/* </div> */}
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
                <FooterMenu />
            </div>
        )
    }




}



function mapStateToProps(state) {
    console.log('isLoaging ResetPassword ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
    GetLocationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),
    getContentDataById : (data) => dispatch(HGActions.getContentDataById((data))),

})
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);