import React, { Component } from "react";

import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";

import baseConstants from '../constants/baseConstants';
import {setSecureData, getSecureData} from '../Utils/utils';
import { Strings } from '../constants/Localization';

import Modal from 'react-bootstrap/Modal'


class AlertDialog extends Component {
  
  constructor(props) {
    super(props);


  }

  render() {
    return (
      
    <Modal
        show={this.props.isAlertVisible}
        // onHide={()=>{this.props.btnLeftClick()}}
        size="sm"
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
      <Modal.Body>
        <div className="item-div">
            <p>{this.props.msgTitle}</p>
            <div className="alertButtonDiv">
              <a className={this.props.isMultibutton ? "greenBtn smallPopupBtnLbl actionbtn" : "btn btn-primary btn-small poupButton fullWidth"} onClick={()=>{this.props.btnLeftClick()}} tabIndex={1}>{this.props.btnLeftTitle}</a>
              {this.props.isMultibutton ? <a className="greenBtn smallPopupBtnLbl actionbtn" onClick={()=>{this.props.btnRightClick()}} tabIndex={2}>{this.props.btnRightTitle}</a> : null }
            
              {this.props.orderDetailId && this.props.orderDetailId > 0
              ?
                <button onClick={() => {
                    setSecureData(baseConstants.PRE_ORDER_ID,this.props.orderDetailId)
                    this.props.history.push({ pathname: '/OrderDetails' })
                }} className="btn btn-primary btn-small poupButton fullWidth">{Strings.btn_view_order_react}</button>
              : 
              null
              
              }
            </div>
            
        </div>
      </Modal.Body>
    </Modal>
    )
  }

}

export default AlertDialog;