import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import Constant from '../constants/baseConstants';
import Constants from '../constants/baseConstants';
import HeaderSignin from '../Components/HeaderSignin';
import LoginForm from '../Components/LoginForm';
import SignUpForm from '../Components/SignUpForm';
import ForgetForm from '../Components/ForgetForm'
import FooterMenu from '../Components/FooterMenu';
import { Strings } from '../constants/Localization';
import AlertDialog from '../Components/AlertDialog';
import {setSecureData, getSecureData} from '../Utils/utils';
import AOS from 'aos';
import 'aos/dist/aos.css';
import queryString from 'query-string';
import { ThemeProvider } from 'react-bootstrap';
import HeaderWhiteMenuHalf from '../Components/HeaderWhiteMenuHalf';
// import DatePicker from '../Components/DatePicker';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

class BookTable extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customerId : 0,
            activeCardID : -1,
            isLoading:false,
            errors: {
                errBookingDate: '',
                errBookingTime: '',  
                errBookingSlotTime : '',              
            },
            isAlertVisible: false,
            alertMessage: '',
            btnLeft: '',
            btnRight: '',
            isMultiButton: false,
            dialogType:1, 
            languageType: 1, //default language type
            minBookingDate : '', 
            minBookingTime : '',
            totalPerson : 1, 
            bookingDate: '',
            bookingDateSet : '',
            bookingTime: '',
            componentLoadStep : 'step1',
            bookingTimeSloat  :[],
            bckBtnTable : '',
            payNowResponse : {},

            fromTime : '',
            toTime : '',
            noOfPeople : 1,
            tableNoOfPeople : 1,
            restaurantId : '',
            locationId : 1,
            languageId: 1,
            date : '',
            select_booking_date_title : '',
            tableBookingPerPersonCharge : getSecureData(Constant.LOCATIONOBJECT) != undefined || getSecureData(Constant.LOCATIONOBJECT) != null ? getSecureData(Constant.LOCATIONOBJECT).tableBookingPerPersonCharge : 0,


            isVisible : false,
            isLoginEnabled: false,
            isSignUpEnabled: false,
            isForgetEnabled : false,
            tableAreaType : 1,
            note : ''

        }
    }
    componentDidMount(){
        
        console.log("getSecureData(Constant.LOCATIONOBJECT)", getSecureData(Constant.LOCATIONOBJECT))
        // let params = queryString.parse(this.props.location.search)
        // if (params.customerId) {
        //     console.log('query string -------------> ', params)
        // }
        

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                // this.setState({ languageType: mLanguageType })
                this.setState({ languageType: mLanguageType,languageTypeCode:"en-GB"})
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    // this.setState({ languageType: mLanguageType })
                    this.setState({ languageType: mLanguageType,languageTypeCode:"es"})
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                if (userObject != null) {
                     this.setState({customerId : userObject.customerId});
                    console.log('login user :' + JSON.stringify(userObject));
                }
            }
        }

        
        this.fetchLocationList()
        // this.GetLocationListApi()
    }

    GetLocationListApi = () => {
        this.props.GetLocationListApi(this.state.languageType).then(() => {

            console.log("GetLocationListApi",this.props.data )
            if (this.props.data.status == 1) {
                this.setState({tableBookingPerPersonCharge : this.props.data.result.tableBookingPerPersonCharge})
            }

        })
    }

    fetchLocationList() {
        
        this.props.AppMessageApi(this.state.languageType).then(() => {
            if (this.props.data.status == 1) {
                var messageListArray = this.props.data.result;
                if (messageListArray != null && messageListArray.length > 0) {
                    
                    // select_booking_date_title

                    var data = messageListArray.find(val => val.keyValue == 'select_booking_date_title');
                    console.log("select_booking_date_title",data)
                    this.setState({select_booking_date_title:data.msgValue})
                }
            }
        })


        if(getSecureData(Constant.LOCATIONOBJECT) == null || getSecureData(Constant.LOCATIONOBJECT) == undefined) {
            // window.location.href = '/signin';
            console.log('getSecureData(Constant.LOCATIONOBJECT)',getSecureData(Constant.LOCATIONOBJECT))
            return
        }
        var tableBookingDelay = getSecureData(Constant.LOCATIONOBJECT).bookingTimeLimit
        var todayDate = new Date();
        var today = todayDate.toISOString().split('T')[0];
        this.setState({ minBookingDate : new Date()})
        this.setState({ bookingDateSet : new Date(), bookingTimeSet : moment(new Date()).add(tableBookingDelay, 'minutes').toDate()})
        this.setState({ bookingDate : moment(today).format('YYYY-MM-DD'),bookingTime : moment(new Date()).add(tableBookingDelay, 'minutes').format('HH:mm')})

        

        // var today = new Date();
        // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        // this.setState({ minBookingTime : today})

        console.log("Todaydate -> new date", today+"bookingTime" +moment(today).add(tableBookingDelay, 'minutes').format('HH:mm'),"=>bookingTimeSet"+ moment(today).add(tableBookingDelay, 'minutes').toDate())

        
    }

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my account page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST,messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    onLogin() {
        this.setState({isLoginEnabled: false, isSignUpEnabled: false, isForgetEnabled : false},()=>{
            setTimeout(() => {
                if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
                    let userObject = getSecureData(Constants.USEROBJECT);
                    if (userObject != null) {
                        let customerId = getSecureData(Constants.USEROBJECT).customerId
                        let id = getSecureData(Constants.LOCATIONOBJECT).locationId
                        this.setState({ customerId: customerId})    
                    } else {
    
                    }
                } else {
                    
                }
            }, 200);
        })
    }

    checkTimeForBook = (event) => {

        var todays = new Date().toISOString();
        // console.log("todays",todays)
        var today = new Date();
        const minutes = (today.getMinutes() < 10) ? `0${today.getMinutes()}` : today.getMinutes()
        var time = today.getHours() + ":" + minutes;
        var getOnlyTime =  moment(event).format('HH:mm');

        var cEvent = moment(event).format('YYYY-MM-DD')
        var fDate = moment(this.state.bookingDateSet).format('YYYY-MM-DD')

        console.log("todays",time,getOnlyTime,today,event)

        console.log("Future Book", this.state.bookingDateSet, event,getOnlyTime, moment(cEvent).isSame(fDate))
        
        if(event == null){
            this.setState({ bookingTimeSet : '', bookingTime : '' });
            // this.setState({errors: { errBookingTime: 'Please select current.'} })
            return false
        }else if(moment(cEvent).isSame(fDate)){

            if(getOnlyTime < time){
                this.setState({errors: { errBookingTime: 'Please select a booking time after current time.'} })
                return false
            }
            else{
                return true
            }
        }
        else{
            return true
        }

        
    } 

    onChkTblAvailabilty = (event) => {
        
        event.preventDefault();

        if(this.state.errors.errBookingTime.length !== 0){
            return
        }
        
        if(!this.checkTimeForBook(this.state.bookingTimeSet)){

            return
        }

        
     
        var bookingDate = this.state.bookingDateSet == null ? 'Please select a booking date.' : '';
        var bookingTime = this.state.bookingTimeSet == null || this.state.bookingTime == 'Invalid date' ? 'Please select a booking time.' : '';

        
        this.setState({ errors: { errBookingDate: bookingDate, errBookingTime: bookingTime} }, () => {
            console.log("hiikp")
            let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId

            this.setState({locationId : locationId})

            console.log(this.state.customerId,locationId,this.state.languageType)
            // let params = queryString.parse(this.props.location.search)
            if (this.state.bookingDate.length !== 0 && this.state.bookingTime.length !== 0 && this.state.errors.errBookingTime.length == 0 && this.state.errors.errBookingDate.length == 0) {

                if (this.state.errors.errBookingDate == '' && this.state.errors.errBookingTime == '') {

                    this.props.getTableReservationTimeSlotList(this.state.customerId, locationId, this.state.languageType, this.state.bookingDate,this.state.bookingTime,this.state.totalPerson).then(() => {

                        console.log("response123 :",this.props.data)
                        if (this.props.data.status == 1) {
                            this.setState({
                                // isAlertVisible: true, 
                                // alertMessage: this.props.data.message, 
                                // isMultiButton: false, 
                                // btnLeft: Strings.Ok_web
                                componentLoadStep : 'step3',
                                // bookingTimeSloat : this.props.data.result.objTime
                            })

                            this.setState({
                                fromTime : this.props.data.result.fromTime,
                                toTime : this.props.data.result.toTime,
                                tableNoOfPeople : this.state.totalPerson,
                            })


                        } else {
                            this.setState({isAlertVisible: true, alertMessage: this.props.data.message, isMultiButton: false, btnLeft: Strings.Ok_web})
                        }

                        

                    })
                    .catch((err)=>{
                        console.log('error ',err)
                    })

    
                } else {
                    console.error('Not valid form')
                }
            }
        })
    }

    onCheckOutBtn = (event) =>{
        event.preventDefault();
        
        if(this.state.fromTime != '' && this.state.toTime != '' && this.state.tableNoOfPeople != ''){
            this.setState({ errors: {errBookingSlotTime : ''}, componentLoadStep : 'step3'})
        }
        else{
            this.setState({ errors: {errBookingSlotTime : 'Plase select any one time slot.'}})
        }
        

        
    }

    onPayNow = () => {
        var data = {
            locationId: this.state.locationId,
            customerId: this.state.customerId,
            languageId: this.state.languageType,
            bookingDate: this.state.bookingDate,
            fromTime: this.state.fromTime,
            toTime: this.state.toTime,
            noOfPeople: this.state.totalPerson,
            tableNoOfPeople: this.state.tableNoOfPeople,
            tableAreaType : this.state.tableAreaType,
            note : this.state.note
        }

        let tableObject = {}
        tableObject.bookingId = 0
        tableObject.contactTime = this.state.fromTime
        tableObject.contactDate = moment(this.state.bookingDate, 'YYYY-MM-DD').format('MM/DD/yyyy')
        tableObject.bookingDepositAmount = this.state.tableBookingPerPersonCharge * this.state.tableNoOfPeople
        tableObject.tableNoOfPeople = this.state.tableNoOfPeople
        tableObject.tableAreaType = this.state.tableAreaType

        localStorage.setItem('tablePreBookingObj',JSON.stringify(tableObject))
        console.log("tablePreBookingObj",tableObject,this.state.tableNoOfPeople,this.state.tableBookingPerPersonCharge)
        // return;
       
        if(this.state.customerId != 0 && this.state.bookingDate != '' && this.state.toTime !='' & this.state.totalPerson != '' && this.state.tableNoOfPeople != ''){
            this.props.addTableReservation(data).then(() => {

                console.log("response123 :",this.props.data)
                if (this.props.data.status == 1) {
                    this.setState({
                        componentLoadStep : 'step4',
                        payNowResponse : this.props.data.result
                    })
    
                    localStorage.remove(Constants.USERCART)
                } else {
                    this.setState({isAlertVisible: true, alertMessage: this.props.data.message, isMultiButton: false, btnLeft: Strings.Ok_web})
                }
    
                
    
            })
            .catch((err)=>{
                console.log('error ',err)
            })
        }
        else{
            if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
                let userObject = getSecureData(Constants.USEROBJECT);
                if (userObject == null) {
                    this.setState({isLoginEnabled: true})
                    return
                }
            } else {
                this.setState({isLoginEnabled: true})
                return
            }
        }
        
        console.log(data)
    }

    onQtyChanged(type, totalPerson, index) {
        var quantity = totalPerson
        if (type == 0) {
            if (quantity > 1) {
                quantity -= 1
            }
        } else {
            quantity += 1
        }

        this.setState({
            totalPerson : quantity
        })

    }

    handleTextFieldChange = (event) => {

        var todays = new Date().toISOString();
        // console.log("todays",todays)
        var today = new Date();
        const minutes = (today.getMinutes() < 10) ? `0${today.getMinutes()}` : today.getMinutes()
        var time = today.getHours() + ":" + minutes;
        var getOnlyTime =  moment(event).format('HH:mm');

        var cEvent = moment(event).format('YYYY-MM-DD')
        var fDate = moment(this.state.bookingDateSet).format('YYYY-MM-DD')

        console.log("todays",time,getOnlyTime,today,event)

        console.log("Future Book", this.state.bookingDateSet, event,getOnlyTime, moment(cEvent).isSame(fDate))
        
        if(event == null){
            this.setState({ bookingTimeSet : '', bookingTime : '' });
            // this.setState({errors: { errBookingTime: 'Please select current.'} })
            return
        }else if(moment(cEvent).isSame(fDate)){

            if(getOnlyTime < time){
                this.setState({ bookingTimeSet : today, bookingTime : moment(today).format('HH:mm') });
                this.setState({errors: { errBookingTime: 'Please select a booking time after current time.'} })
                return
            }
            else if(getOnlyTime > time){
                this.setState({errors: { errBookingTime: ''} })
            }
        }
        else{
            this.setState({errors: { errBookingTime: ''} })
        }
        
        
        

       
        
        this.setState({ bookingTimeSet : event, bookingTime : getOnlyTime });
        
    } 

    checkDate = (value) => {
        var getDate = moment(value).format('YYYY-MM-DD');//moment(this.state.currentTime, "YYYY-MM-dd HH:mm:ss").format("HH:mm");
        console.log("value",value,getDate)
        this.setState({bookingDate : getDate, bookingDateSet : value })
    }

    bookTablePayNow = () => {
            return (
                <div className="iframePayNow">
                    <iframe src={this.state.payNowResponse.paymentUrl} width='100%' height='100%' style={{top:0}}></iframe>
                </div>
            )
    }
    

    render() {
        return (
            <div className="loginpage white nonsticy" style={{}}> 
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}
                {/* <HeaderMenu /> */}
                {/* Old header */}
               {/* <HeaderWhiteMenuHalf isHideOrderType={true} headerLbl={'Book A Table'} isCategory={true} 
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/>  */}
                <AlertDialog 
                isAlertVisible={this.state.isAlertVisible} 
                msgTitle={this.state.alertMessage} 
                isMultibutton={this.state.isMultiButton} 
                btnLeftClick={() => { 
                    this.setState({ isAlertVisible: false });
                    // window.location.href = Constant.mainURL
                    return
                 }} 
                 btnRightClick={() => { 
                     this.onRightClick() }} 
                btnLeftTitle={this.state.btnLeft} 
                btnRightTitle={this.state.btnRight} />

                {<LoginForm isShowForm={this.state.isLoginEnabled} onResponse={()=>this.onLogin()} onSignUp={()=>{this.setState({isLoginEnabled: false, isSignUpEnabled: true})}} onForget={()=>{this.setState({isLoginEnabled: false, isForgetEnabled: true})}}></LoginForm>}
                {<SignUpForm isShowForm={this.state.isSignUpEnabled} onResponse={()=>this.onLogin()}></SignUpForm>}
                {<ForgetForm isShowForm={this.state.isForgetEnabled} onResponse={()=>this.setState({isLoginEnabled: false, isSignUpEnabled: false, isForgetEnabled : false})}></ForgetForm>}
                
                {(() => {
                      switch (this.state.componentLoadStep) {
                        case 'step1':
                            return this.bookTableStep1()
                        case 'step2':
                            return this.bookTableStep2()
                        case 'step3':
                            return this.bookTableStep3()
                        case 'step4':
                            return this.bookTablePayNow()
                        default:
                            return ''
                     }
                })()}
                {/* <FooterMenu /> */}
            </div>
        )
    }

     convertDate = () =>{
        let d = new Date(this.state.bookingDate)

        const year = d.getFullYear()
        const month = months[d.getMonth()]
        const date = d.getDate() 
        return date + ' ' + month + ' '+ year;
    }

    bookTableStep1 = () => { 

        return (
            <div className="content_wrap">
                    <div className="container">
     
                        <div className="user booktable-wrp fo-btm-spc">

                            <div className="row">
                                <div className="col-md-12  col-lg-6 center" style={{margin:'0 auto'}}>
                                    <h1 className="text-center">{'Book A Table'}</h1>
                                    <div className="form-group">
                                        {/* <label>{Strings.select_booking_date}*</label> */}
                                        <label>{this.state.select_booking_date_title != '' ? this.state.select_booking_date_title : Strings.select_booking_date}*</label>
                                        {/* <input className="form-control datechk" name="bookingDate" type="text" min={this.state.minBookingDate}  value={this.state.bookingDate} onSelect={e => this.handleTextFieldChange(e)} /> */}
                                        {/* <DatePicker
                                            onDateChange={date => this.setState({ bookingDate: date })}
                                            initialDate={this.state.bookingDate}
                                            minDate={this.state.minBookingDate}
                                        /> */}
                                        <br/>
                                        <DatePicker
                                            onChange={(date) =>{this.checkDate(date)}}
                                            // dateFormat="dd/MM/yyyy"
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.minBookingDate}
                                            selected={this.state.bookingDateSet}
                                            name="bookingDate"
                                            className="form-control calenderIconInput"
                                            onKeyPress={(e) => {return false} }
                                        />
                                        <i className="fa fa-calendar calenderIcon" aria-hidden="true"></i>
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.errBookingDate}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>{Strings.select_booking_time}*</label>
                                        {/* <input className="form-control" name="bookingTime" type="time" value={this.state.bookingTime} onChange={e => this.handleTextFieldChange(e)} /> */}
                                        <br/>
                                        <DatePicker
                                        selected={this.state.bookingTimeSet}
                                        onChange={(date) => this.handleTextFieldChange(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        placeholderText="Please select a time."
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        className="form-control clockIconInput"
                                        onKeyPress={(e) => {return false} }
                                        />
                                        <i className="fa fa-clock clockIcon" aria-hidden="true"></i>
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.errBookingTime}</span>
                                        </div>
                                    </div>

                                    <div className="form-group" style={{display: 'inline-grid'}}>
                                            <label>{'No. of People'}*</label>
                                            <div class="QtyDiv">
                                                <button href="" type="button" class="btn" data-toggle="modal" data-target="#myModal" onClick={() => { this.onQtyChanged(0, this.state.totalPerson) }}><img src="../images/minus-icon.png" /></button>
                                                <label class="QTYLabel">{this.state.totalPerson}</label>
                                                <button href="" type="button" class="btn" data-toggle="modal" data-target="#myModal" onClick={() => { this.onQtyChanged(1, this.state.totalPerson) }}><img src="../images/plus-icon.png" /></button>
                                            </div>
                                    </div>
                                    <div className="form-group">
                                        <label>{Strings.select_Area}</label>
                                        <br />
                                        <div class="form-check form-check-inline ">
                                            <label onClick={() => { this.setState({tableAreaType : 1}) }} className="form-check-label cursor-pointer" for="inlineRadio1"><i class={this.state.tableAreaType == 1 ? 'fas fa-check-circle' : 'fa fa-circle-o'} aria-hidden="true"> </i> {'  '} Inside</label>
                                        </div>

                                        <div class="form-check form-check-inline ">
                                            <label onClick={() => { this.setState({tableAreaType : 2}) }} className="form-check-label cursor-pointer" for="inlineRadio1"><i class={this.state.tableAreaType == 2 ? 'fas fa-check-circle' : 'fa fa-circle-o'} aria-hidden="true"> </i> {'  '} Outside</label>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>{Strings.lbl_comment_react}</label>
                                        <textarea type="text" className="form-control mt-0" placeholder={Strings.comments_web} value={this.state.note} onChange={text => {this.setState({note: text.target.value})}}/>
                                    </div>

                                    <div className="checkoutBtn flex-dir-mob-rev" >
                                        {/* <div className="buttons-checkout">
                                            <button className="btn btn-primary btn-medium" onClick={() => { this.props.history.push('/BookTableList') }}>{Strings.booking_history}</button>
                                        </div> */}
                                        <div className="buttons-checkout">
                                            <button className="btn btn-primary btn-medium" onClick={this.onChkTblAvailabilty}>{Strings.check_table_availability}</button>
                                        </div>
                                        
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    bookTableStep2 = () => { 

        return (
            <div className="content_wrap">
                    <div className="container">
     
                        <div className="user booktable-wrp fo-btm-spc">

                            <div className="row book-ava-date-wrp">
                                <div className="col-md-12  col-lg-6 center" style={{margin:'0 auto'}}>
                                    <h1 className="text-center">{'Book A Table'}</h1>
                                    <p className="text-center select-ave-txt">Select from available time for date <br/> <span className="bkTblDate" >{this.convertDate()}</span> </p>
                                    
                                    <div className="row book-avdate" >
                                        {
                                            this.state.bookingTimeSloat.map((value, i) => { 
                                                return (
                                                    value.isAvailable === true
                                                    ?
                                                    <div className="col-md-4 col-sm-6" >
                                                        <span onClick={() => this.setTimesloat(value)}  className={value.tId === this.state.activeCardID ? 'timeSloatBox ActiveCard' : 'timeSloatBox deActiveCard' }  style={{backgroundColor : (value.tId === this.state.activeCardID ? 'pink' : '')}} >
                                                            {this.convertFrom24To12Format(value.fromTime)}
                                                        </span>
                                                            
                                                    </div>
                                                    : 
                                                    null
                                                )
                                            })
                                        }
                                        <div className="form-group col-md-12">
                                            <div className="errorDiv">
                                                <span className='errorMsg'>{this.state.errors.errBookingSlotTime}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group flex-btn-group book-btn-grp flex-dir-mob-rev">
                                     <div className="buttons-checkout bktablegrp">
                                            <button className="btn btn-primary btn-medium" onClick={() => {this.setState({componentLoadStep : 'step1'})}}>{'Back'}</button>
                                        </div>
                                        <div className="buttons-checkout bktablegrp">
                                            <button className="btn btn-primary btn-medium" onClick={this.onCheckOutBtn}>{Strings.strHeaderCheckout}</button>
                                        </div>
                                       
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    bookTableStep3 = () => { 

        return (
            <div className="content_wrap">
                    <div className="container">
           
                        <div className="user booktable-wrp fo-btm-spc ">

                            <div className="row checkpay-table">
                                <div className="col-md-12  col-lg-6 center max-460" style={{margin:'0 auto'}}>
                                     <div class="checkpay-head">       
                                    <h1 className="text-center">{'CheckOut'}</h1>
                                    <p className="text-center">Please {this.state.tableBookingPerPersonCharge > 0 ? 'pay' : 'confirm '} to reserve your {this.state.tableAreaType == 1 ? 'Inside' : 'Outside'  } table on <br/> <span className="bkTblDate" >{this.convertDate()} {this.convertFrom24To12Format(this.state.fromTime)}</span> <br/> for {this.state.totalPerson} people  </p>
                                    </div>
                                    {
                                        this.state.tableBookingPerPersonCharge > 0
                                        ?
                                        <div className="row">
                                            <div className="col-md-12  col-lg-12">
                                                <div class="tab-book-charges">   
                                                <h4 class="text-center"> Table Booking Charges</h4>
                                                <table className="table">
                                                
                                                    <tbody>
                                                        <tr>
                                                            <td class="row-center" data-column="ORDER #">@ £{this.state.tableBookingPerPersonCharge}/Person</td>
                                                            <td class="row-center" data-column="DATE">£{this.state.tableBookingPerPersonCharge} x {this.state.totalPerson}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <hr/>
                                                <table className="table bookTableCheckout">
                                                    <thead>
                                                        <tr>
                                                            <th className="header-center" data-column="Total">Total</th>
                                                            <th className="header-center">£{(this.state.tableBookingPerPersonCharge * this.state.totalPerson).toFixed(2)}</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    
                                    <div className="row" >
                                        <div className="form-group">
                                            <div className="errorDiv">
                                                <span className='errorMsg'>{this.state.errors.errBookingSlotTime}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group flex-btn-group book-btn-grp flex-dir-mob-rev">
                                     <div className="buttons-checkout bktablegrp">
                                            <button className="btn btn-primary btn-medium" onClick={() => {this.setState({componentLoadStep : 'step1'})}}>{'Back'}</button>
                                        </div>
                                         <div className="buttons-checkout bktablegrp">
                                            <button className="btn btn-primary btn-medium" onClick={this.onPayNow}>{this.state.tableBookingPerPersonCharge > 0 ? Strings.strPayNow : 'Confirm '}</button>
                                        </div>
                                       
                                       
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    setTimesloat = (val) => {
        
        
        this.setState({
            activeCardID : val.tId,
            fromTime : val.fromTime,
            toTime : val.toTime,
            tableNoOfPeople : val.tableNoOfPeople,
        })

    }

    convertFrom24To12Format = (time24) => {
        // const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        // const period = +sHours < 12 ? 'AM' : 'PM';
        // const hours = +sHours % 12 || 12;

        var dt = moment(time24, ["HH.mm"]).format("hh:mm a");

        console.log("checTime",time24,dt)
      
        return dt;
      }

}

function mapStateToProps(state) {
    console.log('isLoaging ResetPassword ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    locationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),
    getTableReservationTimeSlotList: (customerId,locationId, languageId, bookingDate, BookingTime,totalPerson) => dispatch(HGActions.getTableReservationTimeSlotList(customerId,locationId, languageId, bookingDate, BookingTime, totalPerson)),
    addTableReservation: (data) => dispatch(HGActions.addTableReservation(data)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
    GetLocationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),

})
export default connect(mapStateToProps, mapDispatchToProps)(BookTable);