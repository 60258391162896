import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import HeaderWhiteMenuHalf from '../Components/HeaderWhiteMenuHalf';
import FooterMenu from '../Components/FooterMenu';
import { Strings } from '../constants/Localization';
import Constant from '../constants/baseConstants';
import {setSecureData, getSecureData} from '../Utils/utils';

import AOS from 'aos';
import 'aos/dist/aos.css';

class MobileVerification extends Component {

    constructor(props) {
        super(props)

        this.state = {
            verificationMessage: this.props.location.message,
            customerId: this.props.location.customerId,
            phone: this.props.location.phone,
            languageType: 1, //default language type
            verificationCode: '',
            verificationCodeError: '',
            minutes: 0,
            seconds: 30,

        }

    }


    componentDidMount() {
        AOS.init({
            duration: 1000
        })

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // let userObject = localStorage.getItem(Constants.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
        }
        else {
            this.props.history.push({ pathname: '/' })
            return
        }

        this.startTimer()
        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                this.setState({ languageType: mLanguageType })
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    this.setState({ languageType: mLanguageType })
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }
    }
    // componentWillUnmount() {
    //     clearInterval(this.myInterval)
    // }


    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("mobile verification page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST, messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);

                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }


    startTimer() {
        clearInterval(this.myInterval)
        this.setState({
            seconds: 30,
        })
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state
            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000)
    }

    onResendCodeClick() {
        this.props.resendCodeAPI(this.state.customerId, this.state.phone, this.state.languageType).then(() => {
            console.log("resendCodeAPI-RESPONSE --------- ", this.props.data)
            if (this.props.data.status == 1) {
                if (this.props.data.result != null) {
                    setTimeout(() => {
                        let result = this.props.data.result;
                        this.setState({ verificationMessage: this.props.data.message })
                        this.startTimer()
                    }, 100)
                }
            } else {
                alert(this.props.data.message)
                console.log("resendCodeAPI--status else...")
            }
        })
    }

    onVerifyClick = (event) => {
        event.preventDefault();

        var codeError = this.state.verificationCode.length == 0 ? Strings.lbl_verification_code_react : '';

        this.setState({ verificationCodeError: codeError })
        setTimeout(() => {
            if (this.state.verificationCodeError == '') {
                console.info('Valid form')
                console.log('Customer id --- ', this.state.customerId)
                this.props.verifyCustomerAPI(this.state.customerId, this.state.verificationCode, this.state.languageType).then(() => {
                    console.log("verifyCustomerAPI RESPONSE --------- ", this.props.data)
                    if (this.props.data.status == 1) {
                        if (this.props.data.result != null) {
                            setTimeout(() => {
                                let result = this.props.data.result;
                                console.log("verifyCustomerAPI RESPONSE --------- ", JSON.stringify(result))
                                // localStorage.setItem(Constant.USEROBJECT, JSON.stringify(result))
                                setSecureData(Constant.USEROBJECT, result)
                                //Check for profile update or normal registration
                                if (this.props.location.isProfileUpdate == true) {
                                    this.props.history.goBack()
                                } else {
                                    //Move on MyAccount screen
                                    this.props.history.push({ pathname: '/MyAccount' })
                                }
                            }, 100)
                        }
                    } else {
                        alert(this.props.data.message)
                        console.log("verifyCustomerAPI status else...")
                    }
                })
            } else {
                console.error('Not valid form')
            }
        }, 100)

    }
    render() {

        const { minutes, seconds } = this.state

        return (
            <div>
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}

                <HeaderWhiteMenuHalf isHideOrderType={true}
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/>

                {/* <h2>{this.props.match.params.id}</h2> */}

                <div className="content_wrap">
                    <div className="container">
                        <div className="signupwrap verification" data-aos='fade-up'>

                            <div className="row">
                                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">

                                    <div className="form-group">
                                        <p className="messageLbl">{this.state.verificationMessage}</p>
                                        <label>{Strings.lbl_verification_code_react}</label>
                                        <input tabIndex="1" type="text" className="form-control" maxLength={75} value={this.state.verificationCode} onChange={e => this.setState({ verificationCode: e.target.value })} />
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.verificationCodeError}</span>
                                        </div>
                                    </div>
                                    <div className="time">
                                        {minutes === 0 && seconds === 0
                                            ? <button className="btn btn-primary btn-small reset" onClick={() => this.onResendCodeClick()} >{Strings.btn_resend}</button>
                                            : <label>0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</label>
                                        }
                                    </div>
                                    <div className="signup">
                                        <button tabIndex="2" className="btn btn-primary btn-big" onClick={() => this.props.history.goBack()}>{Strings.btn_edit}</button>
                                        <button tabIndex="3" className="btn btn-primary btn-big" onClick={this.onVerifyClick}>{Strings.btn_verify}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="content_wrap">
                    <div className="container">
                        <div className="signupwrap">
                        
                        <h1>Mobile Verification</h1>
                        <div className="row">
                            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                            <span className="">you received verification code on +91 9876543210</span>
                                <div className="form-group">
                                
                                    <label>Mobile Verification Code:*</label>
                                    <input type="text" className="form-control" maxLength={75} value={this.state.verificationCode} onChange={e => this.setState({verificationCode:e.target.value})}/>
                                    <div className="errorDiv">
                                        <span className='errorMsg'>{this.state.verificationCodeError}</span>
                                    </div>
                                </div>
                                { minutes === 0 && seconds === 0
                                    ? <button onClick={()=>this.startTimer()}>Didn't get code?</button>
                                    : <label>0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</label>
                                }
                                <div>
                            </div>
                                
                                <div className="signup">
                                    <a href="#" className="btn btn-primary btn-big" onClick={this.onVerifyClick}>Verify</a>    
                                </div>


                              </div>        
                            </div>    
                        </div>    
                    </div>        
                </div> */}

                <FooterMenu />
            </div>
        )
    }

}

function mapStateToProps(state) {
    console.log('isLoaging Mobile Verification ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    verifyCustomerAPI: (customerId, verificationCode, languageType) => dispatch(HGActions.initVerifyCustomerCall(customerId, verificationCode, languageType)),
    resendCodeAPI: (customerId, phoneNumber, languageType) => dispatch(HGActions.initResendVerificationCode(customerId, phoneNumber, languageType)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileVerification);