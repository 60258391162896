import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import Constant from '../constants/baseConstants';
import HeaderSignin from '../Components/HeaderSignin';
import FooterMenu from '../Components/FooterMenu';
import { Strings } from '../constants/Localization';
import AlertDialog from '../Components/AlertDialog';
import {setSecureData, getSecureData} from '../Utils/utils';
import AOS from 'aos';
import 'aos/dist/aos.css';
import queryString from 'query-string';
import { ThemeProvider } from 'react-bootstrap';
import HeaderWhiteMenuHalf from '../Components/HeaderWhiteMenuHalf';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

class VirtualQueue extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customerId : 0,
            activeCardID : -1,
            isLoading:false,
            errors: {
                errBookingDate: '',
                errBookingTime: '',  
                errBookingSlotTime : '',              
            },
            isAlertVisible: false,
            alertMessage: '',
            btnLeft: '',
            btnRight: '',
            isMultiButton: false,
            dialogType:1, 
            languageType: 1, //default language type
            minBookingDate : '', 
            minBookingTime : '',
            totalPerson : 1, 
            bookingDate: '',
            bookingTime: '',
            componentLoadStep : 'step2',
            bookingTimeSloat  :[],
            bckBtnTable : '',
            payNowResponse : {},

            fromTime : '',
            toTime : '',
            noOfPeople : 1,
            tableNoOfPeople : 1,
            restaurantId : '',
            locationId : 1,
            languageId: 1,
            date : '',
            tableBookingPerPersonCharge : 5,
            virtualQueData : []

        }
    }
    componentDidMount(){
        
        let params = queryString.parse(this.props.location.search)
        if (params.customerId) {
            console.log('query string -------------> ', params)
        }
        

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                // this.setState({ languageType: mLanguageType })
                this.setState({ languageType: mLanguageType,languageTypeCode:"en-GB"})
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    // this.setState({ languageType: mLanguageType })
                    this.setState({ languageType: mLanguageType,languageTypeCode:"es"})
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        var today = new Date().toISOString().split('T')[0];
        this.setState({ minBookingDate : today})

        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        this.setState({ minBookingTime : today})

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                if (userObject != null) {
                     this.setState({customerId : userObject.customerId});
                    console.log('login user :' + JSON.stringify(userObject));
                }
            }
        }

        this.checkUserLogin()
        
    }

    checkUserLogin = () => {
        // this.setState({isContentLoading:true})
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
            if (userObject !== null) {
                // this.getTableVirtualWaitingByCustomer()
            }
            else {
                this.props.history.push({ pathname: '/' })
                return
            }

        }
        else {
            this.props.history.push({ pathname: '/' })
        }
    }

  

    GetLocationListApi = () => {
        this.props.AppMessageApi(this.state.languageType).then(() => {

            if (this.props.data.status == 1) {
                this.setState({tableBookingPerPersonCharge : this.props.data.result.tableBookingPerPersonCharge})
            }

        })
    }

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my account page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST,messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }



 



    
  

    render() {
        return (
            <div className="loginpage white nonsticy" style={{}}> 
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}
                {/* <HeaderMenu /> */}
                {/* Old header */}
               <HeaderWhiteMenuHalf isHideOrderType={true} headerLbl={'Rewards'} isCategory={true} 
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/> 
                <AlertDialog 
                isAlertVisible={this.state.isAlertVisible} 
                msgTitle={this.state.alertMessage} 
                isMultibutton={this.state.isMultiButton} 
                btnLeftClick={() => { 
                    this.setState({ isAlertVisible: false });
                    // window.location.href = Constant.mainURL
                    return
                 }} 
                 btnRightClick={() => { 
                     this.onRightClick() }} 
                btnLeftTitle={this.state.btnLeft} 
                btnRightTitle={this.state.btnRight} />
                {/* <HeaderSignin logoType={1} headerLbl={Strings.reset_pass_web} isCategory={true} /> */}
                
                <div className="content_wrap">
                    <div className="container">
                        {/* <div className="book-tab-backbtn" style={{float: 'right'}}>
                                
                                <a  className="btn btn-primary order-btn book-back-btn" href="/BookTableList" >Back</a>
                        </div> */}
                        <div className="user booktable-wrp fo-btm-spc">

                            <div className="row">
                                <div className="col-md-12  col-lg-6 center" style={{margin:'0 auto'}}>
                                    <h1 className="text-center">{'Rewards'}</h1>
                                    <hr />

                                    
                                    <div className="ROImgBox">
                                        <img  className="congImg" src="images/bg/offer_rewards.jpg" alt="congratulations" />
                                    </div>
                                    <br />

                                    <div className="ROdiduknow">
                                        <p className="text-center">Access your personalised rewards throughout the year and a special treat on your Birthday! Or find special offers available throughout the year.</p>
                                    </div>
                                    

                                    <div className="checkoutBtn " >
                                        <div className="buttons-checkout">
                                            <button className="btn btn-primary btn-medium vQbtn" onClick={() => { this.props.history.push('/MyRewards') }}>Your Rewards</button>
                                        </div>

                                        <div className="buttons-checkout">
                                            <button className="btn btn-primary btn-medium vQbtn" onClick={() => { this.props.history.push('/BestOfferList') }}>Special Offers</button>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMenu />
            </div>
        )
    }

    


     convertDate = () =>{
        let d = new Date(this.state.bookingDate)

        const year = d.getFullYear()
        const month = months[d.getMonth()]
        const date = d.getDate() 
        return date + ' ' + month + ' '+ year;
    }






    setTimesloat = (val) => {
        
        
        this.setState({
            // componentLoadStep : 'step3',
            activeCardID : val.tId,
            fromTime : val.fromTime,
            toTime : val.toTime,
            tableNoOfPeople : val.tableNoOfPeople,
        })

        // {"restaurantId":147,"locationId":363,"customerId":33006,"languageId":1,"date":"05/31/2021","fromTime":"18:30","toTime":"19:00","noOfPeople":1,"tableNoOfPeople":2}
    }

    convertFrom24To12Format = (time24) => {
        const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        const period = +sHours < 12 ? 'AM' : 'PM';
        const hours = +sHours % 12 || 12;
      
        return `${hours}:${minutes} ${period}`;
      }

}

function mapStateToProps(state) {
    console.log('isLoaging ResetPassword ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
    GetLocationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),

})
export default connect(mapStateToProps, mapDispatchToProps)(VirtualQueue);