import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import Constant from '../constants/baseConstants';
import baseConstants from '../constants/baseConstants';
import HeaderSignin from '../Components/HeaderSignin';
import FooterMenu from '../Components/FooterMenu';
import { Strings } from '../constants/Localization';
import AlertDialog from '../Components/AlertDialog';
import {setSecureData, getSecureData} from '../Utils/utils';
import AOS from 'aos';
import 'aos/dist/aos.css';
import queryString from 'query-string';
import { ThemeProvider } from 'react-bootstrap';
import HeaderWhiteMenuHalf from '../Components/HeaderWhiteMenuHalf';
import {getPriceFormat} from "../Utils/utils";
import { Tabs, Tab } from 'react-bootstrap';
import moment from 'moment';
import BookTable  from './BookTable';


const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

class BookTableList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customerId : 0,
            activeCardID : -1,
            isLoading:false,
            errors: {
                errBookingDate: '',
                errBookingTime: '',  
                errBookingSlotTime : '',              
            },
            upComingData : true,
            isAlertVisible: false,
            alertMessage: '',
            btnLeft: '',
            btnRight: '',
            isMultiButton: false,
            dialogType:1, 
            languageType: 1, //default language type
            minBookingDate : '', 
            minBookingTime : '',
            totalPerson : 1, 
            bookingDate: '',
            bookingTime: '',
            componentLoadStep : 'step1',
            bookingTimeSloat  :[],
            bckBtnTable : '',
            upComingBooking : [],
            pastBooking : [],
            tableTitle : 'UPCOMING BOOKING',
            tableBookDetailRow : {
                orderstatusText : '',

            },
            ActiveTab : 'bookaTable',

            fromTime : '',
            toTime : '',
            noOfPeople : 1,
            tableNoOfPeople : 1,
            restaurantId : '',
            locationId : 1,
            languageId: 1,
            date : '',
            bookingId : 0

        }
    }
    componentWillMount() {

        
        let params = queryString.parse(this.props.location.search)
        if (params.customerId) {
            console.log('query string -------------> ', params)
        }
        

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                // this.setState({ languageType: mLanguageType })
                this.setState({ languageType: mLanguageType,languageTypeCode:"en-GB"})
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    // this.setState({ languageType: mLanguageType })
                    this.setState({ languageType: mLanguageType,languageTypeCode:"es"})
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        var today = new Date().toISOString().split('T')[0];
        this.setState({ minBookingDate : today})

        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        this.setState({ minBookingTime : today})

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                if (userObject != null) {
                     this.setState({customerId : userObject.customerId});
                    console.log('login user :' + JSON.stringify(userObject));
                }
            }
        }

        this.fetchLocationList()
    }
    componentDidMount(){
        
        this.checkUserLogin()
        localStorage.removeItem('tablePreBookingObj')
        
    }

    fetchLocationList() {
        this.props.locationListApi(this.state.languageType).then(() => {
            if (this.props.data.status == 1) {
                if (this.props.data.result != null) {
                    var locationList = this.props.data.result;
                    if (locationList != null && locationList.length > 0) {
                        if (getSecureData(Constant.LOCATIONOBJECT)) {
      
                            // console.log('location object  ----------- ',getSecureData(Constants.LOCATIONOBJECT))
                            // console.log('locationList  ----------- ',locationList)
          
                            let objLocation = locationList.filter(obj => {
                              return (obj.locationId == getSecureData(Constant.LOCATIONOBJECT).locationId)
                            })
          
                          
          
                            this.setState({locationObj: objLocation[0]}, ()=>{
                              setSecureData(Constant.LOCATIONOBJECT,objLocation[0])
                              // this.setLocationData()
                            })
                          } else {
                            if(locationList.length == 1){
                              this.setState({locationObj: locationList[0]}, ()=>{
                                setSecureData(Constant.LOCATIONOBJECT,locationList[0])
                                // this.setLocationData()
                              })
                            }
                            else{
                              setSecureData(Constant.LOCATIONLISTOBJECT,locationList)
                              this.setState({ locationArray: locationList, isVisible: true })
                            }
                          }
                    }
                }

                console.log("checkLocation",getSecureData(Constant.LOCATIONOBJECT))
                if(getSecureData(Constant.LOCATIONOBJECT) != undefined && getSecureData(Constant.LOCATIONOBJECT).isTableBooking == true){
                    // this.checkUserLogin()
                }
                else{
                    window.location.href = "/"
                    return
                }
            } else {
                    // alert(this.props.data.message)
            }
        })


        if(getSecureData(Constant.LOCATIONOBJECT) == null || getSecureData(Constant.LOCATIONOBJECT) == undefined) {
            // window.location.href = '/signin';
            console.log('getSecureData(Constant.LOCATIONOBJECT)',getSecureData(Constant.LOCATIONOBJECT))
            return
        }
        // var tableBookingDelay = getSecureData(Constant.LOCATIONOBJECT).bookingTimeLimit
        // var todayDate = new Date();
        // var today = todayDate.toISOString().split('T')[0];
        // this.setState({ minBookingDate : new Date()})
        // this.setState({ bookingDateSet : new Date(), bookingTimeSet : moment(new Date()).add(tableBookingDelay, 'minutes').toDate()})
        // this.setState({ bookingDate : moment(today).format('YYYY-MM-DD'),bookingTime : moment(new Date()).add(tableBookingDelay, 'minutes').format('HH:mm')})

        

        // var today = new Date();
        // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        // this.setState({ minBookingTime : today})

        // console.log("Todaydate -> new date", today+"bookingTime" +moment(today).add(tableBookingDelay, 'minutes').format('HH:mm'),"=>bookingTimeSet"+ moment(today).add(tableBookingDelay, 'minutes').toDate())

        
      }

    checkUserLogin = () => {
        // this.setState({isContentLoading:true})
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject == null) {
                // this.props.history.push({ pathname: '/signin' })
                // return
            }
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                this.setState({
                    customerId: userObject.customerId, name: userObject.firstname, countryCode: userObject.countryCode,
                    phoneNumber: userObject.phone, email: userObject.emailId, password: userObject.password, linkedCompanyName: userObject.linkedCompanyName
                },()=>{this.getTableBookingHistoryByCustomer()})
                
            }
            else {
                // this.props.history.push({ pathname: '/signin' })
                // return
            }

        }
        else {
            // this.props.history.push({ pathname: '/signin' })
        }
    }

    getTableBookingHistoryByCustomer = () => {

        let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId

            this.setState({locationId : locationId})
        var data = {
            locationId: locationId,
            customerId: this.state.customerId,
            languageId: this.state.languageType,
        }

        if(this.state.customerId != 0  && this.state.languageType != '' && locationId != ''){
            this.props.getTableBookingHistoryByCustomer(data).then(() => {

                console.log("response123 :",this.props.data)
                if (this.props.data.status == 1) {
                    this.setState({
                        // isAlertVisible: true, 
                        // alertMessage: this.props.data.message, 
                        // isMultiButton: false, 
                        // btnLeft: Strings.Ok_web
                        // componentLoadStep : 'step2',
                        upComingBooking : this.props.data.result.upComingBooking,
                        pastBooking : this.props.data.result.pastBooking
                    })
    
    
                } 
                else if(this.props.data.status == 2) {
                    this.setState({isAlertVisible: true, alertMessage: this.props.data.message, isMultiButton: false, btnLeft: Strings.Ok_web})
                }
    
                
    
            })
            .catch((err)=>{
                console.log('error ',err)
            })
        }
        // else{
        //     alert('Do Login First.')
        // }
    }

    

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my account page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST,messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    onChkTblAvailabilty = (event) => {
        
        event.preventDefault();

        var bookingDate = this.state.bookingDate.length == 0 ? 'Please select a booking date.' : '';
        var bookingTime = this.state.bookingTime.length == 0 ? 'Please select a booking time.' : '';

        
        this.setState({ errors: { errBookingDate: bookingDate, errBookingTime: bookingTime} }, () => {
            console.log("hiikp")
            let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId

            this.setState({locationId : locationId})

            console.log(this.state.customerId,locationId,this.state.languageType)
            // let params = queryString.parse(this.props.location.search)
            if (this.state.bookingDate.length !== 0 && this.state.bookingTime.length !== 0) {

                if (this.state.errors.errBookingDate == '' && this.state.errors.errBookingTime == '') {

                    this.props.getTableReservationTimeSlotList(this.state.customerId, locationId, this.state.languageType, this.state.bookingDate,this.state.bookingTime,this.state.totalPerson).then(() => {

                        console.log("response123 :",this.props.data)
                        if (this.props.data.status == 1) {
                            this.setState({
                                // isAlertVisible: true, 
                                // alertMessage: this.props.data.message, 
                                // isMultiButton: false, 
                                // btnLeft: Strings.Ok_web
                                componentLoadStep : 'step2',
                                bookingTimeSloat : this.props.data.result.objTime
                            })


                        } else {
                            this.setState({isAlertVisible: true, alertMessage: this.props.data.message, isMultiButton: false, btnLeft: Strings.Ok_web})
                        }

                        

                    })
                    .catch((err)=>{
                        console.log('error ',err)
                    })

    
                } else {
                    console.error('Not valid form')
                }
            }
        })
    }

    onCheckOutBtn = (event) =>{
        event.preventDefault();
        
        if(this.state.fromTime != '' && this.state.toTime != '' && this.state.tableNoOfPeople != ''){
            this.setState({ errors: {errBookingSlotTime : ''}, componentLoadStep : 'step3'})
        }
        else{
            this.setState({ errors: {errBookingSlotTime : 'Plase select any one time sloat.'}})
        }
        

        
    }

    onPayNow = () => {
        var data = {
            locationId: this.state.locationId,
            customerId: this.state.customerId,
            languageId: this.state.languageType,
            date: this.state.bookingDate,
            fromTime: this.state.fromTime,
            toTime: this.state.toTime,
            noOfPeople: this.state.totalPerson,
            tableNoOfPeople: this.state.tableNoOfPeople
        }

        console.log(data)
    }

    onQtyChanged(type, totalPerson, index) {
        var quantity = totalPerson
        if (type == 0) {
            if (quantity > 1) {
                quantity -= 1
            }
        } else {
            quantity += 1
        }

        this.setState({
            totalPerson : quantity
        })

    }

    handleTextFieldChange = (event) => {

        var todays = new Date().toISOString();
        console.log(todays)
        var today = new Date();
        const minutes = (today.getMinutes() < 10) ? `0${today.getMinutes()}` : today.getMinutes()
        var time = today.getHours() + ":" + minutes;
        

        if(event.target.name == 'bookingTime' && event.target.value < time){
            this.setState({ [event.target.name]: '' });
            this.setState({errors: { errBookingTime: 'Please select a booking time after current date.'} })
            return
        }
        else if(event.target.name == 'bookingTime' && event.target.value > time){
            this.setState({errors: { errBookingTime: ''} })
        }

        this.setState({ [event.target.name]: event.target.value });
        
    } 
    statusBadge = (item) => {
        // if(item.orderstatus == 0){
        //     return (
        //         <span class="badge badge-danger">{item.orderstatusText}</span>
        //     )
        // }
        // else if(item.orderstatus == 1){
        //     return (
        //         <span class="badge badge-success">{item.orderstatusText}</span>
        //     )
        // }
        // else if (item.orderstatus == 3) {
        //     return (
        //         <span class="badge badge-warning">{item.orderstatusText}</span>
        //     )
        // }
        // else{
            return (item.orderstatusText)        
        // }
    }

    upComingBooking() {
        if (this.state.upComingBooking != null && this.state.upComingBooking.length > 0) 
            {

                
                    return (
                        this.state.upComingBooking.map((item, index) => (
                            <tbody key={index}>
                                <tr  >
                                    <td class="row-center" data-column="Booking Date">{item.displaybookingtime}</td>
                                    <td class="row-center" data-column="Booking Time">{this.convertFrom24To12Format(item.bookingtime)}</td>
                                    <td class="row-center" data-column="Person">{item.noOfPerson}</td>
                                    <td class="row-center" data-column="Amount">{getPriceFormat(item.bookingAmount)}</td>
                                    <td class="row-center" data-column="Area">{item.tableAreaType == 1 ? 'Inside' : 'Outside'  }</td>
    
                                    <td class="row-center" data-column="Status">
                                        {/* <button onClick={() => {
                                            setSecureData(baseConstants.PRE_ORDER_ID,item.orderId)
                                            // this.props.history.push({ pathname: '/OrderDetails' })
                                        }} className="btn btn-primary btn-small order-btn">{item.orderstatusText}</button> */}
                                        {
                                            this.statusBadge(item)
                                        }
                                    </td>
                                    <td class="row-center" data-column="View">
                                        <button onClick={() =>{this.setState({componentLoadStep : 'step2', tableBookDetailRow : item, upComingData : true})}} className="btn btn-primary btn-small order-btn">{'View Detail'}</button>
                                    </td>
                                </tr>
                            </tbody>
                        ))
                    )
                
            
        }
        else {
            return (
                <tr style={{ textAlign: 'center' }} class="error-no-record">
                    <td colspan="5">
                        <h3>{'Upcoming Booking Not Available.'}</h3>
                    </td>
                </tr>
            )
        }
    }
    
    postBooking() {
        if (this.state.pastBooking != null && this.state.pastBooking.length > 0) 
            {

                
                    return (
                        this.state.pastBooking.map((item, index) => (
                            <tbody key={index}>
                                <tr>
                                    <td class="row-center" data-column="Booking Date">{item.displaybookingtime}</td>
                                    <td class="row-center" data-column="Booking Time">{this.convertFrom24To12Format(item.bookingtime)}</td>
                                    <td class="row-center" data-column="Person">{item.noOfPerson}</td>
                                    <td class="row-center" data-column="Amount">{getPriceFormat(item.bookingAmount)}</td>
                                    <td class="row-center" data-column="Area">{item.tableAreaType == 1 ? 'Inside' : 'Outside'  }</td>
                                    <td class="row-center" data-column="Status">{item.orderstatusText}</td>  
                                    <td class="row-center" data-column="View">
                                        <button onClick={() =>{this.setState({componentLoadStep : 'step2', tableBookDetailRow : item, upComingData : false})}} className="btn btn-primary btn-small order-btn">{'View Detail'}</button>
                                    </td>
                                </tr>
                            </tbody>
                        ))
                    )
                
            
        }
        else {
            return (
                <tr style={{ textAlign: 'center' }} class="error-no-record">
                    <td colspan="5">
                        <h3>{'Past Booking Not Available.'}</h3>
                    </td>
                </tr>
            )
        }
    }
    tabChangeFun = (tab) => {
        this.setState({ActiveTab : tab})

        if(tab != 'bookaTable'){
            this.checkUserLogin()
        }
    }
    bookTableListView = () => {
        return (
            <div className="content_wrap orderList">
                    <div className="container" >
                        <div class="add-booktab-btn-wrp">
                            <div className="add-booktab-btn" style={{float: 'right'}}>
                                
                            {
                                getSecureData(Constant.LOCATIONOBJECT) != undefined && getSecureData(Constant.LOCATIONOBJECT).isWaitingList == true
                                ?
                                <a  className="btn btn-primary order-btn VirtualQueue" href="/VirtualQueue" >Virtual Queue</a>
                                : 
                                null
                            }
                                    
                                
                                {/* <Dropdown onSelect={(e) => this.onfilter(e)} style={{alignSelf:'center'}}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                    {this.state.orderStatusType==0?"Select type ":(this.state.orderStatusType == 1 ? Strings.str_pending : Strings.str_complete)} 
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu} style={{height:"90px",overflow:"scroll"}}>
                                        <Dropdown.Item eventKey="0">All</Dropdown.Item>
                                        <Dropdown.Item eventKey="1">{Strings.str_pending}</Dropdown.Item>
                                        <Dropdown.Item eventKey="2">{Strings.str_complete}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                            </div>
                            <Tabs
                            id="controlled-tab-example"
                            activeKey={this.state.ActiveTab}
                            onSelect={(k) => {this.tabChangeFun(k)}}
                            >
                                <Tab eventKey="bookaTable" title="BOOK A TABLE">
                                    <BookTable />
                                </Tab>
                                <Tab eventKey="upcoming" title="UPCOMING BOOKING">
                                    <div className="all-order" >
                                        <div className="row">
                                            <div className="col-md-12  col-lg-12 ">
                                                <div className="full-w" >
                                                <h1>{this.state.tableTitle}</h1>
                                                
                                                <table className="table table-responsive bookTabCustList">
                                                    <thead>
                                                        <tr>
                                                            <th className="header-center">{Strings.lbl_tblbooking_date}</th>
                                                            <th className="header-center">{Strings.lbl_tblbooking_time}</th>
                                                            <th className="header-center">{Strings.lbl_tblbooking_person}</th>
                                                            <th className="header-center">{Strings.lbl_tblbooking_amount}</th>
                                                            <th className="header-center">{Strings.lbl_tblbooking_area}</th>
                                                            <th className="header-center">{Strings.lbl_tblbooking_status}</th>
                                                        </tr>
                                                    </thead>
                                                    {   
                                                        
                                                        this.upComingBooking()
                                                    }
                                                </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="post" title="PAST BOOKING">
                                    <div className="all-order" >
                                        <div className="row">
                                            <div className="col-md-12  col-lg-12">
                                                <div className="full-w" >
                                                <h1>{'Past Booking'}</h1>
                                                
                                                <table className="table table-responsive bookTabCustList">
                                                    <thead>
                                                        <tr>
                                                            <th className="header-center">{Strings.lbl_tblbooking_date}</th>
                                                            <th className="header-center">{Strings.lbl_tblbooking_time}</th>
                                                            <th className="header-center">{Strings.lbl_tblbooking_person}</th>
                                                            <th className="header-center">{Strings.lbl_tblbooking_amount}</th>
                                                            <th className="header-center">{Strings.lbl_tblbooking_status}</th>
                                                        </tr>
                                                    </thead>
                                                    {   
                                                        
                                                        this.postBooking()
                                                    }
                                                </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Tab>
                            </Tabs>
                        
                        </div>
                        
                    </div>
                </div>
                
        )
    }

    cancelBookedTable = () => {

            if(typeof this.state.tableBookDetailRow.Id !== undefined){
                let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId

                this.setState({locationId : locationId})
                var data = {
                    locationId: locationId,
                    customerId: this.state.customerId,
                    languageId: this.state.languageType,
                    bookingId : this.state.tableBookDetailRow.Id
                }
        
                if(this.state.customerId != 0  && this.state.languageType != '' && locationId != ''){
                    this.props.updateTableReservationBookingRequest(data).then(() => {
        
                        console.log("response123 cancel:",this.props.data)
                        if (this.props.data.status == 1) {
                            this.setState({
                                // isAlertVisible: true, 
                                // alertMessage: this.props.data.message, 
                                // isMultiButton: false, 
                                // btnLeft: Strings.Ok_web
                                componentLoadStep : 'step1',
                            })

                            this.getTableBookingHistoryByCustomer()
                            // this.setState({isAlertVisible: true, alertMessage: this.props.data.message, isMultiButton: false, btnLeft: Strings.Ok_web})
            
                        } else {
                            this.setState({isAlertVisible: true, alertMessage: this.props.data.message, isMultiButton: false, btnLeft: Strings.Ok_web})
                        }
            
                        
            
                    })
                    .catch((err)=>{
                        console.log('error ',err)
                    })
                }
            }
    }

    bookTableListViewDetail = () => {
        console.log("this.state.tableBookDetailRow",this.state.tableBookDetailRow)
        return (
            <div className="content_wrap">
            <div className="container">
                        <div className="row" style={{float: 'right'}}>
                                <button className="btn btn-primary order-btn" onClick={() => {this.setState({componentLoadStep : 'step1'})}}>Back</button>
                        </div>
                <div className="user">

                    <div className="row">
                        <div className="col-md-12  col-lg-6 center" style={{margin:'0 auto',textAlign: 'center'}}>
                            <h1 className="text-center">{'Booking For'}</h1>

                            <div className="card-body" >
                                <div className="form-group">
                                    <label>
                                        <p>
                                            Your {this.state.tableBookDetailRow.tableAreaType == 1 ? 'Inside' : 'Outside'  } table booking is {this.state.tableBookDetailRow.orderstatusText} for <br/> <span className="bkTblDate" >{this.state.tableBookDetailRow.displaybookingtime } {this.convertFrom24To12Format(this.state.tableBookDetailRow.bookingtime)}</span> <br /> for {this.state.tableBookDetailRow.noOfPerson} people.
                                        </p>
                                    </label>
                                </div>
    

                                <div className="checkoutBtn" >
                                    {
                                            this.state.tableBookDetailRow.orderstatus == 3 && this.state.upComingData === true
                                        ?
                                            
                                            <div className="buttons-checkout">
                                                <button className="btn btn-primary btn-medium" onClick={() =>{this.setState({componentLoadStep : 'step3', tableBookDetailRow : this.state.tableBookDetailRow})}}> PAY NOW</button>
                                            </div>

                                        : 

                                            this.state.upComingData === true
                                            ?
                                                this.state.tableBookDetailRow.Ispreorder == true 
                                                ?
                                                    <div className="buttons-checkout">
                                                        <button 
                                                            className="btn btn-primary btn-medium" 
                                                            onClick={() => {
                                                            // localStorage.setItem(baseConstants.PRE_ORDER_ID,JSON.stringify(item.orderId))
                                                            setSecureData(baseConstants.PRE_ORDER_ID,this.state.tableBookDetailRow.orderId)
                                                            this.props.history.push({ pathname: '/OrderDetails' })
                                                            }}> 
                                                            VIEW ORDER
                                                            </button>
                                                    </div>
                                                :
                                                getSecureData(Constant.LOCATIONOBJECT) != undefined && getSecureData(Constant.LOCATIONOBJECT).isPreOrder == true
                                                ?
                                                    <div className="buttons-checkout">
                                                        <button className="btn btn-primary btn-medium" 
                                                            onClick={() => {
                                                                
                                                                localStorage.remove(Constant.USERCART)
                                                                let tableObject = {}
                                                                tableObject.bookingId = this.state.tableBookDetailRow.Id
                                                                tableObject.contactTime = this.state.tableBookDetailRow.bookingtime
                                                                tableObject.contactDate = moment(this.state.tableBookDetailRow.displaybookingtime, 'DD MMM YYYY').format('MM/DD/yyyy')
                                                                tableObject.bookingDepositAmount = this.state.tableBookDetailRow.bookingAmount
                                                                localStorage.setItem('tablePreBookingObj',JSON.stringify(tableObject))
                                                            this.props.history.push({ pathname: '/MenuItems' })}}
                                                        > 
                                                            Pre Order
                                                        </button>
                                                    </div>
                                                :
                                                null
                                            :
                                                this.state.tableBookDetailRow.Ispreorder == true && this.state.tableBookDetailRow.orderstatus != 0
                                                ?
                                                    <div className="buttons-checkout">
                                                        <button 
                                                            className="btn btn-primary btn-medium" 
                                                            onClick={() => {
                                                            // localStorage.setItem(baseConstants.PRE_ORDER_ID,JSON.stringify(item.orderId))
                                                            setSecureData(baseConstants.PRE_ORDER_ID,this.state.tableBookDetailRow.orderId)
                                                            this.props.history.push({ pathname: '/OrderDetails' })
                                                            }}> 
                                                            VIEW ORDER
                                                            </button>
                                                    </div>
                                                :
                                                null
                                    
                                    }
                                    {
                                            this.state.tableBookDetailRow.orderstatus == 0 || this.state.upComingData == false
                                            ?
                                            null
                                            :
                                                this.state.tableBookDetailRow.orderstatus == 3 
                                                ?
                                                null
                                                : 
                                                <div className="buttons-checkout">
                                                    <button className="btn btn-primary btn-medium" onClick={() => {this.cancelBookedTable()}}>Cancel Booking</button>
                                                </div>
                                    }
                                    
                                </div>
                            </div>
                            
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
        )
    }

    bookTablePayNow = () => {
        return (
            <div className="iframePayNow">
                <iframe src={this.state.tableBookDetailRow.paymentUrl} width='100%' height='100%' style={{top:0}}></iframe>
            </div>
        )
    }


    render() {
        return (
            <div className="loginpage white nonsticy" style={{}}> 
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}
                {/* <HeaderMenu /> */}
                {/* Old header */}
               <HeaderWhiteMenuHalf isHideOrderType={true} headerLbl={'Book A Table'} isCategory={true} 
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/> 
                <AlertDialog 
                isAlertVisible={this.state.isAlertVisible} 
                msgTitle={this.state.alertMessage} 
                isMultibutton={this.state.isMultiButton} 
                btnLeftClick={() => { 
                    this.setState({ isAlertVisible: false });
                    // window.location.href = Constant.mainURL
                    return
                 }} 
                 btnRightClick={() => { 
                     this.onRightClick() }} 
                btnLeftTitle={this.state.btnLeft} 
                btnRightTitle={this.state.btnRight} />
                {/* <HeaderSignin logoType={1} headerLbl={Strings.reset_pass_web} isCategory={true} /> */}
                
                {(() => {
                      switch (this.state.componentLoadStep) {
                        case 'step1':
                            return this.bookTableListView()
                        case 'step2':
                            return this.bookTableListViewDetail()
                        case 'step3':
                            return this.bookTablePayNow()
                        default:
                            return ''
                     }
                })()}

                
                <FooterMenu />
            </div>
        )
    }

     convertDate = () =>{
        let d = new Date(this.state.bookingDate)

        const year = d.getFullYear()
        const month = months[d.getMonth()]
        const date = d.getDate() 
        return date + ' ' + month + ' '+ year;
    }

    setTimesloat = (val) => {
        
        
        this.setState({
            // componentLoadStep : 'step3',
            activeCardID : val.tId,
            fromTime : val.fromTime,
            toTime : val.toTime,
            tableNoOfPeople : val.tableNoOfPeople,
        })

        // {"restaurantId":147,"locationId":363,"customerId":33006,"languageId":1,"date":"05/31/2021","fromTime":"18:30","toTime":"19:00","noOfPeople":1,"tableNoOfPeople":2}
    }

    convertFrom24To12Format = (time24) => {
        const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        const period = +sHours < 12 ? 'AM' : 'PM';
        const hours = +sHours % 12 || 12;
      
        return `${hours}:${minutes} ${period}`;
      }

}

function mapStateToProps(state) {
    console.log('isLoaging ResetPassword ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    getTableReservationTimeSlotList: (customerId,locationId, languageId, bookingDate, BookingTime,totalPerson) => dispatch(HGActions.getTableReservationTimeSlotList(customerId,locationId, languageId, bookingDate, BookingTime, totalPerson)),
    getTableBookingHistoryByCustomer: (data) => dispatch(HGActions.getTableBookingHistoryByCustomer(data)),
    updateTableReservationBookingRequest: (data) => dispatch(HGActions.updateTableReservationBookingRequest(data)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
    locationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),

})
export default connect(mapStateToProps, mapDispatchToProps)(BookTableList);