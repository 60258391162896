import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import {
  Route,
  Redirect,
  NavLink,
  BrowserRouter as Router,
  Switch,
  Link,
  history,
} from "react-router-dom";

import Constants from "./constants/baseConstants";

import { Provider } from "react-redux";
import configureStore from "./Redux/store/index";

import SignIn from "./Screens/SignIn";
import SignUp from "./Screens/SignUp";
import MobileVerification from "./Screens/MobileVerification";
import MyAccount from "./Screens/MyAccount";
import MyOrders from "./Screens/MyOrders";
import Categories from "./Screens/Categories";
import Logout from "./Screens/Logout";
import MenuItems from "./Screens/MenuItems";
import CheckoutDelivery from "./Screens/CheckoutDelivery";
import CheckoutTakeaway from "./Screens/CheckoutTakeaway";
import OrderDetails from "./Screens/OrderDetails";
import Savedcards from "./Screens/SavedCards";
import UserFeedBack from "./Screens/UserFeedback";
import CardListing from "./Screens/CardListing";
import CardDetails from "./Screens/CardDetails";
import WaiterListing from "./Screens/WaiterListing";
import WaiterDetails from "./Screens/WaiterDetails";
import PaymentCardList from "./Screens/PaymentCardList";
import cancelOrder from "./Screens/cancelOrder";
import ResetPassword from "./Screens/ResetPassword";
import OfficeRegistration from "./Screens/OfficeRegistration";
import Offers from "./Screens/Offers";
import Home from "./Screens/Home";
import BookTable from "./Screens/BookTable";
import BookTableList from "./Screens/BookTableList";
import BookTableConfirm from "./Screens/BookTableConfirm";
import VirtualQueue from "./Screens/VirtualQueue";
import Rewards from "./Screens/Rewards";
import MyRewards from "./Screens/MyRewards";
import EnjoyRewards from "./Screens/EnjoyRewards";
import ViewLevels from "./Screens/ViewLevels";
import LoyaltyHistory from "./Screens/LoyaltyHistory";
import BestOfferList from "./Screens/BestOfferList";
import Myfav from "./Screens/Myfav";
import AboutUs from "./Screens/AboutUs";
import ContactUs from "./Screens/ContactUs";
import TermsConditions from "./Screens/TermsConditions";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import LoyaltyRewards from "./Screens/LoyaltyRewards";
import PaymentComplete from "./Components/PaymentComplete";
// import BookTableIframe from './Screens/BookTableIframe'
// const AuthenticatedRoute = ({ component: Component, ...rest }) => (
//     <Route
//       {...rest}
//       render={(props) => (
//         (localStorage.getItem(Constants.USEROBJECT) != null || localStorage.getItem(Constants.USEROBJECT) != undefined) ?

//           return (<Component {...props} />)
//          :
//           return (<Redirect
//             to={{
//               pathname: "/SignIn",
//               state:{from: props.location}
//             }}
//           />)
//       )}
//     />
// );

const store = configureStore();
console.log = console.warn = console.error = () => {};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/home" component={Home}></Route>
          <Route exact path="/SignIn" component={SignIn}></Route>
          <Route path="/SignUp" component={SignUp}></Route>
          <Route
            path="/MobileVerification"
            component={MobileVerification}
          ></Route>
          <Route path="/MyAccount" component={MyAccount}></Route>
          <Route path="/MyOrders" component={MyOrders}></Route>
          <Route path="/Categories" component={MenuItems}></Route>
          {/* <AuthenticatedRoute path="/Categories" component={{Categories}} /> */}
          <Route path="/MenuItems" component={MenuItems}></Route>
          <Route path="/OrderDetails" component={OrderDetails}></Route>
          <Route path="/UserLogout" component={Logout}></Route>
          <Route path="/CheckoutDelivery" component={CheckoutDelivery}></Route>
          <Route path="/CheckoutTakeaway" component={CheckoutTakeaway}></Route>
          {/* <Route path="/Savedcards" component={Savedcards}></Route> */}
          {/* <Route path="/UserFeedBack" component={UserFeedBack}></Route> */}
          <Route path="/CardListing" component={CardListing}></Route>
          <Route path="/CardDetails" component={CardDetails}></Route>
          {/* <Route path="/WaiterListing" component={WaiterListing}></Route>
            <Route path="/WaiterDetails" component={WaiterDetails}></Route> */}
          <Route path="/PaymentCardList" component={PaymentCardList}></Route>
          <Route path="/cancelOrder" component={cancelOrder}></Route>
          <Route path="/ResetPassword" component={ResetPassword}></Route>
          <Route path="/Offers" component={Offers}></Route>

          {/* <Route path="/BookTable" component={BookTable} ></Route> */}
          <Route path="/BookTable" component={BookTableList}></Route>
          <Route path="/BookTableList" component={BookTableList}></Route>
          {/* <Route path="/BookTablePay" component={BookTableIframe} /> */}

          <Route path="/BookTableConfirm" component={BookTableConfirm}></Route>
          <Route path="/VirtualQueue" component={VirtualQueue}></Route>

          <Route path="/Rewards" component={LoyaltyRewards}></Route>
          {/* <Route path="/Rewards" component={Rewards} />
            <Route path="/MyRewards" component={MyRewards}></Route>
            <Route path="/EnjoyRewards" component={EnjoyRewards}></Route>
            <Route path="/ViewLevels" component={ViewLevels} ></Route>
            <Route path="/LoyaltyHistory" component={LoyaltyHistory}></Route>
            <Route path="/BestOfferList" component={BestOfferList}></Route> */}
          <Route path="/BestOfferList" component={BestOfferList}></Route>
          <Route path="/MyFavorites" component={Myfav}></Route>
          <Route path="/AboutUs" component={AboutUs}></Route>
          <Route path="/ContactUs" component={ContactUs}></Route>
          <Route path="/TermsConditions" component={TermsConditions}></Route>
          <Route path="/PrivacyPolicy" component={PrivacyPolicy}></Route>
          <Route
            path="/PaymentComplete/:id"
            component={PaymentComplete}
          ></Route>
          <Route path="/ok" component={PaymentComplete}></Route>
          {/* <Route path="*" component={PaymentComplete}></Route> */}
          {/* <Route path="/OfficeRegistration" component={OfficeRegistration}></Route> */}
          {/* <Route path="/SignUp/:id" component={SignUp}></Route> */}
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
