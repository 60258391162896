import React, { Component } from "react";
import { history, Link } from "react-router-dom";
// import { createBrowserHistory } from "history";

import { connect } from "react-redux";
import * as HGActions from "../Redux/actions/HGActions";
import Constants from "../constants/baseConstants";
import HeaderWhiteMenu from "../Components/HeaderWhiteMenu";
import Modal from "react-bootstrap/Modal";
import FooterMenu from "../Components/FooterMenu";
import ItemPopup from "../Components/ItemPopup";
import AlertDialog from "../Components/AlertDialog";
import { Strings } from "../constants/Localization";
import AOS from "aos";
import "aos/dist/aos.css";
import moment from "moment";
import { getCurrencySign, bindhttps } from "../Utils/utils";
import { setSecureData, getSecureData } from "../Utils/utils";
import baseConstants from "../constants/baseConstants";
import queryString from "query-string";

var categoryName = "";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayWholeMenuItems: [],
      arrayMenuItems: [],
      arraySubCategory: [],
      arrayFilterTypes: [],
      isFilter: false,
      isItemPopupVisible: false,
      objectAt: 0,
      // catId: JSON.parse(localStorage.getItem(Constants.PRE_CAT_ID)),//this.props.location.catId,
      // arrayMainCategories: JSON.parse(localStorage.getItem(Constants.CATEGORIESDATA)),//this.props.location.Categorieslist,
      catId: getSecureData(Constants.PRE_CAT_ID), //this.props.location.catId,
      arrayMainCategories: getSecureData(Constants.CATEGORIESDATA), //this.props.location.Categorieslist,

      isAlertVisible: false,
      alertMessage: "",
      btnLeft: "",
      btnRight: "",
      isMultiButton: false,
      languageType: 1,

      isVisible: false,
      locationArray: [],

      /* comment for order type */
      isOrderTypeVisible: false,
      arrayOrderTypes: [],

      isNo: false,
      locationObj: {
        address1: "6 Harlestone Rd, Northampton \n NN5 7AE, United Kingdom",
        countryName: "United Kingdom",
        phone: "+44 1604 757007",
        locationTimeList: [
          {
            day: "Monday - Sunday",
            time: "01:00 AM - 11:00 PM",
          },
        ],
      },
      isTableBookingStatus : false
    };
  }

  componentDidMount() {
    AOS.init({
      duration: 1000,
    });

    // if (localStorage.hasOwnProperty(Constants.USEROBJECT)) {
    //   let userObject = getSecureData(Constants.USEROBJECT);
    //   if (userObject == null) {
    //     this.props.history.push({ pathname: '/' })
    //     return
    //   }
    // }
    // else {
    //   this.props.history.push({ pathname: '/' })
    //   return
    // }

    let params = queryString.parse(this.props.location.search);
    if (params.isRemove) {
      console.log("query string -------------> ", params);
      localStorage.removeItem(Constants.USERCART);
      localStorage.removeItem(Constants.PLACEDORDEROBJECT);
      localStorage.removeItem(Constants.ISCANCEL);
    }

    let session = getSecureData(Constants.PRE_MESSAGE_LIST);
    let mLanguageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
    // let mLanguageCode = localStorage.getItem(Constants.PRE_LANGUAGE_CODE);
    console.log("default lanague type  :" + mLanguageType);
    if (session != null && session.length > 0) {
      // session = JSON.parse(session);
      if (mLanguageType != null && mLanguageType == 1) {
        Strings.setLanguage("en"); //Setting selected language code here
        this.setState({ languageType: mLanguageType });
      } else {
        if (mLanguageType != null) {
          Strings.setLanguage("es"); //Setting selected language code here
          this.setState({ languageType: mLanguageType });
        }
      }
    } else {
      Strings.setLanguage("en"); //Setting default language code here
    }

    setTimeout(() => {
      if (!localStorage.hasOwnProperty(Constants.CHECKINFO)) {
        this.props
          .callCheckVersion(0, this.state.languageType)
          .then(() => {
            if (this.props.data.status == 1) {
              setTimeout(() => {
                setSecureData(Constants.CHECKINFO, this.props.data.result);
                this.callLocationListingAPI();
              }, 500);
            } else {
              setTimeout(() => {
                this.callLocationListingAPI();
              }, 500);
            }
          })
          .catch((error) => {
            setTimeout(() => {
              this.callLocationListingAPI();
            }, 500);
          });
      } else {
        this.callLocationListingAPI();
      }

      // this.callGetCategoryListApi()
      // this.getCategoryItem()
    }, 200);
    // this.fetchLocationList()
  }

  //Handle change language click event
  //English language type: 1
  //Spanish language type: 2
  languageChangeClickEvent = (type) => {
    return;
    console.log("menuitem page language type :" + type);
    // localStorage.setItem(Constants.PRE_LANGUAGE_TYPE, type)
    setSecureData(Constants.PRE_LANGUAGE_TYPE, type);
    this.setState({ languageType: type });
    setTimeout(() => {
      this.props.AppMessageApi(this.state.languageType).then(() => {
        if (this.props.data.status == 1) {
          var messageListArray = this.props.data.result;
          if (messageListArray != null && messageListArray.length > 0) {
            console.log("message list  :" + JSON.stringify(messageListArray));
            // localStorage.setItem(Constants.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
            setSecureData(Constants.PRE_MESSAGE_LIST, messageListArray);
            // let localMessage = JSON.parse(localStorage.getItem(Constants.PRE_MESSAGE_LIST));
            let localMessage = getSecureData(Constants.PRE_MESSAGE_LIST);
            // localStorage.setItem(Constants.PRE_LANGUAGE_TYPE, this.state.languageType)
            setSecureData(Constants.PRE_LANGUAGE_TYPE, this.state.languageType);
            // let languageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
            let languageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
            console.log("language type  :" + languageType);
            if (languageType == 1) {
              //English language type: 1
              Strings.setLanguage("en"); //Setting selected language code here
              this.setState({ languageType: languageType });
              // localStorage.setItem(Constants.PRE_LANGUAGE_CODE, "en-GB");
            } else {
              Strings.setLanguage("es"); //Setting selected language code here
              this.setState({ languageType: languageType });
              // localStorage.setItem(Constants.PRE_LANGUAGE_CODE, "es");
            }
            Strings.setContent(localMessage);
            // this.setState({ languageType: languageType })
            //Call Menu items API while change language
            setTimeout(() => {
              this.callGetCategoryListApi();
            }, 500);
          }
        } else {
          alert(this.props.error);
        }
      });
    }, 700);
  };

  //This method use show location dialog
  showLocationListDialog() {
    if (!this.state.isVisible) {
      return;
    }
    if (
      this.state.locationArray != null &&
      this.state.locationArray.length > 0
    ) {
      return (
        <Modal
          show={this.state.isVisible}
          onHide={() => {}}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="popupHeader">
              <label className="headerTitle">
                {Strings.lbl_select_location_react}
              </label>
            </div>
            {/* <!-- The pop --> */}
            <div className="pop loc-popup" id="mypop">
              <div className="pop-dialog">
                <div className="pop-content">
                  <div className="pop-body">
                    <div className="prd-s">
                      <div className="prd-cont">
                        {this.setLocationList(this.state.locationArray)}
                      </div>
                    </div>
                    {/* {this.state.locationObj != null ? (
                                      <div className="continue-btn"> <button className="btn btn-primary btn-small order-btn" onClick={() => { this.onContinue() }}>{Strings.btn_continue_react}</button></div>
                                  ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    } else {
      return;
    }
  }

  //This method use bind location data
  setLocationList(itemData) {
    if (itemData != null && itemData.length > 0) {
      return itemData.map((item, index) => (
        <div key={index}>
          <div
            className="chek-new"
            onClick={() => {
              this.onContinue(item, index);
            }}
          >
            <label className="container1">
              <h3>{item.locationName}</h3>
              <p>{item.distance > 0 ? item.distance : null}</p>
              {/* <input
                              type="checkbox"
                              defaultChecked={true} />
                          <span className={this.state.selectIndex == index ? "checkmark" : ''}></span> */}
            </label>
          </div>
        </div>
      ));
    }
  }

  onContinue = (item, index) => {
    if (getSecureData(Constants.USERCART)) {
      // console.log('location object  ----------- ',getSecureData(Constants.LOCATIONOBJECT))
      // console.log('select location object  ----------- ',item)

      if (
        getSecureData(Constants.LOCATIONOBJECT).locationId != item.locationId
      ) {
        // console.log('hdfuehduehduheudheudhuehdu')
        this.setState({
          isVisible: false,
          selectIndex: index,
          locationObj: item,
          orderType: true,
          isAlertVisible: true,
          alertMessage: Strings.empty_cart_message,
          isMultiButtom: true,
          btnLeft: Strings.btn_cancel,
          btnRight: Strings.btn_confirm,
        });
      } else {
        this.setState(
          { selectIndex: index, locationObj: item, orderType: true },
          () => {
            this.setLocationData();
          }
        );
      }
    } else {
      this.setState(
        { selectIndex: index, locationObj: item, orderType: true },
        () => {
          this.setLocationData();
        }
      );
    }
  };

  setLocationData() {
    return;
    setTimeout(() => {
      if (this.state.locationObj == null) {
        // if (item == null) {
        alert(Strings.lbl_select_location_react);
      } else {
        // console.log('prev location =------ ',getSecureData(Constants.ORDERTYPE))

        let locationObj = this.state.locationObj;
        var arry = [];
        if (locationObj.isDelivery) {
          arry.push(Strings.Delivery_web);
        }
        if (locationObj.isTakeAway) {
          arry.push(Strings.lbl_takeaway_react);
        }
        if (locationObj.isOrderExtras) {
          arry.push(Strings.str_sitDown);
        }
        // console.log('current type =------ ',arry)
        if (arry.includes(getSecureData(Constants.ORDERTYPE))) {
          setSecureData(Constants.LOCATIONOBJECT, this.state.locationObj);
          Constants.locationId = this.state.locationObj.locationId;
          this.setState({ isVisible: false }, () => {
            setTimeout(() => {
              this.callGetCategoryListApi();
            }, 500);
          });
        } else {
          setSecureData(Constants.LOCATIONOBJECT, this.state.locationObj);
          Constants.locationId = this.state.locationObj.locationId;

          var arry = [];
          if (locationObj.isDelivery) {
            arry.push({ title: Strings.Delivery_web, isSelect: false });
          }
          if (locationObj.isTakeAway) {
            arry.push({ title: Strings.lbl_takeaway_react, isSelect: false });
          }
          if (locationObj.isOrderExtras) {
            arry.push({ title: Strings.str_collect_drink, isSelect: false });
          }
          this.setState({
            isVisible: false,
            isOrderTypeVisible: true,
            arrayOrderTypes: arry,
          });
        }

        return;

        // localStorage.setItem(Constants.LOCATIONOBJECT, JSON.stringify(this.state.locationObj))
        setSecureData(Constants.LOCATIONOBJECT, this.state.locationObj);
        // setSecureData(Constants.LOCATIONOBJECT, item)
        Constants.locationId = this.state.locationObj.locationId;
        // Constants.locationId = item.locationId

        let locationObject = this.state.locationObj;
        var arry = [];
        if (locationObject.isDelivery) {
          arry.push({ title: Strings.Delivery_web, isSelect: false });
        }
        if (locationObject.isTakeAway) {
          arry.push({ title: Strings.lbl_takeaway_react, isSelect: false });
        }
        /* comment for order type */
        if (locationObject.isOrderExtras) {
          arry.push({ title: Strings.str_collect_drink, isSelect: false });
        }
        {
          /* comment for order type */
        }
        // this.setState({ isVisible: false, arrayOrderTypes: arry},()=>{
        //   setTimeout(() => {
        //     this.callGetCategoryListApi()
        //   }, 500);
        // })
        this.setState({
          isVisible: false,
          isOrderTypeVisible: true,
          arrayOrderTypes: arry,
        });
      }
    }, 200);
  }

  callLocationListingAPI() {
    if (this.props.location.isChangeLocation) {
      this.fetchLocationList();
    } else {
      this.fetchLocationList();
      return;
    }
  }

  fetchLocationList() {
    this.props.locationListApi(this.state.languageType).then(() => {
      if (this.props.data.status == 1) {
        if (this.props.data.result != null) {
          var locationList = this.props.data.result;
          if (locationList != null && locationList.length > 0) {
            console.log("locationList", locationList)
            if (getSecureData(Constants.LOCATIONOBJECT)) {
              console.log('isTableDepositDiscount  ----------- ',getSecureData(Constants.LOCATIONOBJECT).isTableDepositDiscount)
              // console.log('locationList  ----------- ',locationList)

              let objLocation = locationList.filter((obj) => {
                return (
                  obj.locationId ==
                  getSecureData(Constants.LOCATIONOBJECT).locationId
                );
              });

              this.setState({ locationObj: objLocation[0], isTableBookingStatus : objLocation[0].isTableBooking  }, () => {
                setSecureData(Constants.LOCATIONOBJECT, objLocation[0]);
                // this.setLocationData()
              });
            } else {
              if (locationList.length == 1) {
                this.setState({ locationObj: locationList[0], isTableBookingStatus : locationList[0].isTableBooking }, () => {
                  setSecureData(Constants.LOCATIONOBJECT, locationList[0]);
                  // this.setLocationData()
                });
              } else {
                setSecureData(Constants.LOCATIONLISTOBJECT, locationList);
                this.setState({ locationArray: locationList, isVisible: true });
              }
            }
          }
        }
      } else {
        console.log("undefined exception",this.props.data.message);
      }
    });
  }

  callGetCategoryListApi() {
    this.props.getCategoriesAPICall(this.state.languageType).then(() => {
      console.log("Category response ---- ", this.props.data);
      if (this.props.data.status == 1) {
        if (this.props.data.result != null) {
          setTimeout(() => {
            if (this.props.data.result.length > 0) {
              this.setState({
                arrayMainCategories: this.props.data.result,
                catId: this.props.data.result[0].catId,
              });
              // localStorage.setItem(Constants.CATEGORIESDATA,JSON.stringify(this.props.data.result))
              setSecureData(Constants.CATEGORIESDATA, this.props.data.result);
              // window.location.reload(false)
              this.getCategoryItem();
            }
          }, 100);
        }
      } else {
        alert(this.props.data.message);
        console.log("Category status 2 ---- ", this.props.data);
      }
    });
  }

  getCategoryItem() {
    this.state.arraySubCategory = [];
    this.state.arrayFilterTypes = [];

    if (!this.state.arrayMainCategories) {
      return;
    }
    let myCatData = this.state.arrayMainCategories.filter(
      (data) => data.catId == this.state.catId
    );
    if (myCatData.length == 0) {
      return;
    }
    categoryName = myCatData[0].catName;
    setTimeout(() => {
      this.props
        .getCategoriesMenuItemsAPICall(
          this.state.catId,
          this.state.languageType
        )
        .then(() => {
          console.log(
            "CATEGORIES MENU ITEMS RESPONSE --------- ",
            this.props.data.result
          );
          if (this.props.data.status == 1) {
            if (this.props.data.result != null) {
              setTimeout(() => {
                if (
                  typeof this.props.data.result.MenuCategoryList.length !==
                    undefined &&
                  this.props.data.result.MenuCategoryList.length == 0
                ) {
                  this.setState({
                    isNo: true,
                    arrayMenuItems: this.props.data.result.MenuItemList,
                    arrayWholeMenuItems: this.props.data.result.MenuItemList,
                  });

                  var filterTitle = [];
                  this.state.arrayMenuItems.map((menuData) =>
                    menuData.MenuStausList.map((status) => {
                      var obj = filterTitle.filter(
                        (flt) => flt.title == status.title
                      );
                      if (obj.length == 0) {
                        let fObj = {
                          title: status.title,
                          iconurl: status.iconurl,
                          iconurlDark: status.iconurlDark,
                          isSelected: true,
                        };
                        filterTitle.push(fObj);
                      }
                    })
                  );
                  console.log("resource ------------------ ", filterTitle);
                  this.setState({ arrayFilterTypes: filterTitle });
                } else if (this.props.data.result.MenuCategoryList.length > 0) {
                  this.setState({
                    arraySubCategory: this.props.data.result.MenuCategoryList,
                    arrayMenuItems: this.props.data.result.MenuItemList,
                    arrayWholeMenuItems: this.props.data.result.MenuItemList,
                  });
                }
                if (window.screen.width < 768) {
                  window.scrollTo(0, 880);
                } else {
                  window.scrollTo(0, 0);
                }
              }, 100);
            }
          } else {
            console.log("api errrrror");
          }
        });
    }, 500);
  }

  onAddClick(i, menuitemId) {
    // this.setState({ isItemPopupVisible: true, objectAt: i })
    // return

    let myCatData = this.state.arrayMainCategories.filter(
      (data) => data.catId == this.state.catId
    );
    let uktime = moment(myCatData[0].ukdatetime, "YYYY-MM-dd HH:mm:ss").format(
      "HH:mm:ss"
    );
    let ukdate = moment(myCatData[0].ukdatetime, "YYYY-MM-DD HH:mm:ss").format(
      "DD-MM-YYYY"
    );

    this.props
      .checkItemTimeAPI(
        uktime,
        ukdate,
        this.state.catId,
        this.state.languageType
      )
      .then(() => {
        if (this.props.data.status == 1) {
          if (this.props.data.result != null) {
            setTimeout(() => {
              console.log("Clicked at menuitemId ", menuitemId);
              if (this.state.arraySubCategory.length > 0) {
                for (var k = 0; k < this.state.arrayMenuItems.length; k++) {
                  if (this.state.arrayMenuItems[k].menuitemId == menuitemId) {
                    i = k;
                    break;
                  }
                }
              }
              console.log("Clicked at ", i);
              this.setState({ isItemPopupVisible: true, objectAt: i });
            }, 100);
          }
        } else {
          this.setState({
            isAlertVisible: true,
            alertMessage: this.props.data.message,
            isMultiButton: false,
            btnLeft: Strings.Ok_web,
          });
        }
      });
  }

  renderOptions(optlist, title) {
    let filrt = optlist.filter((data) => data.labelText == title);

    var arrOpt = [];
    for (var i = 0; i < filrt.length; i++) {
      arrOpt.push(
        <li key={i}>
          <label className="container1">
            <input type="checkbox" checked="checked" />
            <span className="checkmark"></span>
            {filrt[i].type}
          </label>
        </li>
      );
    }
    return arrOpt;
  }

  renderOptionMenu(item, index) {
    console.log("index ------ ", item.menuItemName, index);

    var arrayTitle = [];
    let optList = this.state.arrayMenuItems[index].PriceList[0].OptionList;

    optList.map((data) => {
      var temp = arrayTitle.filter((obj) => obj == data.labelText);
      if (temp.length == 0) {
        arrayTitle.push(data.labelText);
      }
    });
    console.log("arrayTitle ------ ", arrayTitle.length, arrayTitle);

    var arrReturn = [];
    for (var k = 0; k <= arrayTitle.length - 1; k++) {
      console.log("plate-div ----- ", k, optList);
      arrReturn.push(
        <div className="plate-div" key={k}>
          <h4>{arrayTitle[k]}</h4>
          <ul>{/* {this.renderOptions(optList,arrayTitle[k])} */}</ul>
        </div>
      );
    }
    return arrReturn;
  }

  renderMenuStatus(status) {
    let obj = [];
    status.map((data, index) =>
      obj.push(
        <li key={index}>
          <img src={bindhttps(data.iconurl)} />
          {/* <a href="#">
           
          </a> */}
        </li>
      )
    );
    return obj;
  }

  renderMenuItems(menuItem) {
    const itemDiv = menuItem.map((item, index) => (
      <div className="col-lg-4 col-md-12 mb-5" key={index}>
        <div className="prd-s">
          <div className="prd-img">
            {/* <img src="../images/prdmainimg.jpg"  onClick={() => { this.onAddClick(index, item.menuitemId) }}/> */}
            {/* {item.imgurl ? <img src={bindhttps(item.imgurl)}  onClick={() => { this.onAddClick(index, item.menuitemId) }}/> : null} */}
            <img
              src={item.imgurl ? bindhttps(item.imgurl) : "../images/no.jpg"}
              onClick={() => {
                this.onAddClick(index, item.menuitemId);
              }}
            />
            {/* <a href="">
              
            </a> */}
          </div>
          <div className="prd-cont">
            <ul className="vegi">
              {this.renderMenuStatus(item.MenuStausList)}
            </ul>

            <h3
              onClick={() => {
                this.onAddClick(index, item.menuitemId);
              }}
              className="prdTitle"
            >
              {item.menuItemName}
            </h3>
            <p className="prdDesc">{item.description}</p>
            <div className="price-btns-details">
              <p className="lbl">{getCurrencySign()}</p>
              <p className="prdPriceAmt">
                {item.PriceList[0].price.toFixed(2)}
              </p>
              <a
                href=""
                // className="btn btn-primary btn-medium btn-price"
                type="button"
                // className="btn btn-primary btn-price"
                className="greenBtn smallBtnLbl qty"
                data-toggle="modal"
                data-target="#myModal"
                key={index}
                onClick={() => {
                  this.onAddClick(index, item.menuitemId);
                }}
              >
                {Strings.lbl_edit_add_react}
              </a>
            </div>
          </div>
        </div>
      </div>
    ));
    return itemDiv;
  }

  onFilterClick(filterData) {
    filterData.isSelected = !filterData.isSelected;
    this.setState({ arrayFilterTypes: this.state.arrayFilterTypes });

    let flt = this.state.arrayFilterTypes.filter(
      (data) => data.isSelected == false
    );

    if (flt.length == this.state.arrayFilterTypes.length) {
      // this.setState({ arrayMenuItems: this.state.arrayWholeMenuItems })

      let menuItem = this.state.arrayWholeMenuItems;
      var filteredItem = [];

      let newArrList = [];
      for (var i = 0; i < menuItem.length; i++) {
        const ele = menuItem[i].MenuStausList;
        // console.log('elements -------- ',ele)

        const arrList1 = ele.map((item) => item.title);
        const arrList2 = flt.map((item) => item.title);
        let isAdded = false;
        if (arrList1.length == 0) {
          newArrList.push(menuItem[i]);
        }
      }

      this.setState({ arrayMenuItems: newArrList });
    } else {
      if (flt.length > 0) {
        let menuItem = this.state.arrayWholeMenuItems;
        var filteredItem = [];

        let filtItem = menuItem.filter((objItem) => objItem.MenuStausList);
        // console.log('filter item ------- ',filtItem)

        let newArrList = [];
        for (var i = 0; i < menuItem.length; i++) {
          const ele = menuItem[i].MenuStausList;
          // console.log('elements -------- ',ele)

          const arrList1 = ele.map((item) => item.title);
          const arrList2 = flt.map((item) => item.title);
          let isAdded = false;
          if (arrList1.length == 0) {
            newArrList.push(menuItem[i]);
          }
          for (let index = 0; index < arrList1.length; index++) {
            const element = arrList1[index];
            let contains = arrList2.includes(element);
            if (contains == false) {
              isAdded = true;
            } else {
              isAdded = false;
              break;
            }
          }

          if (isAdded) {
            newArrList.push(menuItem[i]);
          }
        }

        this.setState({ arrayMenuItems: newArrList });

        return;
      } else {
        this.setState({ arrayMenuItems: this.state.arrayWholeMenuItems });
      }
    }
  }

  renderFilter() {
    // console.log('filter view ------ ',this.state.arrayFilterTypes)
    let obj = this.state.arrayFilterTypes.map((data, index) => (
      <a
        onClick={() => {
          this.onFilterClick(data);
        }}
        key={index}
      >
        <li className={data.isSelected == true ? "active" : ""}>
          {/* {data.title} {(data.isSelected == true) ? <img src={data.iconurlDark} /> : <img src={data.iconurl} />} */}
          {data.title}{" "}
          {data.isSelected == true ? (
            <img src={data.iconurl} />
          ) : (
            <img src={data.iconurl} />
          )}
        </li>
      </a>
    ));
    return <ul className="list-btn">{obj}</ul>;
  }

  onSelectCategory(id) {
    this.setState({ catId: id, arrayMenuItems: [], isNo: false });
    // localStorage.setItem(Constants.PRE_CAT_ID,JSON.stringify(id))
    setSecureData(Constants.PRE_CAT_ID, id);
    setTimeout(() => {
      this.getCategoryItem();
    }, 200);
  }

  renderCategoryTitle() {
    if (!this.state.arrayMainCategories) {
      return;
    }
    return this.state.arrayMainCategories.map((category) =>
      this.state.catId == category.catId ? (
        <h1 className="stylMmenuTitle">{category.catName}</h1>
      ) : // <div>

      // <h1 className="stylMmenuTitle">{category.catName}</h1>
      //   <h1 className="newFont">{category.catName}</h1>
      // </div>
      null
    );
  }

  renderCategoryView() {
    let catArray = [];
    this.state.arrayMainCategories.map((category) =>
      catArray.push(
        <li key={category.catId}>
          {this.state.catId == category.catId ? (
            <a
              data-toggle="tab"
              className="active"
              onClick={() => {
                this.onSelectCategory(category.catId);
              }}
            >
              {/* <img src="../images/imgMenu1.jpg" /><p className="active">{category.catName}</p> */}
              <img
                src={
                  category.thumbimgurl
                    ? bindhttps(category.thumbimgurl)
                    : "../images/no.jpg"
                }
              />
              <p className="active">{category.catName}</p>
            </a>
          ) : (
            <a
              data-toggle="tab"
              onClick={() => {
                this.onSelectCategory(category.catId);
              }}
            >
              {/* <img src="../images/imgMenu2.jpg" /><p>{category.catName}</p> */}
              <img
                src={
                  category.thumbimgurl
                    ? bindhttps(category.thumbimgurl)
                    : "../images/no.jpg"
                }
              />
              <p>{category.catName}</p>
            </a>
          )}
        </li>
      )
    );
    return <ul className="nav tabs-left sideways">{catArray}</ul>;
  }

  renderSubCategoryView() {
    let catObjects = [];
    this.state.arraySubCategory.map((catData) => {
      var filt = this.state.arrayMenuItems.filter(
        (menuItem) => menuItem.catId == catData.catId
      );
      catObjects.push({ catTitle: catData.catName, menuItems: filt });
    });
    console.log("cat object ----- ", catObjects);

    var objFinal = [];
    catObjects.map((catData) =>
      objFinal.push(
        <div className="dr1">
          <h2>{catData.catTitle}</h2>
          <div className="row">{this.renderMenuItems(catData.menuItems)}</div>
        </div>
      )
    );
    return objFinal;
  }

  onViewOrderClick() {
    this.setState({ isAlertVisible: false }, () => {
      this.props.history.push({ pathname: "/CheckoutDelivery" });
    });
  }

  renderBanner() {
    if (!this.state.arrayMainCategories) {
      return;
    }
    let myCatData = this.state.arrayMainCategories.filter(
      (data) => data.catId == this.state.catId
    );

    if (myCatData.length > 0) {
      return myCatData[0].bannerfullimgurl ? (
        <img src={bindhttps(myCatData[0].bannerfullimgurl)} className="w-100" />
      ) : (
        <img src="/images/dishes-banner.jpg" className="w-100" />
      );
    }
  }

  /* comment for order type */
  onContinueOrder(order) {
    if (order.length > 0) {
      if (order[0].title == Strings.Delivery_web) {
        Constants.OrderType = Strings.Delivery_web;
      } else if (order[0].title == Strings.lbl_takeaway_react) {
        Constants.OrderType = Strings.lbl_takeaway_react;
      } else if (order[0].title == Strings.str_collect_drink) {
        Constants.OrderType = Strings.str_sitDown;
      }
    }
    setSecureData(Constants.ORDERTYPE, Constants.OrderType);
    // return
    this.setState({ isOrderTypeVisible: false }, () => {
      setTimeout(() => {
        this.callGetCategoryListApi();
      }, 500);
    });
  }

  onSelectTypes(order) {
    this.state.arrayOrderTypes.map((data) => {
      if (data.title == order.title) {
        data.isSelect = true;
        Constants.OrderType = data.title;
      } else {
        data.isSelect = false;
      }
    });
    this.setState({ arrayOrderTypes: this.state.arrayOrderTypes });

    let obj = this.state.arrayOrderTypes.filter(
      (data) => data.isSelect == true
    );

    if (obj.length > 0) {
      this.onContinueOrder(obj);
      // Constants.OrderType = obj[0].title
      // console.log('Constants.OrderType = obj[0].title --------- ', Constants.OrderType)
      // return (
      //     <div className="continue-btn">
      //         <button className="btn btn-primary btn-small order-btn" onClick={() => { this.onContinueOrder(obj) }}>{Strings.btn_continue_react}</button>
      //     </div>
      // )
    }
  }

  renderDeliveryMsg() {
    if (this.state.arrayOrderTypes.length > 0) {
      if (this.state.locationObj.isDelivery) {
        return <p className="infomsg">{Strings.msgDeliverylabel}</p>;
      } else {
        return <p className="infomsg">{Strings.msgSelectlabel}</p>;
      }
    }
  }

  renderOrderType() {
    if (this.state.arrayOrderTypes.length == 0) {
      return <label>{Strings.error_msg_no_order_type_react}</label>;
    }
    var arry = [];
    this.state.arrayOrderTypes.map((data, index) => {
      arry.push(
        <div
          className="optList"
          onClick={() => {
            this.onSelectTypes(data);
          }}
          key={index}
        >
          <h3>{data.title}</h3>
        </div>
      );
    });
    return arry;
  }

  renderButton() {
    let obj = this.state.arrayOrderTypes.filter(
      (data) => data.isSelect == true
    );
    if (obj.length > 0) {
      Constants.OrderType = obj[0].title;
      return (
        <div className="continue-btn">
          <button
            className="btn btn-primary btn-small order-btn"
            onClick={() => {
              this.onContinueOrder(obj);
            }}
          >
            {Strings.btn_continue_react}
          </button>
        </div>
      );
    }
  }
  renderOrderSelectionView() {
    return (
      <Modal
        show={this.state.isOrderTypeVisible}
        onHide={() => {}}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="popupHeader">
            <label className="headerTitle">
              {Strings.lbl_select_order_type_react}
            </label>
          </div>
          <div className="pop loc-popup" id="mypop">
            <div className="pop-dialog">
              <div className="pop-content">
                <div className="pop-body">
                  <div className="prd-s">
                    <div className="prd-cont">
                      {this.renderDeliveryMsg()}
                      {this.renderOrderType()}
                    </div>
                  </div>
                  {this.renderButton()}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    return (
      <>
        <div
          className="white dishes Homepage"
          style={{
            background: `url("images/bg/bg.png") no-repeat`,
            display: "block",
            height: "962px",
            width: "100%",
            backgroundSize: "cover",
          }}
        >
          {/* {this.props.isLoading ? (
            <div className="loadermain" style={{ display: "block" }}>
              <div className="loader"></div>
            </div>
          ) : (
            <div className="loadermain" style={{ display: "none" }}>
              <div className="loader"></div>
            </div>
          )} */}

          <HeaderWhiteMenu
            logoType={1}
            headerLbl={Strings.strHeaderMenu}
            isMenu={true}
            changeLanguageEvent={this.languageChangeClickEvent}
            isCategory={true}
          />

          <div class=" h-orderbx-rw">
            <div class="container">
              <div className="row m-0 text-center">
                <div className="col-md-4 p-0">
                  <div class="h-orderbx-wrp">
                    <div class="h-orderbx-img">
                      <img class="bgcardbdy" src="images/bg/bg-up-1.png" />
                    </div>
                    {/* <div class="bgcardbdy" style={{background: `url("images/bg/bg-up-1.png") no-repeat`,display: 'block',height:'210px', width: 'auto', objectFit: 'cover', backgroundColor: 'black', opacity: '0.9'}} > */}
                    <div class="h-orderbx-cont">
                      <h4 class="head-h2">Order Online</h4>
                      <a href="/categories" className="order-btn">
                        Start Ordering
                      </a>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div className="col-md-4 p-0">
                  <div class="h-orderbx-wrp">
                    <div class="h-orderbx-img">
                      {" "}
                      <img class="bgcardbdy" src="images/bg/bg-up-2.png" />
                    </div>
                    {/* <div class="bgcardbdy" style={{background: `url("images/bg/bg-up-2.png") no-repeat`,display: 'block',height:'210px', width: 'auto', objectFit: 'cover', backgroundColor: 'black', opacity: '0.9'}}  > */}
                    <div class="h-orderbx-cont">
                      <h4 class="head-h2">Offers</h4>
                      <a href="/BestOfferList" className="order-btn">
                        View All Offers
                      </a>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div className="col-md-4 p-0">
                  <div class="h-orderbx-wrp">
                    <div class="h-orderbx-img">
                      {" "}
                      <img class="bgcardbdy" src="images/bg/bg-up-3.png" />
                    </div>
                    {/* <div class="bgcardbdy" style={{background: `url("images/bg/bg-up-3.png") no-repeat`,display: 'block',height:'210px', width: 'auto', objectFit: 'cover', backgroundColor: 'black', opacity: '0.9'}} > */}
                    <div class="h-orderbx-cont">
                      <h4 class="head-h2">Book a Table</h4>
                
                      {
                         ( (getSecureData(Constants.LOCATIONOBJECT) != undefined && getSecureData(Constants.LOCATIONOBJECT).isTableBooking == true ) || this.state.isTableBookingStatus )
                         ?
                          <a href="/BookTable" className="order-btn">
                            Book A Table
                          </a>
                          : 
                          <a href="javascript: void(0)" className="order-btn diableBtn">
                            Now Closed
                          </a>
                      }
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="h-welcom-sec section">
            <div className="container">
              <div className="row">
                <div className="col-md-6 lcol">
                  <div class="h-welimg-col">
                    <img src="images/bg/wel-img.png" alt="" />
                  </div>
                </div>
                <div className="col-md-6 rcol">
                  <div className="h-welcont-col">
                    <div className="wel-head">
                      <h4 class="whead sub-head">Welcome to</h4>
                      <h3 class="whead">Jimmy’s Sports Bar</h3>
                    </div>
                    <p>
                    Jimmy's Sports Bar is a brand new, exciting offering for Northampton. A sports bar with a twist, based in St James. We serve breakfast, Tapas style street food, cocktails and your normal spirits and draughts cider and lagers, which you can enjoy in our American themed bar area or on the only rooftop terrace in Northampton. 
                    </p>
                    <p>
                      {" "}
                      We run a brunch option every Saturday and Sunday with DJs and live bands over the weekends and bank holidays! There’s something for everyone with pool tables, table tennis & over 30 TVs to enjoy your favourite sports!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="section h-img-infocont">
            <div className="container">
              <div className="row">
                <div class="col-sm-6 col-md-3">
                  <div class="h-img-info-wrp">
                    <h3 className="head-h2">Gallery</h3>

                    <img src="images/bg/section3-1-I.png" />
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div onClick={()=>{window.location.href="/Rewards"}} class="h-img-info-wrp">
                    <h3 className="head-h2">
                      Loyalty
                      <br /> Rewards
                    </h3>

                    <img src="images/bg/section3-2-I.png" />
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div onClick={()=>{window.location.href="/ContactUs"}} class="h-img-info-wrp">
                    <h3 className="head-h2">
                      Connect <br /> with Us
                    </h3>

                    <img src="images/bg/section3-3-I.png" />
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="h-img-info-wrp">
                    <h3 className="head-h2">
                      Download <br /> App
                    </h3>

                    <img src="images/bg/section3-4-I.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="section h-map-sec">
            <div className="container">
              <div className="row">
                <div className="homepageMapover">
                  <img src="images/bg/Path-504.png" />
                  <div className="hmapover-wrp">
                    <div class="hmapover-infocont">
                      <div class="hmapover-div">
                        <i class="fas fa-map-marker-alt"></i>
                        {this.state.locationObj.address1},{" "}
                        {this.state.locationObj.countryName}
                      </div>

                      <div class="hmapover-div">
                        <i class="fas fa-mobile-alt"></i>
                        {this.state.locationObj.phone}
                      </div>
                      <div class="hmapover-div">
                        <i class="fas fa-clock"></i>
                        {this.state.locationObj.locationTimeList[0].time}
                      </div>
                    </div>
                    <div class="hmapover-social">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/jimmysportsbar/"
                            target="_blank"
                          >
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/jimmysportsbar/?hl=en"
                            target="_blank"
                          >
                            <i class="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/jimmyssportsba1?lang=en"
                            target="_blank"
                          >
                            <i class="fa fa-twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9772.748941947453!2d-0.9149502!3d52.2399827!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf9150757dc5ed833!2sJimmy&#39;s%20Sports%20Bar!5e0!3m2!1sen!2sin!4v1622031188816!5m2!1sen!2sin"
                  width="1200"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <FooterMenu />
      </>
    );
  }
}

function mapStateToProps(state) {
  console.log(
    "isLoaging Categories MENU Items------- " + state.HGReducers.isLoading
  );
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  locationListApi: (languageType) =>
    dispatch(HGActions.GetLocationListApi(languageType)),
  // getCategoriesAPICall: (languageType) => dispatch(HGActions.initGetAllCategoriesCall(languageType)),
  // checkItemTimeAPI: (contactTime,orderDate,catId,languageType) => dispatch(HGActions.initCheckMenuItemTimeAPI(contactTime,orderDate,catId,languageType)),
  // getCategoriesMenuItemsAPICall: (categories, languageType) => dispatch(HGActions.initGetCategoriesMenuItemsCall(categories, languageType)),
  AppMessageApi: (languageType) =>
    dispatch(HGActions.GetAppMessageListApi(languageType)),
  callCheckVersion: (customerId, languageType) =>
    dispatch(HGActions.callApiCheckVersionAPI(customerId, languageType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
