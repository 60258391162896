export default
    {

        mainURL : 'https://www.jimmyssportsbar.co.uk/',
        /*
        needs to change bindhttps method to 
        replace https from http for live of utils.js class 
        */
        iconURL: 'https://www.jimmyssportsbar.co.uk/',
        baseURL: 'https://www.jimmyssportsbar.co.uk/RestaurantAPIOnlineV1.asmx',//LIVE

        currencyIcon : '€',
        locationId: 0,
        OrderType: 'Delivery',
        
        // Setting Default Value
        USEROBJECT : 'userObject',
        USERCART: 'UserCart',
        CATEGORIESDATA: 'CategoryData',
        LOCATIONOBJECT: 'locationObject',
        LOCATIONLISTOBJECT: 'locationListObject',
        ORDERTYPE: 'OrderType',
        ORDERTYPEID: 'ORDERTYPEID',
        CHECKINFO: 'CheckInfo',
        PRE_CARD_LIST: 'CardList',
        PRE_WAITER_ITEM:'WaiterItem',
        PRE_CAT_ID: 'PreCatID',
        PLACEDORDEROBJECT: 'ord',
        MEMBEROFFERS : 'memberOffers',

        //BTN TITLE
        strYes: 'YES',
        strNo: 'NO',
        strOk: 'OK',

        //MSG
        msgEraseItem: 'Do you want to delete item?',
        msgErasedItem: 'Item erased successfully',
        msgItemAdded: 'Item added successfully',
        msgItemUpdated: 'Item updated successfully',

        PRE_MESSAGE_LIST: 'MessageList',
        PRE_LANGUAGE_TYPE:'LanguageType',
        // PRE_LANGUAGE_CODE:'LanguageTypeCode',

        ISCANCEL: 'isCancel',

        ENCRYPTKEY: 'HgW@bEncK3y',

    }