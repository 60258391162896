import React, { Component } from "react";
import {
  Route,
  NavLink,
  BrowserRouter as Router,
  Switch,
  Link,
  history,
  useLocation,
} from "react-router-dom";
import FooterMenu from "../Components/FooterMenu";
import { connect } from "react-redux";
import * as HGActions from "../Redux/actions/HGActions";
import Constant from "../constants/baseConstants";
import { Strings } from "../constants/Localization";
import HeaderWhiteMenuHalf from "../Components/HeaderWhiteMenuHalf";
import baseConstants from "../constants/baseConstants";
import AOS from "aos";
import "aos/dist/aos.css";
import { getPriceFormat } from "../Utils/utils";
import { setSecureData, getSecureData } from "../Utils/utils";
import { Dropdown } from "react-bootstrap";
import queryString from "query-string";

let customerId = 0;
class MyOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      orderArray: [],
      errorMessage: "",
      languageType: 1, //default language type

      orderStatusType: 0, //1 = pending 2 = others
      filterArray: [],
    };

    if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
      // get value from localStorage
      // let userObject = localStorage.getItem(Constant.USEROBJECT);
      let userObject = getSecureData(Constant.USEROBJECT);
      if (userObject !== null) {
        // userObject = JSON.parse(userObject);
        if (userObject != null) {
          customerId = userObject.customerId;
          console.log("login user :" + JSON.stringify(userObject));
        }
      }
    }
  }

  componentDidMount() {
    //Init animation duration
    AOS.init({
      duration: 1000,
    });

    let params = queryString.parse(this.props.location.search);
    if (params.isRemove) {
      console.log("query string -------------> ", params);
      localStorage.removeItem(Constant.USERCART);
      localStorage.removeItem(Constant.PLACEDORDEROBJECT);
      localStorage.removeItem(Constant.ISCANCEL);
    }

    // if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
    //     // let userObject = localStorage.getItem(Constants.USEROBJECT);
    //     let userObject = getSecureData(Constant.USEROBJECT);
    //     if (userObject == null) {
    //         this.props.history.push({ pathname: '/' })
    //         return
    //     }
    // }
    // else {
    //     this.props.history.push({ pathname: '/' })
    //     return
    // }
    //Get messagelist from localstorage
    // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
    // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
    let session = getSecureData(Constant.PRE_MESSAGE_LIST);
    let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
    console.log("default lanague type  :" + mLanguageType);
    if (session != null && session.length > 0) {
      // session = JSON.parse(session);
      if (mLanguageType != null && mLanguageType == 1) {
        Strings.setLanguage("en"); //Setting selected language code here
        this.setState({ languageType: mLanguageType });
      } else {
        if (mLanguageType != null) {
          Strings.setLanguage("es"); //Setting selected language code here
          this.setState({ languageType: mLanguageType });
        }
      }
    } else {
      Strings.setLanguage("en"); //Setting default language code here
    }

    setTimeout(() => {
      console.log("call order list api :");
      this.props.orderListApi(customerId, this.state.languageType).then(() => {
        if (this.props.data.status == 1) {
          var orderrList = this.props.data.result;
          if (orderrList != null && orderrList.length > 0) {
            orderrList.sort((a, b) => a.orderdate - b.orderdate).reverse();
            this.setState({ orderArray: orderrList });
          } else {
            this.setState({
              orderArray: [],
              errorMessage: Strings.lbl_no_order_react,
            });
          }
        } else {
          this.setState({ errorMessage: this.props.error });
        }
      });
    }, 500);
  }

  //Handle change language click event
  //English language type: 1
  //Spanish language type: 2
  languageChangeClickEvent = (type) => {
    console.log("my order page language type :" + type);
    // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
    setSecureData(Constant.PRE_LANGUAGE_TYPE, type);
    this.setState({ languageType: type });
    setTimeout(() => {
      this.props.AppMessageApi(this.state.languageType).then(() => {
        if (this.props.data.status == 1) {
          var messageListArray = this.props.data.result;
          if (messageListArray != null && messageListArray.length > 0) {
            console.log("message list  :" + JSON.stringify(messageListArray));
            // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
            setSecureData(Constant.PRE_MESSAGE_LIST, messageListArray);
            // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
            let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
            // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
            setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType);
            // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
            let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
            if (languageType == 1) {
              //English language type: 1
              Strings.setLanguage("en"); //Setting selected language code here
              this.setState({
                languageType: languageType,
                languageTypeCode: "en-GB",
              });
            } else {
              Strings.setLanguage("es"); //Setting selected language code here
              this.setState({
                languageType: languageType,
                languageTypeCode: "es",
              });
            }
            console.log("language type  :" + languageType);
            Strings.setContent(localMessage);
            // this.setState({ languageType: languageType });
            window.location.reload(false);
          }
        } else {
          alert(this.props.error);
        }
      });
    }, 700);
  };

  onfilter(e) {
    //     public enum OrderStatus
    // {
    //     Completed = 1,
    //     Pending = 2,
    //     Cancel = 3,
    //     Dispached = 4,
    //     InProcess = 5,
    //     OrderReceived = 6
    // }
    console.log("selected type======== ", e);
    this.setState({ orderStatusType: e }, () => {
      if (e == "1") {
        let obj = this.state.orderArray.filter(
          (obj) => obj.orderstatusValue != 1
        );
        console.log("filter === ", obj);
        this.setState({ filterArray: obj });
      } else if (e == "2") {
        let obj = this.state.orderArray.filter(
          (obj) => obj.orderstatusValue == 1
        );
        console.log("filter else=== ", obj);
        this.setState({ filterArray: obj });
      }
    });
  }

  render() {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="form-control fltr"
      >
        {children}
        &#x25bc;
      </a>
    ));

    // forwardRef again here!
    // Dropdown needs access to the DOM of the Menu to measure it
    const CustomMenu = React.forwardRef(
      ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        // const [value, setValue] = useState('');
        const value = "";
        return (
          <div
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
          >
            {/* <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => alert(e.target.value)}
                    value={value}
                /> */}
            <ul>
              {React.Children.toArray(children).filter(
                (child) =>
                  !value || child.props.children.toLowerCase().startsWith(value)
              )}
            </ul>
          </div>
        );
      }
    );

    return (
      <div className="white dishes">
        {this.props.isLoading ? (
          <div class="loadermain" style={{ display: "block" }}>
            <div class="loader"></div>
          </div>
        ) : (
          <div class="loadermain" style={{ display: "none" }}>
            <div class="loader"></div>
          </div>
        )}

        <HeaderWhiteMenuHalf
          isHideOrderType={true}
          headerLbl={"View All Orders"}
          changeLanguageEvent={this.languageChangeClickEvent}
          isCategory={true}
        />

        <div className="content_wrap orderList">
          <div className="container">
            <div className="row" style={{ float: "right" }}>
              <Dropdown
                onSelect={(e) => this.onfilter(e)}
                style={{ alignSelf: "center" }}
              >
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="dropdown-custom-components"
                >
                  {this.state.orderStatusType == 0
                    ? "Select type "
                    : this.state.orderStatusType == 1
                    ? Strings.str_pending
                    : Strings.str_complete}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  as={CustomMenu}
                  style={{ height: "90px", overflow: "scroll" }}
                >
                  <Dropdown.Item eventKey="0">All</Dropdown.Item>
                  <Dropdown.Item eventKey="1">
                    {Strings.str_pending}
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="2">
                    {Strings.str_complete}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="all-order">
              <div className="row">
                <div className="col-md-12  col-lg-12">
                  {/* <h1>{Strings.lbl_view_order_react}</h1> */}

                  <table className="table">
                    <thead>
                      <tr>
                        <th className="header-center">
                          {Strings.lbl_order_react}
                        </th>
                        <th className="header-center">
                          {Strings.lbl_date_react}
                        </th>
                        {/* <th class="header-left">{Strings.lbl_location_react}</th> */}
                        <th className="header-center">
                          {Strings.lbl_location_react}
                        </th>
                        <th className="header-center">
                          {Strings.lbl_order_status_react}
                        </th>
                        <th className="header-center">
                          {Strings.lbl_order_type_react}
                        </th>
                        {/* <th class="header-right">{Strings.lbl_price_react}</th> */}
                        <th className="header-center">
                          {Strings.lbl_price_react}
                        </th>
                        {/* <th class="header-center">
                                                    
                                                </th> */}
                      </tr>
                    </thead>
                    {this.setOrderListView()}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterMenu />
      </div>
    );
  }

  //Setting user order listing
  setOrderListView() {
    if (this.state.orderArray != null && this.state.orderArray.length > 0) {
      {
        if (this.state.orderStatusType == 0) {
          return this.state.orderArray.map((item, index) => (
            <tbody key={index}>
              <tr>
                <td class="row-center" data-column="ORDER #">
                  {item.orderNo}
                </td>
                <td class="row-center" data-column="DATE">
                  {item.contactDatetime}
                </td>
                {/* <td class="row-left" data-column="LOCATION">{item.locationName}</td> */}
                <td class="row-center" data-column="LOCATION">
                  {item.locationName}
                </td>
                <td class="row-center" data-column="ORDER STATUS">
                  {item.orderstatus == "OrderReceived"
                    ? "Order Received"
                    : item.orderstatus}
                </td>
                <td class="row-center" data-column="ORDER TYPE">
                  {item.ordertype}
                </td>
                {/* <td class="row-right" data-column="PRICE">{getPriceFormat(item.totalamount)}</td> */}
                <td class="row-center" data-column="PRICE">
                  {getPriceFormat(item.totalamount)}
                </td>

                <td class="row-center">
                  <button
                    onClick={() => {
                      // localStorage.setItem(baseConstants.PRE_ORDER_ID,JSON.stringify(item.orderId))
                      setSecureData(baseConstants.PRE_ORDER_ID, item.orderId);
                      this.props.history.push({ pathname: "/OrderDetails" });
                    }}
                    className="btn btn-primary btn-small order-btn"
                  >
                    {Strings.btn_view_order_react}
                  </button>
                  {/* <button className="btn btn-primary btn-small order-btn">{Strings.support_web}</button> */}
                </td>
              </tr>
            </tbody>
          ));
        } else {
          return this.state.filterArray.map((item, index) => (
            <tbody key={index}>
              <tr>
                <td class="row-center" data-column="ORDER #">
                  {item.orderNo}
                </td>
                <td class="row-center" data-column="DATE">
                  {item.contactDatetime}
                </td>
                {/* <td class="row-left" data-column="LOCATION">{item.locationName}</td> */}
                <td class="row-center" data-column="LOCATION">
                  {item.locationName}
                </td>
                <td class="row-center" data-column="ORDER STATUS">
                  {item.orderstatus == "OrderReceived"
                    ? "Order Received"
                    : item.orderstatus}
                </td>
                <td class="row-center" data-column="ORDER TYPE">
                  {item.ordertype}
                </td>
                {/* <td class="row-right" data-column="PRICE">{getPriceFormat(item.totalamount)}</td> */}
                <td class="row-center" data-column="PRICE">
                  {getPriceFormat(item.totalamount)}
                </td>

                <td class="row-center">
                  <button
                    onClick={() => {
                      // localStorage.setItem(baseConstants.PRE_ORDER_ID,JSON.stringify(item.orderId))
                      setSecureData(baseConstants.PRE_ORDER_ID, item.orderId);
                      this.props.history.push({ pathname: "/OrderDetails" });
                    }}
                    className="btn btn-primary btn-small order-btn"
                  >
                    {Strings.btn_view_order_react}
                  </button>
                  {/* <button className="btn btn-primary btn-small order-btn">{Strings.support_web}</button> */}
                </td>
              </tr>
            </tbody>
          ));
        }
      }
    } else {
      return (
        <tr style={{ textAlign: "center" }} class="error-no-record">
          <td colspan="5">
            <h3>
              {this.state.errorMessage == "NorecordavailableForOrder"
                ? "No Record Available"
                : this.state.errorMessage}
            </h3>
          </td>
        </tr>
      );
    }
  }
}

function mapStateToProps(state) {
  console.log("isLoaging Order ------- " + state.HGReducers.isLoading);
  return {
    isLoading: state.HGReducers.isLoading,
    data: state.HGReducers.data,
    error: state.HGReducers.error,
  };
}
const mapDispatchToProps = (dispatch) => ({
  orderListApi: (customerId, languageType) =>
    dispatch(HGActions.GetOrderListApi(customerId, languageType)),
  AppMessageApi: (languageType) =>
    dispatch(HGActions.GetAppMessageListApi(languageType)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);
