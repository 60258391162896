import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import { Strings } from '../constants/Localization';
import FooterMenu from '../Components/FooterMenu';
import Constant from '../constants/baseConstants';
import baseConstants from '../constants/baseConstants';
import HeaderWhiteMenu from '../Components/HeaderWhiteMenu';
import {setSecureData, getSecureData} from '../Utils/utils';

import AOS from 'aos';
import 'aos/dist/aos.css';


class WaiterListing extends Component {

    constructor(props) {
        super(props)

        this.state = {
            waiterArray: [],
            errorMessage: '',
            languageType: 1, //default language type

        }
    }

    componentDidMount() {
        //Init animation duration
        AOS.init({
            duration: 1000
        })

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // let userObject = localStorage.getItem(Constants.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
        }
        else {
            this.props.history.push({ pathname: '/' })
            return
        }

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                this.setState({ languageType: mLanguageType })
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    this.setState({ languageType: mLanguageType })
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        setTimeout(() => {
            this.fetchWaiterList()
        }, 500);
    }

    fetchWaiterList() {
        console.log("call waiter list api :")
        this.props.callWaiterListApi(this.state.languageType).then((response) => {

            console.log('waiter response --------- ',this.props.data,response)

            if (this.props.data.status == 1) {
                var waiterList = this.props.data.result;
                if (waiterList != null && waiterList.length > 0) {
                    this.setState({ waiterArray: waiterList })
                }
                else {
                    this.setState({ waiterArray: [], errorMessage: Strings.lbl_no_waiter_react })
                }
            }
            else {
                this.setState({ waiterArray: [], errorMessage: this.props.data.message })
            }
        })
        .catch((err) => {
            console.log('errro ', err)
        })
    }

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my order page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST, messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        console.log('language type  :' + languageType);
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType });
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    render() {
        return (
            <div>
                {this.props.isLoading ? <div class="loadermain" style={{ display: 'block' }}>
                    <div class="loader" ></div>
                </div> : <div class="loadermain" style={{ display: 'none' }}>
                        <div class="loader" ></div>
                    </div>}

                <HeaderWhiteMenu
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true} onChangeLocation={()=>{this.fetchWaiterList()}}/>

                <div class="content_wrap">
                    <div class="dishes-page">
                        <div class="row">
                            <div class="col-md-12 col-md-12">
                                <h1 class="watb-title">{Strings.lbl_waiter_title_react}</h1>
                                {/* <!-- Tab panes --> */}
                                <div class="tab-content watle-tab" >
                                    <div class="tab-pane active" id="home-v">
                                        <div class="row watle-main">
                                            {
                                                this.setWaiterListView()
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <FooterMenu />
            </div>
        )
    }


    //This method use bind waiter list
    setWaiterListView() {
        if (this.state.waiterArray != null && this.state.waiterArray.length > 0) {
            {
                return (
                    this.state.waiterArray.map((item, index) => (
                        <div onClick={() => {
                            // localStorage.setItem(baseConstants.PRE_WAITER_ITEM, JSON.stringify(item))
                            setSecureData(baseConstants.PRE_WAITER_ITEM, item)
                            this.props.history.push({ pathname: '/WaiterDetails' })
                        }}
                            class="col-lg-3 col-md-3 waiter-single all-product" key={index}>
                            <div class="prd-s" data-aos='zoom-in'>
                                <div class="prd-img"><a href="">
                                    <img src={item.thumbimgurl != null && item.thumbimgurl.length > 0
                                        ? item.thumbimgurl : null} /></a></div>

                                <div class="prd-cont">
                                    <h3>{item.waiterName != null && item.waiterName.length > 0 ? item.waiterName : null}</h3>
                                    {/* <p>{item.averageRating > 0 ? item.averageRating + " " + "/" + "5.0" : null}</p> */}
                                </div>
                            </div>
                        </div>
                    ))
                )
            }
        }
        else {
            return (
                <div style={{ textAlign: 'center' }} class="col-12">
                    <h3>{this.state.errorMessage}</h3>
                </div>
            )
        }
    }
}
function mapStateToProps(state) {
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}
const mapDispatchToProps = dispatch => ({
    callWaiterListApi: (languageType) => dispatch(HGActions.callGetWaiterListApi(languageType)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
})
export default connect(mapStateToProps, mapDispatchToProps)(WaiterListing);

