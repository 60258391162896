import React, { Component } from "react";

import {withRouter} from 'react-router-dom';

import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';

import Constants from '../constants/baseConstants';
import {Strings} from '../constants/Localization';
import Modal from 'react-bootstrap/Modal'
import {getSecureData} from '../Utils/utils';


class LinkCompany extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
        arrayAddress: this.props.addressList.AddressLists,
        selectedIndex: 0,
        languageType: 1,
    }

  }

  componentDidMount() {
      //Get messagelist from localstorage
        // let session = localStorage.getItem(Constants.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constants.PRE_LANGUAGE_TYPE);
        let session = getSecureData(Constants.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constants.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                this.setState({ languageType: mLanguageType })
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    this.setState({ languageType: mLanguageType })
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }
  }

  
  onSelect(i) {
      this.setState({selectedIndex:i})
  }

  renderAddressList() {
      let arr = []
      if(!this.state.arrayAddress) {
        arr.push( 
          <label className="noData">{Strings.no_record_address}</label>
        )
        return arr
      }
      this.state.arrayAddress.map((address,index) => {
          arr.push(
              // <div>
              //   <input type='radio' id={index} name='rd' onClick={()=>this.onSelect(index)}/>
              //   <div>
              //       <label>{address.address1}</label><br/>
              //       {address.address2 ? <label>Comment: {address.address2}</label> : null }
              //       <button onClick={()=>this.onDeleteAddress(index)}>Delete</button>
              //   </div>
              // </div>
              <li key={index}>
                  <button className="checked-btn" onClick={()=>this.onSelect(index)}><img src={this.state.selectedIndex == index ? '/images/checked.png' : '/images/uncheck.png'}/></button><label className="container1">{address.address1}{address.address2 ? <span><br/>Comment: {address.address2}</span> : null }</label>
                  <a href="#" className="bin" onClick={()=>{}}><img src="/images/delete.png"/></a>
              </li>
          )
      })
      return arr
  }

  onSelectAddressClick() {
    if (!this.state.arrayAddress) {
      return
    }
    
    this.props.checkDeliveryAddressID(this.state.languageType,this.state.arrayAddress[this.state.selectedIndex].deliveryaddressId).then(() => {
      if (this.props.data.status == 1) {
          setTimeout(() => {
            if (this.props.data.result.delivery) {
              //valid address
              this.props.onHideAddressView(this.state.arrayAddress[this.state.selectedIndex])
            }
          }, 100)
      } else {
        alert(this.props.data.message)
      }
    })
  }

  onAddNewAddress() {
    this.props.onAddNewAddress()
  }

  render() {
    return (
        
    <Modal
        show={this.props.isShow}
        onHide={()=>{this.props.onHideAddressView()}}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
      <Modal.Body>
        {/* <div>
            <label>Select Address</label>
            {this.renderAddressList()}
            <button onClick={()=>{this.onSelectAddressClick()}}>Select Address</button>
            <button onClick={()=>{this.onAddNewAddress()}}>Add New Address</button>
        </div> */}
        <div className="address-popup">                   
                    <h3>{Strings.select_address}</h3>

                    <div className="address plate-div">
                        
                        <ul>
                        {this.renderAddressList()}
                      </ul> 
                    </div>

                    <div className="price-btns">
                      <a href="#" className="btn btn-primary btn-small" onClick={()=>{this.onAddNewAddress()}}>{Strings.str_add_new_address}s</a>
                      <a href="#" className="btn btn-primary btn-small" onClick={()=>{this.onSelectAddressClick()}}>{Strings.select_address}</a>
                    </div>

                  </div>
      </Modal.Body>
    </Modal>
    )
  }

}

function mapStateToProps(state) {
    console.log('isLoaging Address Popup ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    deleteAddressAPI: (addressId) => dispatch(HGActions.initDeleteDeliveryAddressAPI(addressId)),
    checkDeliveryAddressID: (languageType,deliveryaddressId) => dispatch(HGActions.initCheckDeliveryAddressAPI(languageType,deliveryaddressId))
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(LinkCompany);