import React, { Component } from 'react';
import { Strings } from '../constants/Localization';
import CookieConsent, { Cookies } from "react-cookie-consent";
import Constant from '../constants/baseConstants';
import { setSecureData, getSecureData } from '../Utils/utils';

export default class FooterMenu extends Component {

    render() {
        return (
            <footer>
                <div class="static-possition-dl">
                        <ul>
                            <li>
                                <img className="appIconImg" src="images/bg/app.png" onClick={()=>{window.open('https://apps.apple.com/gb/app/jimmys-sports-bar/id1573629887','_blank');}} />
                            </li>

                                <li>
                                <img className="appIconImg"  src="images/bg/gpay.png" onClick={()=>{window.open('https://play.google.com/store/apps/details?id=com.jimmybar','_blank');}} />
                            </li>
                            
                        </ul>
                </div>
                <CookieConsent
                location="bottom"
                buttonText="Accept All Cookies"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#360024" }}
                buttonStyle={{ background:'#3abda6' ,color: "#000", fontSize: "14px" }}
                expires={150}
                >
                This website uses cookies to enhance the user experience.
                </CookieConsent>
                <div className="book-ftr w100 mt-5">
                    <div className="container container_footer">
                        <div className="row" >
                            <div className="col-md-8 footerLeftSide">
                                <div className="footerMenu" >
                                    <a href="/" >Home</a>
                                    <a href="/AboutUs" >About US</a>
                                    <a href="/menuItems" >Menu</a>
                                    {
                                        getSecureData(Constant.LOCATIONOBJECT) != undefined && getSecureData(Constant.LOCATIONOBJECT).isTableBooking == true
                                        ?
                                        <a href="/BookTable" >Book A Table</a>
                                        : 
                                        null
                                    }
                                    
                                    <a href="/ContactUs" >Contact</a>
                                </div>
                                <div className="footerAddress" >
                                    {/* <a href="javascript:void(0)" >&copy;Jimmy's Sports Bar LTD 2021 | 6 Harlestone Rd, Northampton NN5 7AE | Company Reg: 11729977</a> */}
                                    <a href="javascript: void(0)" >&copy;2021 Jimmy's Sports Bar LTD - </a>
                                     <a href="http://www.smart-restaurants.co.uk" title="Bar and Restaurant Website Design and Development"> Bar Website Design & </a>
                                    <a href="http://www.smart-restaurants.co.uk" title="Bar and Restaurant App Development">Bar App Development </a> 
                                    <a href="javascript: void(0)" > by Smart Restaurants</a>
                                </div>

                                <div className="footerTCP" >
                                    <a href="/TermsConditions" >Terms & Conditions</a>
                                    <a href="/PrivacyPolicy" >Privacy Policy</a>
                                    {/* <a href="http://www.smart-restaurants.co.uk" >Website Design & App Development by Smart Restaurants</a> */}
                                    
                                </div>
                                
                            </div>
                            
                            <div className="col-md-4">
                                <div class="hmapover-social FooterSocialICon">
                                    <ul>
                                        <li>
                                        <a
                                            href="https://www.facebook.com/jimmysportsbar/"
                                            target="_blank"
                                        >
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                        </li>
                                        <li>
                                        <a
                                            href="https://www.instagram.com/jimmysportsbar/?hl=en"
                                            target="_blank"
                                        >
                                            <i class="fab fa-instagram"></i>
                                        </a>
                                        </li>
                                        <li>
                                        <a
                                            href="https://twitter.com/jimmyssportsba1?lang=en"
                                            target="_blank"
                                        >
                                            <i class="fa fa-twitter"></i>
                                        </a>
                                        </li>
                                    </ul>
                                    </div>
                            </div>
                        </div>
                        {/* <div className="row" >
                            <div className="col">
                                <div className="footersmart">
                                    <a href="http://www.smart-restaurants.co.uk" title="Bar and Restaurant Website Design and Development">Bar Website Design</a>
                                    <a href="javascript: void(0)" >  |  </a>
                                    <a href="http://www.smart-restaurants.co.uk" title="Bar and Restaurant App Development">Bar App Development </a>
                                </div>
                            </div>
                            
                        </div> */}
                    </div>

                </div>
            </footer>
        )
    }
}



