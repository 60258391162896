import React, { Component } from 'react';
import { history, withRouter, Link } from "react-router-dom";
import Constant from '../constants/baseConstants';
import Constants from '../constants/baseConstants';
import { Strings } from '../constants/Localization';
import AlertDialog from '../Components/AlertDialog';
import { setSecureData, getSecureData } from '../Utils/utils';

var ordType = ''

class HeaderWhiteMenuHalf extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loggedInUserId: 0,
            isAlertVisible: false,
            alertMessage: '',
            alertType: 1,
            selectedLocationObject: {},
            lType: 1,
            menuitemcallStatus : false,
            orderImg : getSecureData(Constant.ORDERTYPE) == 'Delivery Order' ? 'deliveryIcon.png' : (getSecureData(Constant.ORDERTYPE) == 'TakeAway Order' ? 'takeAwayIcon.png' : 'tableOrderIcon.png')
        }
    }

    componentDidMount() {
        // localStorage.getItem(Constant.USEROBJECT)
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let value = localStorage.getItem(Constant.USEROBJECT);
            let value = getSecureData(Constant.USEROBJECT);
            // console.log('value='+value);
            if (value !== undefined) {
                if (value !== null)// || value !== undefined) 
                    this.setState({ loggedInUserId: value.customerId });
            }
        }
        else {
            // console.log('value=');
        }

        // if (localStorage.getItem(Constant.PRE_LANGUAGE_TYPE) == '1') {
        if (getSecureData(Constant.PRE_LANGUAGE_TYPE) == '1') {
            this.setState({ lType: 1 })
            // } else if (localStorage.getItem(Constant.PRE_LANGUAGE_TYPE) == '2') {
        } else if (getSecureData(Constant.PRE_LANGUAGE_TYPE) == '2') {
            this.setState({ lType: 2 })
        }

    }

    // UNSAFE_componentWillUpdate() {
    //     // if (getSecureData(Constant.PRE_LANGUAGE_TYPE) == '1') {
    //     //     this.state.lType = 1
    //     // } else if (getSecureData(Constant.PRE_LANGUAGE_TYPE) == '2') {
    //     //     this.state.lType = 2
    //     // }
    // }

    renderBadge() {

        if (localStorage.hasOwnProperty(Constant.USERCART)) {
            // let value = localStorage.getItem(Constant.USERCART);
            let value = getSecureData(Constant.USERCART);
            if (value !== undefined) {
                if (value !== null) {
                    if (value.length > 0) {
                        return (
                            <span>{value.length}</span>
                        )
                    }
                }
            }
        }

    }

    renderAccountMenu() {
        
        var cId = 0

        console.log("localStorage.hasOwnProperty(Constant.USEROBJECT)",localStorage.hasOwnProperty(Constant.USEROBJECT))
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let value = localStorage.getItem(Constant.USEROBJECT);
            let value = getSecureData(Constant.USEROBJECT);
            console.log('value='+value);
            if (value !== undefined) {
                if (value !== null)// || value !== undefined) 
                    cId = value.customerId
            }
        }

        

        if (cId > 0) {
            return (
                
                <li>
                    <a href="javascript: void(0)" className="user">{Strings.lbl_my_account_react}</a>
                    <ul>
                        <li ><a href='/MyAccount' >{Strings.EditAccount_Web}</a></li>
                        <li><a href="/MyOrders" >{Strings.lbl_title_my_order_react}</a></li>
                        {/* <li><a href="/BookTableList" >Table Booking</a></li> */}
                        {/* <li><a href="/VirtualQueue" >Virtual Queue</a></li> */}
                        <li><a href="/Rewards" >Rewards</a></li>
                        <li><a href="/MyFavorites" >My Favorites</a></li>
                        {/* <li><a href="" onClick={() => { this.props.history.push('/CardListing') }}>{Strings.btn_save_card}</a></li> */}
                        {/* {(locationObject) ? <li><a href="" onClick={() => { this.props.history.push('/WaiterListing') }}>{Strings.rate_a_waiter}</a></li> : null} */}
                        <li><a href="/UserLogout">{Strings.lbl_title_logout_react}</a></li>
                    </ul>
                </li>
                 
            )
        } else {
            return (
                <li><a href="/SignIn" className="user" >{Strings.btn_signin_react.toLocaleUpperCase()}</a></li>
            )
        }
    }

    renderBookTableMenu() {
        
        var cId = 0

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let value = localStorage.getItem(Constant.USEROBJECT);
            let value = getSecureData(Constant.USEROBJECT);
            // console.log('value='+value);
            if (value !== undefined) {
                if (value !== null)// || value !== undefined) 
                    cId = value.customerId
            }
        }

        console.log("localStorage.hasOwnProperty(Constant.USEROBJECT)",localStorage.hasOwnProperty(Constant.USEROBJECT))

        if (cId > 0) {
            return (
                <li class="menu-btn">
                    <a href="/BookTable" className="orderOnlineBtn book-table" >Book a Table</a>
                </li>
            )
        } else {
            return (
                <li class="menu-btn">
                    <a href="/BookTable" className="orderOnlineBtn book-table" >Book a Table</a>
                </li>
            )
        }
    }

    renderOrderType() {
        if (getSecureData(Constant.LOCATIONOBJECT)) {
            if (getSecureData(Constant.ORDERTYPE)) {
                let locationObject = getSecureData(Constant.LOCATIONOBJECT)
                var arry = []
                if (locationObject.isDelivery) {
                    arry.push({ title: Strings.Delivery_web, isSelect: false, img:'delivery.png' })
                }
                if (locationObject.isTakeAway) {
                    arry.push({ title: Strings.lbl_takeaway_react, isSelect: false, img:'takeaway.png' })
                }
                if (locationObject.isOrderExtras) {
                    arry.push({ title: Strings.str_collect_drink, isSelect: false, img:'orderextra.png' })
                } 
                var arrReturn = []
                arry.map((order, index)=>{
                    arrReturn.push(
                        <li key={index}>
                            <div className="orderImg" >
                                <img src={`./images/${order.img}`} />
                            </div>
                            <a href="javascript: void(0)" onClick={() => {this.onChangeOrderType(order)}}>
                                {order.title}
                            </a>
                        </li>
                    )
                })
                return (
                    <li className="ulFororderType" >
                        <a href="javascript: void(0)" >
                                
                                {
                                
                                window.screen.width > 768
                                ?
                                <img className="orderImgIcon" src={"./images/"+this.state.orderImg} /> 
                                :
                                
                                    getSecureData(Constant.ORDERTYPE) == 'Delivery Order' ? Strings.Delivery_web : (getSecureData(Constant.ORDERTYPE) == 'TakeAway Order' ? Strings.lbl_takeaway_react : Strings.str_collect_drink)
                                }
                            </a>
                        <ul>
                            {arrReturn.reverse()}
                        </ul>
                    </li>
                )
            }
        }
    }

    renderCartBadge = () => {
        if (this.state.loggedInUserId > 0) {
            return (
                <div className="hg_cart" ><a href="/CheckoutDelivery" ></a>{this.renderBadge()}</div>
            )
        }
    }
    renderCartMenu = () => {
        if (this.state.loggedInUserId > 0) {
            return (
                <li className="mobileMenu"><a href="/CheckoutDelivery" >{Strings.lbl_title_cart_react}</a></li>
            )
        }
    }

    // English language type: 1
    // Spanish language type: 2
    //Handle select language click event
    changeLanguageClickEvent(type) {
        console.log("change language event header white menu : " + type);
        this.props.changeLanguageEvent(type)
    }
    renderLogo(logoType) {
        if (logoType === 1)
            return (<div className="hg_logo"><a href="/"><img src='images/logo-white.png' alt="" /></a></div>);
        else
            // return (<div className="hg_logo"><a href="/"><img src='images/logo.png' alt="" /></a></div>); ccc
            return (<div className="hg_logo"><a href="/"><img src='images/logo-white.png' alt="" /></a></div>);
    }

    onChangeLocation(location) {
        // if (JSON.parse(localStorage.getItem(Constant.USERCART))) {
        if (getSecureData(Constant.USERCART)) {
            // if (JSON.parse(localStorage.getItem(Constant.USERCART)).length > 0) {
            if (getSecureData(Constant.USERCART).length > 0) {
                this.setState({ isAlertVisible: true, alertType: 1, alertMessage: Strings.empty_cart_message, selectedLocationObject: location })
            } else {
                // localStorage.setItem(Constant.LOCATIONOBJECT,JSON.stringify(location))
                setSecureData(Constant.LOCATIONOBJECT, location)
                setTimeout(() => {
                    this.setState({}, () => {
                        this.props.onChangeLocation()
                    })
                }, 200);
            }
        } else {
            // localStorage.setItem(Constant.LOCATIONOBJECT,JSON.stringify(location))
            setSecureData(Constant.LOCATIONOBJECT, location)
            setTimeout(() => {
                this.setState({}, () => {
                    // this.props.onChangeLocation()
                })
            }, 200);
        }
    }

    onChangeOrderType = (order) => {
        console.log("OrderData",order)
        if (!getSecureData(Constant.USERCART)) {
            /* comment for order type */
            // ordType = (order.title == Strings.Delivery_web) ? 'Delivery' : 'TakeAway'

            console.log("OrderData1",order)
            if(order.title == Strings.Delivery_web) {
                ordType = Strings.Delivery_web
                Constants.OrderTypeID = 2
                this.setState({orderImg : 'deliveryIcon.png'})
            } else if(order.title == Strings.lbl_takeaway_react) {
                ordType = Strings.lbl_takeaway_react
                Constants.OrderTypeID = 1
                this.setState({orderImg : 'takeAwayIcon.png'})
            } else if(order.title == Strings.str_collect_drink) {
                ordType = Strings.str_sitDown
                Constants.OrderTypeID = 3
                this.setState({orderImg : 'tableOrderIcon.png'})
            }
            
            setSecureData(Constant.ORDERTYPE, ordType)
            setSecureData(Constants.ORDERTYPEID, Constants.OrderTypeID)
            setTimeout(() => {
                this.setState({})
                this.props.callmenuitems()
            }, 200);
            return
        }

        if (getSecureData(Constant.USERCART).length > 0) {
            console.log("OrderData2",order)
            /* comment for order type */
            // let ord = (order.title == Strings.Delivery_web) ? Strings.Delivery_web : Strings.lbl_takeaway_react
            // ordType = (order.title == Strings.Delivery_web) ? 'Delivery' : 'TakeAway'
            let ord = ''
            if (order.title == Strings.Delivery_web) {
                ord = Strings.Delivery_web
                Constants.OrderTypeID = 2
            } else if (order.title == Strings.lbl_takeaway_react) {
                ord = Strings.lbl_takeaway_react
                Constants.OrderTypeID = 1
            } else if (order.title == Strings.str_collect_drink) {
                ord = Strings.str_collect_drink
                Constants.OrderTypeID = 3
            }

            if(order.title == Strings.Delivery_web) {
                ordType = Strings.Delivery_web
                Constants.OrderTypeID = 2
            } else if(order.title == Strings.lbl_takeaway_react) {
                ordType = Strings.lbl_takeaway_react
                Constants.OrderTypeID = 1
            } else if(order.title == Strings.str_collect_drink) {
                ordType = Strings.str_sitDown
                Constants.OrderTypeID = 3
            }
            if (getSecureData(Constant.ORDERTYPE) != ordType) {
                setSecureData(Constants.ORDERTYPEID, Constants.OrderTypeID)
                this.setState({ isAlertVisible: true, alertType: 2, alertMessage: Strings.remove_cart_item_message.replace('#', ord) })
            }

        } else {
            /* comment for order type */
            // ordType = (order.title == Strings.Delivery_web) ? 'Delivery' : 'TakeAway'
            if(order.title == Strings.Delivery_web) {
                ordType = Strings.Delivery_web
                Constants.OrderTypeID = 2
            } else if(order.title == Strings.lbl_takeaway_react) {
                ordType = Strings.lbl_takeaway_react
                Constants.OrderTypeID = 1
            } else if(order.title == Strings.str_collect_drink) {
                ordType = Strings.str_sitDown
                Constants.OrderTypeID = 3
            }
            setSecureData(Constant.ORDERTYPE, ordType)
            setSecureData(Constants.ORDERTYPEID, Constants.OrderTypeID)
            console.log("OrderData3",order)
            // if(this.status.menuitemcallStatus){
                setTimeout(() => {
                    // this.setState({menuitemcallStatus : false},() => {this.props.callmenuitems()})
                    this.setState({})
                }, 200);
            // }
            this.props.callmenuitems()
            
        }
    }

    onConfirmClick() {
        if (this.state.alertType == 1) {
            setSecureData(Constant.LOCATIONOBJECT, this.state.selectedLocationObject)
            localStorage.removeItem(Constant.USERCART)
            localStorage.removeItem('tablePreBookingObj')
            setTimeout(() => {
                this.setState({ isAlertVisible: false })
                this.props.callmenuitems()
            }, 200);

        } else if (this.state.alertType == 2) {
            setSecureData(Constant.ORDERTYPE, ordType)
            localStorage.removeItem(Constant.USERCART)
            localStorage.removeItem('tablePreBookingObj')
            setTimeout(() => {
                this.setState({ isAlertVisible: false })
                this.props.callmenuitems()
            }, 200);
        }

    }

    renderLocationList() {
        var arrReturn = []

        // let locationList = JSON.parse(localStorage.getItem(Constant.LOCATIONLISTOBJECT))
        let locationList = getSecureData(Constant.LOCATIONLISTOBJECT)
        // let locationObject = JSON.parse(localStorage.getItem(Constant.LOCATIONOBJECT))
        let locationObject = getSecureData(Constant.LOCATIONOBJECT)
        if (locationList) {
            if (locationList.length > 0) {
                locationList.map((location, index) => {
                    arrReturn.push(
                        // <li><a  className="dropdown-item" href="javascript:void(0);" onClick={() => { this.onChangeLocation(location) }}>{location.locationName}</a></li>
                        <li key={index}><a href="javascript: void(0)" onClick={() => { this.onChangeLocation(location) }}>{location.locationName}</a></li>
                    )
                })
            } else {
                return
            }
        } else {
            return
        }

        return (
            // <div className="lang_drop">
            //     <div className="dropdown">
            //         <a href="javascript: void(0)" className="" type="button" data-toggle="dropdown"><span className="caret">{(locationObject) ? locationObject.locationName : null }</span></a>
            //         <ul className="dropdown-menu">
            //             {arrReturn}
            //         </ul>
            //     </div>
            // </div>
            <li>
                <a href="javascript: void(0)" className="myHovery" id="myHovery">{(locationObject) ? locationObject.locationName : null}</a>
                <ul>
                    {arrReturn}
                </ul>
            </li>
        )
    }

    renderBadge() {

        if (localStorage.hasOwnProperty(Constant.USERCART)) {
            // let value = localStorage.getItem(Constant.USERCART);
            let value = getSecureData(Constant.USERCART);
            if(value !== undefined) {
                if (value !== null) {
                    if (value.length > 0) {
                        return (
                            <span>{value.length}</span>
                        )
                    }
                }
            }
        }

    }

    // renderOrderTypes() {
    //     //Check user is logged in or not
    //     if (this.state.loggedInUserId > 0) {
    //         //check location object available or not in local storage if available then fetch and set data
    //         if (getSecureData(Constant.LOCATIONOBJECT)) {
    //             if (getSecureData(Constant.ORDERTYPE)) {
    //                 let locationObject = getSecureData(Constant.LOCATIONOBJECT)
    //                 var arry = []
    //                 if (locationObject.isDelivery) {
    //                     arry.push({ title: Strings.Delivery_web, isSelect: false })
    //                 }
    //                 if (locationObject.isTakeAway) {
    //                     arry.push({ title: Strings.lbl_takeaway_react, isSelect: false })
    //                 }
    //                 /* comment for order type */
    //                 if (locationObject.isOrderExtras) {
    //                     arry.push({ title: Strings.str_collect_drink, isSelect: false })
    //                 }
    //                 var arrReturn = []
    //                 arry.map((order, index) => {
    //                     arrReturn.push(
    //                         <li key={index}><a href="javascript: void(0)" onClick={() => { this.onChangeOrderType(order) }}>{order.title}</a></li>
    //                     )
    //                 })
    //                 return (
    //                     <li>
    //                         <a href="javascript: void(0)">{getSecureData(Constant.ORDERTYPE) == 'Delivery' ? Strings.Delivery_web : Strings.lbl_takeaway_react}</a>
    //                         <ul>
    //                             {arrReturn}
    //                         </ul>
    //                     </li>
    //                 )
    //             }
    //         }
    //     }
    // }    

    getLocationOrderType = () => {

        console.log("LOCATIONOBJECT",getSecureData(Constants.LOCATIONOBJECT))
    }

    render() {
        console.log("window.innerWidth",window.innerWidth)
        return (
            <div class="white dishes" style={{background: `url("images/bg/bg.png") no-repeat`,display: 'list-item',height: '384px' , width: '100%', backgroundSize: 'cover'}} >

                 <AlertDialog isAlertVisible={this.state.isAlertVisible} msgTitle={this.state.alertMessage} isMultibutton={true} btnLeftClick={() => {this.setState({isAlertVisible:false})}} btnRightClick={() => {this.onConfirmClick()}} btnLeftTitle={Strings.btn_cancel}  btnRightTitle={Strings.btn_confirm} />

            <header>
                <div className={this.props.isMenu ? "header hideMenu" :"header hideMenu"}>
                    <div className=" header-container">
                        <div className="head-inr ">
                            <div className="row">
                                <div className="col-m-3 head-logo">
                                    <img className="" src="images/bg/jimmybarlogo.png" onClick={() => window.location.href = '/'} />
                                </div>
                                <div className="col-m-9 header-col">
                                <a href="#" class="lines-button x2"><span class="lines"></span></a>     
                                    <div class="main_menu">
                                    <ul className="header-menu firstul">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="/AboutUs">About Us</a></li>
                                        <li><a href="/ContactUs">Contact</a></li>
                                        
                                        {
                                            getSecureData(Constant.LOCATIONOBJECT) != undefined && getSecureData(Constant.LOCATIONOBJECT).isTableBooking == true
                                            ?
                                            this.renderBookTableMenu()
                                            : 
                                            null
                                        }

                                        {
                                            getSecureData(Constant.LOCATIONOBJECT) != undefined && getSecureData(Constant.LOCATIONOBJECT).isWaitingList == true
                                            ?
                                            <li class="menu-btn">
                                                <a href="/VirtualQueue" className="orderOnlineBtn VirtualQueue" >Virtual Queue</a>
                                            </li>
                                            : 
                                            null
                                        }

                                        <li class="menu-btn">
                                            
                                            <a href="/categories"  className="orderOnlineBtn" >Order Online</a>
                                        </li>

                                        

                                        {/* <li class="menu-btn">
                                            <a href="javascript: void(0)">
                                                <li>
                                                    <a href="javascript: void(0)" className="orderOnlineBtn">{'Table Order'}</a>
                                                    <ul>
                                                        <li ><a href="#" >{'Take Away'}</a></li>
                                                        <li><a href="#" >{'Delivery'}</a></li>
                                                     
                                                       
                                                    </ul>
                                                </li>
                                            </a>
                                        </li> */}

                                        
                                     
                                         {this.renderAccountMenu()}
                                         {!this.props.isHideOrderType ? this.renderOrderType() : null}
                                       
                                    </ul>
                                    </div>
                                    <div class="header-col-right">

                                        {/* <div className="hg_cart"><a href="javascript: void(0)"></a></div>     */}
                                        {this.renderCartBadge()}
                                        <div class="app-btn">
                                                <ul>
                                                    <li>
                                                        <img className="appIconImg" src="images/bg/app.png" onClick={()=>{window.open('https://apps.apple.com/gb/app/jimmys-sports-bar/id1573629887','_blank');}} />
                                                    </li>

                                                     <li>
                                                        <img className="appIconImg"  src="images/bg/gpay.png" onClick={()=>{window.open('https://play.google.com/store/apps/details?id=com.jimmybar','_blank');}} />
                                                    </li>
                                                </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </header>
                <div className="headr-banr">
                    {this.props.isOffer ? 
                        <div className="container">
                            <h1>HAPPY HOUR <br/>+OFFERS</h1>
                            <div class="hero__banner">
                                <div class="hero__banner-img"></div>
                            </div>
                    </div>
                        
                    :
                    <div class="innerbanner-wrp">
                            <div className=" inner-hero-head">
                                <h1>{this.props.headerLbl}</h1>
                            </div>
                             <div class="inner-banner-img"><img className="bannerImg" src="/images/bg/inner-banner.png"  /></div>
                        </div>
                    }
                    
                </div>
            </div>
        )   
    }
}

export default withRouter(HeaderWhiteMenuHalf);