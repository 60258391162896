import React, { Component } from "react";

import {withRouter} from 'react-router-dom';

import Constants from '../constants/baseConstants';
import Modal from 'react-bootstrap/Modal'


class CMS extends Component {
  
  constructor(props) {
    super(props);


  }

  render() {
    return (
      
    <Modal className="TandCmodal"
        show={this.props.isShow}
        onHide={()=>{this.props.onDisable()}}
        size="lg"
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
        </Modal.Header>
      <Modal.Body>
            <div style={{overflow:'hidden',position:'relative',paddingTop:'46%'}}>
                {/* {this.props.isLoading ? <div class="loadermain" style={{ display: 'block' }}>
                    <div class="loader" ></div>
                </div> : <div class="loadermain" style={{ display: 'none' }}>
                        <div class="loader" ></div>
                    </div>} */}

                <iframe src={this.props.href} width='100%' height='100%' style={{position:'absolute', top:0, border:'none'}}></iframe>
                
            </div>
      </Modal.Body>
    </Modal>
    )
  }

}

export default CMS;