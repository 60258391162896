import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import Constant from '../constants/baseConstants';
import HeaderSignin from '../Components/HeaderSignin';
import FooterMenu from '../Components/FooterMenu';
import { Strings } from '../constants/Localization';
import AlertDialog from '../Components/AlertDialog';
import {setSecureData, getSecureData} from '../Utils/utils';
import AOS from 'aos';
import 'aos/dist/aos.css';
import queryString from 'query-string';
import { ThemeProvider } from 'react-bootstrap';
import HeaderWhiteMenuHalf from '../Components/HeaderWhiteMenuHalf';
import LoginForm from '../Components/LoginForm';
import SignUpForm from '../Components/SignUpForm';
import ForgetForm from '../Components/ForgetForm'

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

class VirtualQueue extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customerId : 0,
            activeCardID : -1,
            isLoading:false,
            errors: {
                errBookingDate: '',
                errBookingTime: '',  
                errBookingSlotTime : '',              
            },
            isAlertVisible: false,
            alertMessage: '',
            btnLeft: '',
            btnRight: '',
            isMultiButton: false,
            dialogType:1, 
            languageType: 1, //default language type
            minBookingDate : '', 
            minBookingTime : '',
            totalPerson : 1, 
            bookingDate: '',
            bookingTime: '',
            componentLoadStep : 'step2',
            bookingTimeSloat  :[],
            bckBtnTable : '',
            payNowResponse : {},

            fromTime : '',
            toTime : '',
            noOfPeople : 1,
            tableNoOfPeople : 1,
            restaurantId : '',
            locationId : 1,
            languageId: 1,
            date : '',
            tableBookingPerPersonCharge : 5,
            virtualQueData : [],

            isVisible : false,
            isLoginEnabled: false,
            isSignUpEnabled: false,
            isForgetEnabled : false,

        }
    }

    componentWillMount(){
        if(getSecureData(Constant.LOCATIONOBJECT) != undefined && getSecureData(Constant.LOCATIONOBJECT).isTableBooking == true){
            // this.checkUserLogin()
            this.fetchLocationList()
        }
        else{
            window.location.href = "/"
            return
        }
    }
    componentDidMount(){
        
        let params = queryString.parse(this.props.location.search)
        if (params.customerId) {
            console.log('query string -------------> ', params)
        }
        

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                // this.setState({ languageType: mLanguageType })
                this.setState({ languageType: mLanguageType,languageTypeCode:"en-GB"})
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    // this.setState({ languageType: mLanguageType })
                    this.setState({ languageType: mLanguageType,languageTypeCode:"es"})
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        var today = new Date().toISOString().split('T')[0];
        this.setState({ minBookingDate : today})

        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        this.setState({ minBookingTime : today})

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                if (userObject != null) {
                     this.setState({customerId : userObject.customerId});
                    console.log('login user :' + JSON.stringify(userObject));
                }
            }
        }

        this.checkUserLogin()
        
    }

    checkUserLogin = () => {
        // this.setState({isContentLoading:true})
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject !== null) {
                this.getTableVirtualWaitingByCustomer()
            }


        }

    }

    fetchLocationList() {
        this.props.locationListApi(this.state.languageType).then(() => {
            if (this.props.data.status == 1) {
                if (this.props.data.result != null) {
                    var locationList = this.props.data.result;
                    if (locationList != null && locationList.length > 0) {
                        if (getSecureData(Constant.LOCATIONOBJECT)) {
      
                            // console.log('location object  ----------- ',getSecureData(Constants.LOCATIONOBJECT))
                            // console.log('locationList  ----------- ',locationList)
          
                            let objLocation = locationList.filter(obj => {
                              return (obj.locationId == getSecureData(Constant.LOCATIONOBJECT).locationId)
                            })
          
                          
          
                            this.setState({locationObj: objLocation[0]}, ()=>{
                              setSecureData(Constant.LOCATIONOBJECT,objLocation[0])
                              // this.setLocationData()
                            })
                          } else {
                            if(locationList.length == 1){
                              this.setState({locationObj: locationList[0]}, ()=>{
                                setSecureData(Constant.LOCATIONOBJECT,locationList[0])
                                // this.setLocationData()
                              })
                            }
                            else{
                              setSecureData(Constant.LOCATIONLISTOBJECT,locationList)
                              this.setState({ locationArray: locationList, isVisible: true })
                            }
                          }
                    }
                }

                console.log("checkLocation",getSecureData(Constant.LOCATIONOBJECT))
                if(getSecureData(Constant.LOCATIONOBJECT) != undefined && getSecureData(Constant.LOCATIONOBJECT).isTableBooking == true){
                }
                else{
                    window.location.href = "/"
                    return
                }
            } else {
                    // alert(this.props.data.message)
            }
        })


        if(getSecureData(Constant.LOCATIONOBJECT) == null || getSecureData(Constant.LOCATIONOBJECT) == undefined) {
            // window.location.href = '/signin';
            console.log('getSecureData(Constant.LOCATIONOBJECT)',getSecureData(Constant.LOCATIONOBJECT))
            return
        }

        
      }

    getTableVirtualWaitingByCustomer = () => {
        let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId
        let userObject = getSecureData(Constant.USEROBJECT);
        this.setState({locationId : locationId})
        var data = {
            locationId: locationId,
            customerId: userObject.customerId,
            languageId: this.state.languageType,
        }
        console.log("virtualQueData data",data)
        if(userObject.customerId != 0  && this.state.languageType != '' && locationId != ''){
            this.props.getTableVirtualWaitingByCustomer(data).then(() => {

                console.log("virtualQueData :",this.props.data)
                if (this.props.data.status == 1) {
                    this.setState({
                        componentLoadStep : this.props.data.result.Booking.length > 0 ? 'step1' :'step2',
                        virtualQueData : this.props.data.result.Booking.length > 0 ? this.props.data.result.Booking[0] : [],
                        
                    })
    
    
                }
                else{
                    // alert('Something went wrong')
                }
    
                
    
            })
            .catch((err)=>{
                console.log('error ',err)
            })
        }
    }

    GetLocationListApi = () => {
        this.props.AppMessageApi(this.state.languageType).then(() => {

            if (this.props.data.status == 1) {
                this.setState({tableBookingPerPersonCharge : this.props.data.result.tableBookingPerPersonCharge})
            }

        })
    }

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my account page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST,messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }



 

    onQtyChanged(type, totalPerson, index) {
        var quantity = totalPerson
        if (type == 0) {
            if (quantity > 1) {
                quantity -= 1
            }
        } else {
            quantity += 1
        }

        this.setState({
            totalPerson : quantity
        })

    }

    cancelVirtualQue = (id) => {
        let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId
        let userObject = getSecureData(Constant.USEROBJECT);
        this.setState({locationId : locationId})
        var data = {
            locationId: locationId,
            customerId: userObject.customerId,
            languageId: this.state.languageType,
            Id : id
        }
        console.log("virtualQueData data",data)
        if(userObject.customerId != 0  && this.state.languageType != '' && locationId != '' && id != ''){
            this.props.deleteTableVirtualWaiting(data).then(() => {

                console.log("virtualQueData :",this.props.data)
                if (this.props.data.status == 1) {
                    this.setState({
                        componentLoadStep : 'step2',
                        // virtualQueData : this.props.data.result.Booking[0],
                        
                    })
                    this.setState({isAlertVisible: true, alertMessage: 'You have removed from virtual queue.', isMultiButton: false, btnLeft: Strings.Ok_web})
    
                }
                else{
                    alert('Something went wrong')
                }
    
                
    
            })
            .catch((err)=>{
                console.log('error ',err)
            })
        }
    }
    
    addVirtualQueue = () => {

        //check user is login or not start
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject == null) {
                this.setState({isLoginEnabled: true})
                return
            }
        } else {
            this.setState({isLoginEnabled: true})
            return
        }
        //check user is login or not end


        let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId
        let userObject = getSecureData(Constant.USEROBJECT);
        this.setState({locationId : locationId})
        var data = {
            locationId: locationId,
            customerId: userObject.customerId,
            languageId: this.state.languageType,
            noOfPeople : this.state.totalPerson
        }
        console.log("virtualQueData data",data)
        if(userObject.customerId != 0  && this.state.languageType != '' && locationId != '' && this.state.totalPerson != ''){
            this.props.addTableVirtualWaiting(data).then(() => {

                console.log("virtualQueData :",this.props.data)
                if (this.props.data.status == 1) {
                    this.setState({
                        componentLoadStep : 'step1',
                        // virtualQueData : this.props.data.result.Booking[0],
                        
                    })
                    this.setState({isAlertVisible: true, alertMessage: 'You have been added into the queue.', isMultiButton: false, btnLeft: Strings.Ok_web})
    
                }
                else{
                    this.setState({isAlertVisible: true, alertMessage: this.props.data.message, isMultiButton: false, btnLeft: Strings.Ok_web})
                }
    
                this.getTableVirtualWaitingByCustomer()
    
            })
            .catch((err)=>{
                console.log('error ',err)
            })
        }
   
    }

    onLogin() {
        this.setState({isLoginEnabled: false, isSignUpEnabled: false, isForgetEnabled : false},()=>{
            setTimeout(() => {
                if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
                    let userObject = getSecureData(Constant.USEROBJECT);
                    if (userObject != null) {
                        let customerId = getSecureData(Constant.USEROBJECT).customerId
                        let id = getSecureData(Constant.LOCATIONOBJECT).locationId
                        this.setState({ customerId: customerId})    
                    } else {
    
                    }
                } else {
                    
                }
            }, 200);
        })
    }

    render() {
        return (
            <div className="loginpage white nonsticy" style={{}}> 
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}
                {/* <HeaderMenu /> */}
                {/* Old header */}
               <HeaderWhiteMenuHalf isHideOrderType={true} headerLbl={'Virtual Queue'} isCategory={true} 
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/> 
                <AlertDialog 
                isAlertVisible={this.state.isAlertVisible} 
                msgTitle={this.state.alertMessage} 
                isMultibutton={this.state.isMultiButton} 
                btnLeftClick={() => { 
                    this.setState({ isAlertVisible: false });
                    // window.location.href = Constant.mainURL
                    return
                 }} 
                 btnRightClick={() => { 
                     this.onRightClick() }} 
                btnLeftTitle={this.state.btnLeft} 
                btnRightTitle={this.state.btnRight} />
                {/* <HeaderSignin logoType={1} headerLbl={Strings.reset_pass_web} isCategory={true} /> */}
                {<LoginForm isShowForm={this.state.isLoginEnabled} onResponse={()=>this.onLogin()} onSignUp={()=>{this.setState({isLoginEnabled: false, isSignUpEnabled: true})}} onForget={()=>{this.setState({isLoginEnabled: false, isForgetEnabled: true})}}></LoginForm>}
                {<SignUpForm isShowForm={this.state.isSignUpEnabled} onResponse={()=>this.onLogin()}></SignUpForm>}
                {<ForgetForm isShowForm={this.state.isForgetEnabled} onResponse={()=>this.setState({isLoginEnabled: false, isSignUpEnabled: false, isForgetEnabled : false})}></ForgetForm>}

                {(() => {
                      switch (this.state.componentLoadStep) {
                        case 'step1':
                            return this.virtualQueAvailable()
                        case 'step2':
                            return this.addMevirtualQueue()
                        default:
                            return ''
                     }
                })()}
                <FooterMenu />
            </div>
        )
    }

    

    virtualQueAvailable = () => { 

        return (
            <div className="content_wrap">
                    <div className="container">
                        {/* <div className="book-tab-backbtn" style={{float: 'right'}}>
                                
                                <a  className="btn btn-primary order-btn book-back-btn" href="/BookTableList" >Back</a>
                        </div> */}
                        <div className="user booktable-wrp fo-btm-spc">

                            <div className="row">
                                <div className="col-md-12  col-lg-6 center" style={{margin:'0 auto'}}>
                                    <h1 className="text-center">{'Virtual Queue'}</h1>
                                    <hr />

                                    <div className="vQcongdiv">
                                        {/* <h2 className="text-center">{'Congratulations'}</h2> */}

                                        <p  className="text-center" >You are added to virtual queue</p>
                                        <p className="text-center" >Your current position is</p>
                                    </div>
                                    
                                    <div className="vQImgBox">
                                        <img  className="congImg" src="images/bg/start.png" alt="congratulations" />
                                        <h2 className="text-center" >{ this.state.virtualQueData.estimatedNo }</h2>
                                    </div>
                                    <br />

                                    <div className="vQdiduknow">
                                        <p className="text-center">We will let you know</p>
                                        <p className="text-center" >When your table is ready.</p>
                                    </div>
                                    

                                    <div className="checkoutBtn " >
                                        <div className="buttons-checkout">
                                            <button className="btn btn-primary btn-medium vQbtn" onClick={() => { this.cancelVirtualQue(this.state.virtualQueData.Id) }}>I HAVE CHANGED MY MIND</button>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

     convertDate = () =>{
        let d = new Date(this.state.bookingDate)

        const year = d.getFullYear()
        const month = months[d.getMonth()]
        const date = d.getDate() 
        return date + ' ' + month + ' '+ year;
    }

    addMevirtualQueue = () => { 

        return (
            <div className="content_wrap">
                    <div className="container">
                        {/* <div className="book-tab-backbtn" style={{float: 'right'}}>
                                
                                <a  className="btn btn-primary order-btn book-back-btn" href="/BookTableList" >Back</a>
                        </div> */}
                        <nav class="braedCrumbs" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item"><a href="/BookTable">Book A Table</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Virtual Queue</li>
                            </ol>
                        </nav>
                        <div className="user booktable-wrp fo-btm-spc">

                            <div className="row">
                                <div className="col-md-12  col-lg-6 center" style={{margin:'0 auto'}}>
                                    <h1 className="text-center">{'Add To Virtual Queue'}</h1>
                                    <hr />
                                    <div className="addVq" >
                                            <h4>{'Select Number Of Guest'}*</h4>
                                            <div class="QtyDiv">
                                                <button href="" type="button" class="btn" data-toggle="modal" data-target="#myModal" onClick={() => { this.onQtyChanged(0, this.state.totalPerson) }}><img src="../images/minus-icon.png" /></button>
                                                <label class="QTYLabel">{this.state.totalPerson}</label>
                                                <button href="" type="button" class="btn" data-toggle="modal" data-target="#myModal" onClick={() => { this.onQtyChanged(1, this.state.totalPerson) }}><img src="../images/plus-icon.png" /></button>
                                            </div>
                                    </div>


                                    <div className="checkoutBtn " >
                                        <div className="buttons-checkout">
                                            <button className="btn btn-primary btn-medium vQbtn" onClick={() => { this.addVirtualQueue() }}>ADD ME TO VIRTUAL QUEUE</button>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }




    setTimesloat = (val) => {
        
        
        this.setState({
            // componentLoadStep : 'step3',
            activeCardID : val.tId,
            fromTime : val.fromTime,
            toTime : val.toTime,
            tableNoOfPeople : val.tableNoOfPeople,
        })

        // {"restaurantId":147,"locationId":363,"customerId":33006,"languageId":1,"date":"05/31/2021","fromTime":"18:30","toTime":"19:00","noOfPeople":1,"tableNoOfPeople":2}
    }

    convertFrom24To12Format = (time24) => {
        const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        const period = +sHours < 12 ? 'AM' : 'PM';
        const hours = +sHours % 12 || 12;
      
        return `${hours}:${minutes} ${period}`;
      }

}

function mapStateToProps(state) {
    console.log('isLoaging ResetPassword ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    getTableReservationTimeSlotList: (customerId,locationId, languageId, bookingDate, BookingTime,totalPerson) => dispatch(HGActions.getTableReservationTimeSlotList(customerId,locationId, languageId, bookingDate, BookingTime, totalPerson)),
    addTableReservation: (data) => dispatch(HGActions.addTableReservation(data)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
    GetLocationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),
    getTableVirtualWaitingByCustomer : (data) => dispatch(HGActions.getTableVirtualWaitingByCustomer((data))),
    deleteTableVirtualWaiting : (data) => dispatch(HGActions.deleteTableVirtualWaiting((data))),
    addTableVirtualWaiting : (data) => dispatch(HGActions.addTableVirtualWaiting((data))),  
    locationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),

})
export default connect(mapStateToProps, mapDispatchToProps)(VirtualQueue);