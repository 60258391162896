import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import Constant from '../constants/baseConstants';
import Constants from '../constants/baseConstants';
import HeaderSignin from '../Components/HeaderSignin';
import FooterMenu from '../Components/FooterMenu';
import { Strings } from '../constants/Localization';
import AlertDialog from '../Components/AlertDialog';
import {setSecureData, getSecureData} from '../Utils/utils';
import AOS from 'aos';
import 'aos/dist/aos.css';
import queryString from 'query-string';
import { ThemeProvider } from 'react-bootstrap';
import HeaderWhiteMenuHalf from '../Components/HeaderWhiteMenuHalf';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class ContactUs extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customerId : 0,
            activeCardID : -1,
            isLoading:false,
            errors: {
                errBookingDate: '',
                errBookingTime: '',  
                errBookingSlotTime : '',              
            },
            isAlertVisible: false,
            alertMessage: '',
            btnLeft: '',
            btnRight: '',
            isMultiButton: false,
            dialogType:1, 
            languageType: 1, //default language type
            minBookingDate : '', 
            minBookingTime : '',
            totalPerson : 1, 
            bookingDate: '',
            bookingTime: '',
            componentLoadStep : 'step2',
            bookingTimeSloat  :[],
            bckBtnTable : '',
            payNowResponse : {},

            fromTime : '',
            toTime : '',
            noOfPeople : 1,
            tableNoOfPeople : 1,
            restaurantId : '',
            locationId : 1,
            languageId: 1,
            date : '',
            tableBookingPerPersonCharge : 5,
            virtualQueData : [],
            offerList : [],
            offerLevelPoint : 0,
            level : 0,
            levelName : '',
            MemberOfferPointAmount : 0,
            ContentData : {},
            locationObj: {
                address1: "6 Harlestone Rd, Northampton \n NN5 7AE, United Kingdom",
                countryName: "United Kingdom",
                phone: "+44 1604 757007",
                locationTimeList: [
                  {
                    day: "Monday - Sunday",
                    time: "01:00 AM - 11:00 PM",
                  },
                ],
            },
            errors: {
                nameError: '',
                countryCodeError: '',
                phoneNumberError: '',
                emailError: '',
                passwordError: '',
                locationError: '',
                tAndCError:'',
                consentError:'',
                birthDateError : ''
            },
            name: '',
            countryCode: '+44',
            phoneNumber: '',
            email: '',
            description : ''



        }
    }
    componentDidMount(){
        
        let params = queryString.parse(this.props.location.search)
        if (params.customerId) {
            console.log('query string -------------> ', params)
        }
        

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                // this.setState({ languageType: mLanguageType })
                this.setState({ languageType: mLanguageType,languageTypeCode:"en-GB"})
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    // this.setState({ languageType: mLanguageType })
                    this.setState({ languageType: mLanguageType,languageTypeCode:"es"})
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        var today = new Date().toISOString().split('T')[0];
        this.setState({ minBookingDate : today})

        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        this.setState({ minBookingTime : today})

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                if (userObject != null) {
                     this.setState({customerId : userObject.customerId});
                    console.log('login user :' + JSON.stringify(userObject));
                }
            }
        }

        this.getContentDataById()
        this.fetchLocationList()
        this.checkUserLogin()
        
    }



    getContentDataById = () => {
        // let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId
        // let userObject = getSecureData(Constant.USEROBJECT);
        // this.setState({locationId : locationId})
        var data = {
            // locationId: locationId,
            // customerId: userObject.customerId,
            // languageId: this.state.languageType,
            // offerType : 1
            contentId : 4
        }
        console.log("offerList data",data)
        if(data.contentId != ''){
            this.props.getContentDataById(data).then(() => {

                console.log("ContentData",this.props.data)
                if (this.props.data.status == 1) {
                    this.setState({
                        ContentData : this.props.data.result.ContentData.length > 0 ? this.props.data.result.ContentData[0] : {},
                    })
    
    
                }
                else{
                    alert('Something went wrong')
                }
    
                
    
            })
            .catch((err)=>{
                console.log('error ',err)
            })
        }
    }

    GetLocationListApi = () => {
        this.props.AppMessageApi(this.state.languageType).then(() => {

            if (this.props.data.status == 1) {
                this.setState({tableBookingPerPersonCharge : this.props.data.result.tableBookingPerPersonCharge})
            }

        })
    }

    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("my account page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST,messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    checkUserLogin = () => {
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {

            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject !== null) {
                this.getCustomerDetailById()
            }
          

        }
       
    }


    fetchLocationList() {
        this.props.locationListApi(this.state.languageType).then(() => {
          if (this.props.data.status == 1) {
            if (this.props.data.result != null) {
              var locationList = this.props.data.result;
              if (locationList != null && locationList.length > 0) {
                if (getSecureData(Constants.LOCATIONOBJECT)) {
                  // console.log('location object  ----------- ',getSecureData(Constants.LOCATIONOBJECT))
                  // console.log('locationList  ----------- ',locationList)
    
                  let objLocation = locationList.filter((obj) => {
                    return (
                      obj.locationId ==
                      getSecureData(Constants.LOCATIONOBJECT).locationId
                    );
                  });
    
                  this.setState({ locationObj: objLocation[0] }, () => {
                    setSecureData(Constants.LOCATIONOBJECT, objLocation[0]);
                    // this.setLocationData()
                  });
                } else {
                  if (locationList.length == 1) {
                    this.setState({ locationObj: locationList[0] }, () => {
                      setSecureData(Constants.LOCATIONOBJECT, locationList[0]);
                      // this.setLocationData()
                    });
                  } else {
                    setSecureData(Constants.LOCATIONLISTOBJECT, locationList);
                    this.setState({ locationArray: locationList, isVisible: true });
                  }
                }
              }
            }
          } else {
            alert(this.props.data.message);
          }
        });
      }

      getCustomerDetailById (){

        let customerId = getSecureData(Constant.USEROBJECT).customerId
        let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId
        this.props.getCustomerDetails(customerId, locationId)
        .then(() => {
            console.log('get Customer Details', this.props.data)
            if (this.props.data.status == 1) {
                var userObject = this.props.data.result
                this.setState({
                    customerId: userObject.customerDetails.customerId, 
                    name: userObject.customerDetails.firstname, 
                    countryCode: userObject.customerDetails.countryCode,
                    phoneNumber: userObject.customerDetails.phone, 
                    email: userObject.customerDetails.emailId, 

                })
            }
        })
        .catch((error) => {

        })

    }
 

    onSendClick = (event) => {
        event.preventDefault();

        var nmError = this.state.name.length == 0 ? Strings.error_msg_name_react : '';
        var ccodeerror = this.state.countryCode == 0 ? Strings.error_msg_country_code_react : '';
        var phError = this.state.phoneNumber.length < 9 ? Strings.error_msg_phone_no_react : '';
        var emlError = this.state.email.length == 0 ? Strings.error_msg_email_react : '';
        var descriptionError = this.state.description.length == 0 ? 'Please enter a description.' : ''

        if (emlError.length == 0) {
            validEmailRegex.test(this.state.email) == false ? emlError = Strings.error_msg_valid_email_react : emlError = '';
        }

        this.setState({ errors: { nameError: nmError, countryCodeError: ccodeerror, phoneNumberError: phError, emailError: emlError, descriptionError : descriptionError } })

        // let userObject = JSON.parse(localStorage.getItem(Constant.USEROBJECT))
        let userObject = getSecureData(Constant.USEROBJECT)
        console.log(this.state.errors)
        setTimeout(() => {
                
            
            if (this.state.errors.nameError == '' && this.state.errors.phoneNumberError == '' && this.state.errors.emailError == '' && this.state.errors.descriptionError == '') {

                console.info('Valid form')
                // let location = JSON.parse(localStorage.getItem(Constant.LOCATIONOBJECT))
                var data = { "name" : this.state.name, "phoneNumber" : this.state.phoneNumber, "email" : this.state.email, "description" : this.state.description }
                this.props.contactUsMail(data).then(() => {
                    console.log("contactUsMail response --------- ", this.props.data)
                    if (this.props.data.status == 1) {
                                console.log(this.props.data.message)
                                this.setState({
                                    isAlertVisible: true, 
                                    alertMessage:this.props.data.message,
                                    isMultiButton : false,
                                    btnLeft : 'Ok',
                                    name : '',
                                    phoneNumber : '',
                                    email : '',
                                    description : '',
                                })

                    } else {
                        console.log(this.props.data.message)
                        console.log("sign up status else...")
                        this.setState({
                            isAlertVisible: true, 
                            alertMessage:this.props.data.message,
                            isMultiButton : false,
                            btnLeft : 'Ok'
                        })
                    }
                    
                })


            } else {
                console.error('Not valid form')
            }
        }, 100);
        
    }


  

    render() {
        return (
            <div className="loginpage white nonsticy" style={{}}> 
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}
                {/* <HeaderMenu /> */}
                {/* Old header */}
               <HeaderWhiteMenuHalf isHideOrderType={true} headerLbl={'Contact Us'} isCategory={true} 
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/> 
                <AlertDialog 
                isAlertVisible={this.state.isAlertVisible} 
                msgTitle={this.state.alertMessage} 
                isMultibutton={this.state.isMultiButton} 
                btnLeftClick={() => { 
                    this.setState({ isAlertVisible: false });
                    // window.location.href = Constant.mainURL
                    return
                 }} 
                 btnRightClick={() => { 
                     this.onRightClick() }} 
                btnLeftTitle={this.state.btnLeft} 
                btnRightTitle={this.state.btnRight} />
                {/* <HeaderSignin logoType={1} headerLbl={Strings.reset_pass_web} isCategory={true} /> */}
                
                <div className="content_wrap">
                    <div className="container">
                        {/* <div className="book-tab-backbtn" style={{float: 'right'}}>
                                
                                <a  className="btn btn-primary order-btn book-back-btn" href="/BookTableList" >Back</a>
                        </div> */}
                        {/* <nav class="braedCrumbs" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/Rewards">Rewards</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Best Offer</li>
                            </ol>
                        </nav> */}
                        <div className="user booktable-wrp fo-btm-spc">

                            <div className="row">
                                <div className="col-md-6 col-lg-6 contactBox1" >
                                        
                                    <div className="homepageMapover">
                                        <img src="images/bg/Path-504.png" />
                                        <div className="hmapover-wrp hmapover-wrp-contactPage">
                                            <div class="hmapover-infocont">
                                            <div class="hmapover-div">
                                                <i class="fas fa-map-marker-alt"></i>
                                                {this.state.locationObj.address1},{" "}
                                                {this.state.locationObj.countryName}
                                            </div>

                                            <div class="hmapover-div">
                                                <i class="fas fa-mobile-alt"></i>
                                                {this.state.locationObj.phone}
                                            </div>
                                            <div class="hmapover-div">
                                                <i class="fas fa-clock"></i>
                                                {this.state.locationObj.locationTimeList[0].time}
                                            </div>
                                            <div class="hmapover-social contactusIcon">
                                            <ul>
                                                <li>
                                                <a
                                                    href="https://www.facebook.com/jimmysportsbar/"
                                                    target="_blank"
                                                >
                                                    <i class="fab fa-facebook-f"></i>
                                                </a>
                                                </li>
                                                <li>
                                                <a
                                                    href="https://www.instagram.com/jimmysportsbar/?hl=en"
                                                    target="_blank"
                                                >
                                                    <i class="fab fa-instagram"></i>
                                                </a>
                                                </li>
                                                <li>
                                                <a
                                                    href="https://twitter.com/jimmyssportsba1?lang=en"
                                                    target="_blank"
                                                >
                                                    <i class="fa fa-twitter"></i>
                                                </a>
                                                </li>
                                            </ul>
                                            </div>
                                            </div>
                                            
                                        </div>
                                        </div>
                                    </div>
                          
                                <div className="col-md-6  col-lg-6 contactBox2" >
                            
                                    <div class="form-group">
                                        <label>{Strings.lbl_name_react}*</label>
                                        <input type="text" class="form-control" maxLength={64} value={this.state.name} onChange={e => this.setState({ name: e.target.value })} tabIndex="1" />
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.nameError}</span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>{Strings.lbl_email_react}*</label>
                                        <input type="text" class="form-control" maxLength={64} value={this.state.email} onChange={e => this.setState({ email: e.target.value })} tabIndex="3" />
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.emailError}</span>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>{Strings.lbl_phone_number_react}*</label>
                                        <input type="text" className="form-control" maxLength={11} value={this.state.phoneNumber} onChange={e => this.setState({ phoneNumber: e.target.value })} />
                                        {/* <div className="row">
                                            <div className="col-5 col-md-3 pr-0">
                                            <label type="text" className="form-control lblPhone">+44</label>
                                             <div>
                                            <div className="col-7 col-md-9">
                                                <input type="text" className="form-control" maxLength={11} value={this.state.phoneNumber} onChange={e => this.setState({ phoneNumber: e.target.value })} />
                                            </div>
                                        </div> */}
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.phoneNumberError}</span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>{Strings.description}*</label>
                                        <textarea type="text" className="form-control" value={this.state.description} onChange={text => {this.setState({description: text.target.value})}}/>
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.descriptionError}</span>
                                        </div>
                                    </div>
                                    
                                    <div class="text-center">
                                        <a href="#" className="btn btn-primary btn-large" onClick={this.onSendClick} tabIndex="5">SEND</a>
                                    </div>
      
                                    
                                </div>
                            </div>
                            
                        </div>

                        <div class=" h-orderbx-rw innerpageThreeCardBoxBtn">
                            <div className="row m-0">
                                <div className="col-md-4 p-0">
                                <div class="h-orderbx-wrp innerpageThreeCardWrp">
                                    <div class="h-orderbx-img">
                                    <img class="bgcardbdy" src="images/bg/bg-up-1.png" />
                                    </div>
                                    {/* <div class="bgcardbdy" style={{background: `url("images/bg/bg-up-1.png") no-repeat`,display: 'block',height:'210px', width: 'auto', objectFit: 'cover', backgroundColor: 'black', opacity: '0.9'}} > */}
                                    <div class="h-orderbx-cont">
                                    <h4 class="head-h2">Order Online</h4>
                                    <a href="/categories" className="order-btn">
                                        Start Ordering
                                    </a>
                                    </div>
                                </div>
                                {/* </div> */}
                                </div>
                                <div className="col-md-4 p-0">
                                <div class="h-orderbx-wrp innerpageThreeCardWrp">
                                    <div class="h-orderbx-img">
                                    {" "}
                                    <img class="bgcardbdy" src="images/bg/bg-up-2.png" />
                                    </div>
                                    {/* <div class="bgcardbdy" style={{background: `url("images/bg/bg-up-2.png") no-repeat`,display: 'block',height:'210px', width: 'auto', objectFit: 'cover', backgroundColor: 'black', opacity: '0.9'}}  > */}
                                    <div class="h-orderbx-cont">
                                    <h4 class="head-h2">Offers</h4>
                                    <a href="/BestOfferList" className="order-btn">
                                        View All Offers
                                    </a>
                                    </div>
                                </div>
                                {/* </div> */}
                                </div>
                                <div className="col-md-4 p-0">
                                <div class="h-orderbx-wrp innerpageThreeCardWrp">
                                    <div class="h-orderbx-img">
                                    {" "}
                                    <img class="bgcardbdy" src="images/bg/bg-up-3.png" />
                                    </div>
                                    {/* <div class="bgcardbdy" style={{background: `url("images/bg/bg-up-3.png") no-repeat`,display: 'block',height:'210px', width: 'auto', objectFit: 'cover', backgroundColor: 'black', opacity: '0.9'}} > */}
                                    <div class="h-orderbx-cont">
                                    <h4 class="head-h2">Book a Table</h4>
                                    {
                                        getSecureData(Constants.LOCATIONOBJECT) != undefined && getSecureData(Constants.LOCATIONOBJECT).isTableBooking == true
                                        ?
                                        <a href="/BookTable" className="order-btn">
                                            Book A Table
                                        </a>
                                        : 
                                        <a href="javascript: void(0)" className="order-btn diableBtn">
                                            Now Closed
                                        </a>
                                    }
                                    </div>
                                </div>
                                {/* </div> */}
                                </div>
                            </div>
                        </div>


                        <div class="section h-map-sec conatctMap">
                            <div className="container">
                                <div className="row">

                                    <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9772.748941947453!2d-0.9149502!3d52.2399827!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf9150757dc5ed833!2sJimmy&#39;s%20Sports%20Bar!5e0!3m2!1sen!2sin!4v1622031188816!5m2!1sen!2sin"
                                    width="1200"
                                    height="450"
                                    style={{ border: 0 }}
                                    allowfullscreen=""
                                    loading="lazy"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMenu />
            </div>
        )
    }




}

function mapStateToProps(state) {
    console.log('isLoaging ResetPassword ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
    GetLocationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),
    getContentDataById : (data) => dispatch(HGActions.getContentDataById((data))),
    locationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),
    getCustomerDetails : (customerId,locationId) => dispatch(HGActions.getCustomerDetails(customerId,locationId)),
    contactUsMail : (data) => dispatch(HGActions.contactUsMail(data))

})
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);