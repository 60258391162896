import Constants from '../constants/baseConstants';
var currencyFormatter = require('currency-formatter');
var CryptoJS = require("crypto-js");

export let getPriceFormat=(value)=>{
    // return currencyFormatter.format(value, { locale: localStorage.getItem(Constants.PRE_LANGUAGE_CODE) });
    // return currencyFormatter.format(value, { locale: 'gb' });
    return currencyFormatter.format(value, { locale: 'en-GB' });
}
export let getCurrencySign=()=>{
    return '\u00A3';
}

//set encrypted data in local storage
export const setSecureData = (keyName, objData) => {
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(objData), Constants.ENCRYPTKEY).toString();
    localStorage.setItem(keyName, ciphertext)
    return
}

export const getSecureData = (keyName) => {
    if (localStorage.hasOwnProperty(keyName)) {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem(keyName), Constants.ENCRYPTKEY);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // console.log(,bytes,decryptedData)
        return decryptedData
    }
    
}

export let bindhttps=(value)=>{
    return value.replace('http','http');
}