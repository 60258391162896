import React, { Component } from 'react';

import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import { Strings } from '../constants/Localization';
import FooterMenu from '../Components/FooterMenu';
import Constant from '../constants/baseConstants';
import HeaderWhiteMenuHalf from '../Components/HeaderWhiteMenuHalf';
import baseConstants from '../constants/baseConstants';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {setSecureData, getSecureData} from '../Utils/utils';

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

var mainObj;
var cardItem;
class CardDetails extends Component {

    constructor(props) {
        super(props)
        // cardItem = localStorage.getItem(baseConstants.PRE_CARD_LIST);
        cardItem = getSecureData(baseConstants.PRE_CARD_LIST);
        // cardItem = JSON.parse(cardItem);
        if (cardItem != null) {
            console.log("cardItem  :" + JSON.stringify(cardItem))
        }
        this.state = {
            customerId: 0,
            name: '',
            CardId: 0,
            cardNumber: '',
            cardBrand: '',
            cardType: '',
            cardCategory: '',
            countryCode: '+44',
            phoneNumber: '',
            email: '',
            comment: '',
            languageType: 1, //default language type
            errors: {
                categoryError: '',
            },
        }
    }

    componentDidMount() {
        //Init animation duration
        AOS.init({
            duration: 1000
        })

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                this.setState({
                    customerId: userObject.customerId
                })
                if (cardItem != null) {
                    this.setState({
                        CardId: cardItem.CardId, cardNumber: cardItem.cardNo, cardBrand: cardItem.cardBrand,
                        cardType: cardItem.cardType, cardCategory: cardItem.cardCategory
                    })
                }
            }
        } else {
            this.props.history.push({ pathname: '/' })
            return
        }

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                this.setState({ languageType: mLanguageType })
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    this.setState({ languageType: mLanguageType })
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }
    }


    //Handle change language click event
    //English language type: 1
    //Spanish language type: 2
    languageChangeClickEvent = (type) => {
        console.log("user feedback page language type :" + type);
        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, type)
        setSecureData(Constant.PRE_LANGUAGE_TYPE, type)
        this.setState({ languageType: type })
        setTimeout(() => {
            this.props.AppMessageApi(this.state.languageType).then(() => {
                if (this.props.data.status == 1) {
                    var messageListArray = this.props.data.result;
                    if (messageListArray != null && messageListArray.length > 0) {
                        console.log("message list  :" + JSON.stringify(messageListArray))
                        // localStorage.setItem(Constant.PRE_MESSAGE_LIST, JSON.stringify(messageListArray));
                        setSecureData(Constant.PRE_MESSAGE_LIST, messageListArray);
                        // let localMessage = JSON.parse(localStorage.getItem(Constant.PRE_MESSAGE_LIST));
                        let localMessage = getSecureData(Constant.PRE_MESSAGE_LIST);
                        // localStorage.setItem(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        setSecureData(Constant.PRE_LANGUAGE_TYPE, this.state.languageType)
                        // let languageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
                        let languageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
                        console.log('language type  :' + languageType);
                        if (languageType == 1) { //English language type: 1
                            Strings.setLanguage('en');//Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"en-GB"})
                        }
                        else {
                            Strings.setLanguage('es'); //Setting selected language code here
                            this.setState({ languageType: languageType,languageTypeCode:"es"})
                        }
                        Strings.setContent(localMessage)
                        // this.setState({ languageType: languageType })
                        window.location.reload(false);
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 700)
    }

    //Handle validation message
    isValidate = (event) => {
        event.preventDefault();
        var mCageoryError = this.state.cardCategory.length == 0 ? Strings.msg_card_type_category_react : '';
        this.setState({ errors: { categoryError: mCageoryError } })
        setTimeout(() => {
            if (this.state.errors.categoryError == '') {
                {
                    this.prepareRequest()
                }
            }
        }, 100)
    }
    //Prepare request and call card details api
    prepareRequest() {
        mainObj = {
            restaurantId: 147,
            customerId: this.state.customerId,
            languageId: this.state.languageType,
            // locationId: JSON.parse(localStorage.getItem(Constant.LOCATIONOBJECT)).locationId,
            locationId:getSecureData(Constant.LOCATIONOBJECT).locationId,
            cardCategory: this.state.cardCategory,
            CardId: cardItem.CardId,
            cardBrand: this.state.cardBrand,
            cardType: this.state.cardType
        }

        setTimeout(() => {
            this.props.cardDetailsApi(mainObj).then(() => {
                if (this.props.data.status == 1) {
                    if (this.props.data.result != null) {
                        setTimeout(() => {
                            alert(this.props.data.message)
                            // this.props.history.push({ pathname: '/CardListing' })
                            this.props.history.push({ pathname: '/MyAccount' })
                        }, 100)
                    }
                } else {
                    alert(this.props.error)
                }
            })
        }, 200)
    }

    render() {
        return (
            <div>
                {this.props.isLoading ? <div class="loadermain" style={{ display: 'block' }}>
                    <div class="loader" ></div>
                </div> : <div class="loadermain" style={{ display: 'none' }}>
                        <div class="loader" ></div>
                    </div>}
                <HeaderWhiteMenuHalf isHideOrderType={true}
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/>

                {/* <h2>{this.props.match.params.id}</h2> */}
                <div class="content_wrap">
                    <div class="container">
                        <div class="signupwrap" data-aos='fade-up'>
                            <h1>{Strings.card_details_web}</h1>
                            <div class="row">
                                <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                                    <div class="form-group">
                                        <label>{Strings.CardNumber_web}*</label>
                                        <input disabled type="text" class="form-control s" maxLength={75} value={this.state.cardNumber} onChange={e => this.setState({ cardNumber: e.target.value })} tabIndex="1" />
                                        {/* <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.nameError}</span>
                                        </div> */}
                                    </div>
                                    <div class="form-group">
                                        <label>{Strings.card_type_web}*</label>
                                        <input disabled type="text" class="form-control s" maxLength={75} value={this.state.cardType} onChange={e => this.setState({ cardType: e.target.value })} tabIndex="2" />
                                        {/* <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.emailError}</span>
                                        </div> */}
                                    </div>
                                    <div class="form-group">
                                        <label>{Strings.card_brand_web}*</label>
                                        <input disabled type="text" class="form-control s" maxLength={11} value={this.state.cardBrand} onChange={e => this.setState({ cardBrand: e.target.value })} tabIndex="3" />
                                        {/* <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.countryCodeError}</span>
                                            <span className='errorMsg'>{this.state.errors.phoneNumberError}</span>
                                        </div> */}
                                    </div>
                                    <div class="form-group">
                                        <label>{Strings.card_cat_web}*</label>
                                        <input type="text" class="form-control" maxLength={75} value={this.state.cardCategory} onChange={e => this.setState({ cardCategory: e.target.value })} tabIndex="4" />
                                        <div className="errorDiv">
                                            <span className='errorMsg'>{this.state.errors.categoryError}</span>
                                        </div>
                                    </div>
                                    <div class="signup">
                                        <a href="#" class="btn btn-primary btn-big" onClick={this.isValidate} tabIndex="5">{Strings.Save_web}</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMenu />
            </div>
        )
    }
}
function mapStateToProps(state) {
    console.log('isLoaging SignUp ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    cardDetailsApi: (mainObj) => dispatch(HGActions.AddCardDetailsApi(mainObj)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardDetails);