import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import HeaderMenu from '../Components/HeaderMenu';
import FooterMenu from '../Components/FooterMenu';
import { Strings } from '../constants/Localization';
import Constant from '../constants/baseConstants';
import {setSecureData, getSecureData} from '../Utils/utils';

class SavedCards extends Component {

    constructor(props) {
        super(props)

        this.state = {
           arrayCards: [],
           selectIndex: 0,
        }

    }

    componentDidMount() {

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // let userObject = localStorage.getItem(Constants.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
        }
        else {
            this.props.history.push({ pathname: '/' })
            return
        }

        // if(this.props.location.isCheckOut == true) {

            // this.props.getSavedCards(JSON.parse(localStorage.getItem(Constant.USEROBJECT)).customerId).then(() => {
            this.props.getSavedCards(getSecureData(Constant.USEROBJECT).customerId).then(() => {
                console.log("getSavedCards RESPONSE --------- ",this.props.data)
                if (this.props.data.status == 1) {
                    if (this.props.data.result != null) {
                        setTimeout(() => {
                            if (this.props.data.result.length > 0) {
                                this.setState({arrayCards:this.props.data.result})
                            }
                        }, 100)
                    }
                } else if (this.props.data.status == 2) {
                    //No Offers available

                } else {

                }
            })

        // } else {
        //     this.props.history.push('/')
        // }
    }

    onSelectCards(index) {
        console.log('On select ',index)
        this.setState({selectIndex: index})
    }

    renderCardsList() {
        let arr = []
        this.state.arrayCards.map((cards,index) => {
            arr.push(
                <li>
                    <button class="checked-btn" onClick={()=>{this.onSelectCards(index)}}><img src={this.state.selectIndex == index ? '/images/checked.png' : '/images/uncheck.png'}/></button><label class="container1">{cards.cardNo}</label>
                </li>
            )
        })
        return arr
    }

    render() {
        return(
            <div>
                <div class="cards cards-div">
                    <ul>
                    {(this.state.arrayCards) ? this.renderCardsList() : null}
                    </ul>
                </div>
                <div>
                    <a href='#' className="btn btn-small trans-btn" onClick={()=>{}}>CHECK OUT</a>
                </div>
                <div>
                    <a href='#' className="btn btn-small trans-btn" onClick={()=>{}}>NEW CARD</a>
                </div>
                <div>
                    <a href='#' className="btn btn-small trans-btn" onClick={()=>{}}>CANCEL</a>
                </div>
                
            </div>
        )
    }

}

function mapStateToProps(state) {
    console.log('isLoaging SavedCards ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    getSavedCards: (customerId) => dispatch(HGActions.initGetSavedCardsAPI(customerId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SavedCards);