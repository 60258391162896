import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter as Router, Switch, Link, history } from "react-router-dom";
import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import Constant from '../constants/baseConstants';
import HeaderSignin from '../Components/HeaderSignin';
import FooterMenu from '../Components/FooterMenu';
import { Strings } from '../constants/Localization';
import AlertDialog from '../Components/AlertDialog';
import {setSecureData, getSecureData} from '../Utils/utils';
import AOS from 'aos';
import 'aos/dist/aos.css';
import queryString from 'query-string';
import { ThemeProvider } from 'react-bootstrap';
import HeaderWhiteMenuHalf from '../Components/HeaderWhiteMenuHalf';
import moment from 'moment';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

class BookTableConfirm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customerId : 0,
            activeCardID : -1,
            isLoading:false,
            errors: {
                errBookingDate: '',
                errBookingTime: '',  
                errBookingSlotTime : '',              
            },
            isAlertVisible: false,
            alertMessage: '',
            btnLeft: '',
            btnRight: '',
            isMultiButton: false,
            dialogType:1, 
            languageType: 1, //default language type
            minBookingDate : '', 
            minBookingTime : '',
            totalPerson : 1, 
            bookingDate: '',
            bookingTime: '',
            componentLoadStep : 'step1',
            bookingTimeSloat  :[],
            bckBtnTable : '',
            payNowResponse : {},

            fromTime : '',
            toTime : '',
            noOfPeople : 1,
            tableNoOfPeople : 1,
            restaurantId : '',
            locationId : 1,
            languageId: 1,
            date : '',
            tableBookingPerPersonCharge : 5,
            tablePreBookingObj : null,
            bookingId :  0,
            contactDate : 'day month year',
            contactTime : '00 : 00',
            bookingDepositAmount : '0',
            tableNoOfPeople : 0,
            tableAreaType : "None",

        }
    }
    componentDidMount(){
        
        this.checkUserLogin()

        let params = queryString.parse(this.props.location.search)
        if (params.customerId) {
            console.log('query string -------------> ', params)
        }
        

        //Get messagelist from localstorage
        // let session = localStorage.getItem(Constant.PRE_MESSAGE_LIST);
        // let mLanguageType = localStorage.getItem(Constant.PRE_LANGUAGE_TYPE);
        
        let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                // this.setState({ languageType: mLanguageType })
                this.setState({ languageType: mLanguageType,languageTypeCode:"en-GB"})
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    // this.setState({ languageType: mLanguageType })
                    this.setState({ languageType: mLanguageType,languageTypeCode:"es"})
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        var today = new Date().toISOString().split('T')[0];
        this.setState({ minBookingDate : today})

        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        this.setState({ minBookingTime : today})

        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                if (userObject != null) {
                     this.setState({customerId : userObject.customerId});
                    console.log('login user :' + JSON.stringify(userObject));
                }
            }
        }

    }

    checkUserLogin = () => {
        // this.setState({isContentLoading:true})
        if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
            // get the key's value from localStorage
            // let userObject = localStorage.getItem(Constant.USEROBJECT);
            let userObject = getSecureData(Constant.USEROBJECT);
            if (userObject == null) {
                this.props.history.push({ pathname: '/' })
                return
            }
            if (userObject !== null) {
                // userObject = JSON.parse(userObject);
                this.setState({
                    customerId: userObject.customerId, name: userObject.firstname, countryCode: userObject.countryCode,
                    phoneNumber: userObject.phone, email: userObject.emailId, password: userObject.password, linkedCompanyName: userObject.linkedCompanyName
                })
                return
            }
            else {
                this.props.history.push({ pathname: '/' })
                return
            }

        }
        else {
            this.props.history.push({ pathname: '/' })
        }
    }



    handleTextFieldChange = (event) => {

        var todays = new Date().toISOString();
        console.log(todays)
        var today = new Date();
        const minutes = (today.getMinutes() < 10) ? `0${today.getMinutes()}` : today.getMinutes()
        var time = today.getHours() + ":" + minutes;
        

        if(event.target.name == 'bookingTime' && event.target.value < time){
            this.setState({ [event.target.name]: '' });
            this.setState({errors: { errBookingTime: 'Please select a booking time after current time.'} })
            return
        }
        else if(event.target.name == 'bookingTime' && event.target.value > time){
            this.setState({errors: { errBookingTime: ''} })
        }

        this.setState({ [event.target.name]: event.target.value });
        
    } 

    componentWillMount() {
        this.checkUserLogin()

        // tableObject.bookingId = this.state.tableBookDetailRow.Id
        // tableObject.contactTime = this.state.tableBookDetailRow.bookingtime
        // tableObject.contactDate = moment(this.state.tableBookDetailRow.displaybookingtime, 'DD MMM YYYY').format('MM/DD/yyyy')
        // tableObject.bookingDepositAmount = this.state.tableBookDetailRow.bookingAmount

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const bookingId = params.get('tableBookingId');

        if(bookingId != null){
            console.log("bookingId",bookingId)

            if(localStorage.getItem('tablePreBookingObj') != undefined && localStorage.getItem('tablePreBookingObj') != null){
                console.log("getSecureData('tablePreBookingObj',data)",localStorage.getItem('tablePreBookingObj'))
                var tableObject = JSON.parse(localStorage.getItem('tablePreBookingObj'))

                console.log("tablePreBookingObj", tableObject)
                this.setState({
                    bookingId :  bookingId,
                    contactDate : tableObject.contactDate,
                    contactTime : tableObject.contactTime,
                    bookingDepositAmount : tableObject.bookingDepositAmount,
                    tableNoOfPeople : tableObject.tableNoOfPeople,
                    tableAreaType : tableObject.tableAreaType,

                })
            }
            else{
                window.location.href = "/BookTable"
            }
            
        }
        else{
            window.location.href = "/BookTable"
        }
       
    }

    bookTablePayNow = () => {
            return (
                <div className="iframePayNow">
                    <iframe src={this.state.payNowResponse.paymentUrl} width='100%' height='100%' style={{top:0}}></iframe>
                </div>
            )
    }
    

    render() {
        return (
            <div className="loginpage white nonsticy" style={{}}> 
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}
                {/* <HeaderMenu /> */}
                {/* Old header */}
               <HeaderWhiteMenuHalf isHideOrderType={true} headerLbl={'TABLE BOOKING CONFIRMED'} isCategory={true} 
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true}/> 

                {/* <HeaderSignin logoType={1} headerLbl={Strings.reset_pass_web} isCategory={true} /> */}
                
                {(() => {
                      switch (this.state.componentLoadStep) {
                        case 'step1':
                            return this.bookTableConfirm()
                        // case 'step2':
                        //     return this.bookTableStep2()
                        // case 'step3':
                        //     return this.bookTableStep3()
                        // case 'step4':
                        //     return this.bookTablePayNow()
                        default:
                            return ''
                     }
                })()}
                <FooterMenu />
            </div>
        )
    }

     convertDate = (bdate) =>{
        let d = new Date(bdate)

        const year = d.getFullYear()
        const month = months[d.getMonth()]
        const date = d.getDate() 
        return date + ' ' + month + ' '+ year;
    }

    preOrderMenuItem = () => {
        // localStorage.getItem('tablePreBookingObj')
        var tableObject = {
            bookingId :  this.state.bookingId,
            contactDate : this.state.contactDate,
            contactTime : this.state.contactTime,
            bookingDepositAmount : this.state.bookingDepositAmount,
            tableNoOfPeople : this.state.tableNoOfPeople,
            tableAreaType : this.state.tableAreaType,
        }

        localStorage.setItem('tablePreBookingObj',JSON.stringify(tableObject))
        console.log("tablePreBookingObjlast",tableObject, JSON.stringify(tableObject))

        setTimeout(() => {
            window.location.href = "/MenuItems"
        },1000)
    }

    bookTableConfirm = () => { 

        return (
            <div className="content_wrap">
                    <div className="container">
                        <div className="book-tab-backbtn" style={{float: 'right'}}>
                                
                                {/* <a  className="btn btn-primary order-btn book-back-btn" href="/BookTableList" >Back</a> */}
                        </div>
                        <div className="user booktable-wrp fo-btm-spc">

                            <div className="row">
                                <div className="col-md-12  col-lg-6 center" style={{margin:'0 auto'}}>
                                    <h1 className="text-center">{'Table Booking Confirmed'}</h1>
                                    <hr />
                                    
                                    <img  className="congImg" src="images/thumbsup.png" alt="congratulations" />
                                    <br />

                                    <div className="congdiv">
                                        <h2 className="text-center">{'Congratulations'}</h2>

                                        <p  className="text-center" >You table is booked {this.state.tableAreaType == 1 ? 'Inside' : 'Outside'  } for <br/> <span className="bkTblDate" >{this.convertDate(this.state.contactDate)} { this.convertFrom24To12Format(this.state.contactTime) }</span> <br/> for {this.state.tableNoOfPeople} people  </p>
                                    </div>

                                    <div className="diduknow">
                                        <h3 className="text-center">Do You Know?</h3>
                                        <p className="text-center" >You can pre-order your food so that you don't need to wait at the table.</p>
                                    </div>
                                    

                                    <div className="checkoutBtn flex-btn-group book-btn-grp flex-dir-mob-rev" >
                                        <div className="buttons-checkout">
                                            <button className="btn btn-primary btn-medium" onClick={() => { window.location.href="/BookTable" }}>{Strings.order_later}</button>
                                        </div>
                                        <div className="buttons-checkout">
                                            <button className="btn btn-primary btn-medium" onClick={() => {this.preOrderMenuItem()}}>{Strings.pre_order}</button>
                                        </div>
                                        
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }



    setTimesloat = (val) => {
        
        
        this.setState({
            // componentLoadStep : 'step3',
            activeCardID : val.tId,
            fromTime : val.fromTime,
            toTime : val.toTime,
            tableNoOfPeople : val.tableNoOfPeople,
        })

        // {"restaurantId":147,"locationId":363,"customerId":33006,"languageId":1,"date":"05/31/2021","fromTime":"18:30","toTime":"19:00","noOfPeople":1,"tableNoOfPeople":2}
    }

    convertFrom24To12Format = (time24) => {
        const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
        const period = +sHours < 12 ? 'AM' : 'PM';
        const hours = +sHours % 12 || 12;
      
        return `${hours}:${minutes} ${period}`;
      }

}

function mapStateToProps(state) {
    console.log('isLoaging ResetPassword ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    getTableReservationTimeSlotList: (customerId,locationId, languageId, bookingDate, BookingTime,totalPerson) => dispatch(HGActions.getTableReservationTimeSlotList(customerId,locationId, languageId, bookingDate, BookingTime, totalPerson)),
    addTableReservation: (data) => dispatch(HGActions.addTableReservation(data)),
    AppMessageApi: (languageType) => dispatch(HGActions.GetAppMessageListApi(languageType)),
    GetLocationListApi: (languageType) => dispatch(HGActions.GetLocationListApi(languageType)),

})
export default connect(mapStateToProps, mapDispatchToProps)(BookTableConfirm);