import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import baseConstants from "../constants/baseConstants";

const PaymentComplete = () => {
  const { id } = useParams();
  console.log("dhruvin quarry", id); // "top"
  useEffect(() => {
    localStorage.removeItem(baseConstants.USERCART);
  }, []);

  return (
    <div>{id == ":ok" ? <Redirect to="/" /> : <Redirect to="/MyOrders" />}</div>
  );
};

export default PaymentComplete;
