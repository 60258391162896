import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as HGActions from '../Redux/actions/HGActions';
import HeaderWhiteMenuHalf from '../Components/HeaderWhiteMenuHalf';
import FooterMenu from '../Components/FooterMenu';
import { Strings } from '../constants/Localization';
import Constant from '../constants/baseConstants';
import {setSecureData, getSecureData} from '../Utils/utils';
import {getCurrencySign} from "../Utils/utils";

class Offers extends Component {

    constructor(props) {
        super(props)

        this.state = {
           offersArray: [],
           selectIndex: 0,
           languageType: 1, //default language type
        }

    }

    componentDidMount() {

        // if (localStorage.hasOwnProperty(Constant.USEROBJECT)) {
        //     // let userObject = localStorage.getItem(Constants.USEROBJECT);
        //     let userObject = getSecureData(Constant.USEROBJECT);
        //     if (userObject == null) {
        //         this.props.history.push({ pathname: '/' })
        //         return
        //     }
        // }
        // else {
        //     this.props.history.push({ pathname: '/SignIn' })
        //     return
        // }

       let session = getSecureData(Constant.PRE_MESSAGE_LIST);
        let mLanguageType = getSecureData(Constant.PRE_LANGUAGE_TYPE);
        console.log("default lanague type  :" + mLanguageType);
        if (session != null && session.length > 0) {
            // session = JSON.parse(session);
            if (mLanguageType != null && mLanguageType == 1) {
                Strings.setLanguage('en'); //Setting selected language code here
                // this.setState({ languageType: mLanguageType })
                this.setState({ languageType: mLanguageType, languageTypeCode: "en-GB" })
            }
            else {
                if (mLanguageType != null) {
                    Strings.setLanguage('es'); //Setting selected language code here
                    // this.setState({ languageType: mLanguageType })
                    this.setState({ languageType: mLanguageType, languageTypeCode: "es" })
                }
            }
        }
        else {
            Strings.setLanguage('en');//Setting default language code here
        }

        setTimeout(() => {
            let locationId = getSecureData(Constant.LOCATIONOBJECT).locationId
            // let customerId = getSecureData(Constant.USEROBJECT).customerId
            let customerId = 0
            this.props.getOffersList(customerId, locationId, this.state.languageType).then(() => {
                console.log("get offers RESPONSE --------- ",this.props.data)
                if (this.props.data.status == 1) {
                    if (this.props.data.result != null) {
                        setTimeout(() => {
                            if (this.props.data.result.offerList.length > 0) {
                                // this.setState({offersArray:this.props.data.result.offerList})
                                let obj = this.props.data.result.offerList
                                // for (var i = 0 ; i < 5; i ++) {
                                //     obj.push(this.props.data.result.offerList)
                                //     console.log(i)
                                // }
                                this.setState({offersArray: obj})
                                // this.setState(prevState => ({
                                //     array: [...prevState.offerList, obj]
                                // }))
                                
                            }
                        }, 100)
                    }
                } else if (this.props.data.status == 2) {
                    //No Offers available

                } else {

                }
            })
        }, 500);
        
    }

    onSelectCards(index) {
        console.log('On select ',index)
        this.setState({selectIndex: index})
    }

    renderAmount(cards) {

        var str = ''
        if (cards.offerType == 1) {//percentage
            str = cards.discount+'%'
            return (
                // <div class="row1"><div class="d1"><span class="titleb">Amount : </span><span className="nrml"> {str}</span></div></div>
                // <div class="row1"><div class="d1"><span class="titleb">{str} Off</span></div></div>
                // <p class="lbl">£</p>
                <p class="heading--lg moss">{str} Off</p>
            )
        } else if (cards.offerType == 2) {//amount
            str = cards.discount
            return (
                // <div class="row1"><div class="d1"><span class="titleb">Amount : </span><span className="nrml"> {str}</span></div></div>
                // <div class="row1"><div class="d1"><span class="titleb">{str} Off</span></div></div>
                // <div><p class="lblcurrsign">{getCurrencySign()}</p>
                <p class="heading--lg moss"><label class="lblcurrsign">{getCurrencySign()}</label>{str} Off</p>
            )
        }
        // return (
        //     // <div class="row1"><div class="d1"><span class="titleb">Amount : </span><span className="nrml"> {str}</span></div></div>
        //     // <div class="row1"><div class="d1"><span class="titleb">{str} Off</span></div></div>
        //     // <p class="lbl">£</p>
        //     <p class="heading--lg moss">{str} Off</p>
        // )
        
    }

    renderTitle(data,i) {
        if (i == 0 || i == 3 || i == 6) {
            return(
                <h2 className="heading--xl moss">{data.offerTitle}</h2>
            )
        } else if (i == 1 || i == 4 || i == 7) {
            return(
                <h2 className="heading--xl raspberry">{data.offerTitle}</h2>
            )
        } else if (i == 2 || i == 5 || i == 8) { 
            return(
                <h2 className="heading--xl blue">{data.offerTitle}</h2>
            )
        }
    }

    renderDescription(data,i) {
        if (i == 0 || i == 3 || i == 6) {
            return(
                <p className="heading--lg moss">{data.offerDescription}</p>
            )
        } else if (i == 1 || i == 4 || i == 7) {
            return(
                <p className="heading--lg raspberry">{data.offerDescription}</p>
            )
        } else if (i == 2 || i == 5 || i == 8) { 
            return(
                <p className="heading--lg blue">{data.offerDescription}</p>
            )
        }
        return(
            <p class={i % 2 == 0 ? "heading--lg moss" : "heading--lg raspberry"}>{data.offerDescription}</p>
        )
    }

    renderCardsList() {
        let arr = []
        this.state.offersArray.map((cards,index) => {
            arr.push(

                <div class="flexible-box__row">
                        <div class="flexible-box__col heading">
                        {this.renderTitle(cards,index)}
                        {this.renderDescription(cards,index)}
                        
                        {/* {this.renderAmount(cards)} */}
                    </div>
                    <div class="flexible-box__col image">
                        <img src={ (cards.offerImg != '' || cards.offerImg) ? cards.offerImg :'/images/no.jpg'} />
                    </div>
                </div>
            )
{/* <div class="all-r">
    <div class="row1"><div class="d1"><span class="titleb">{cards.offerTitle}</span></div></div>
    <div class="row2"><div class="d1"><span class="titleb">Valid from</span> {cards.dispalyfromDate}</div><div class="d1"><span class="titleb">to</span> {cards.dispalytoDate}</div></div>
    {this.renderAmount(cards)}
</div> */}
        })
        return arr
    }

    render() {
        return(
            <div className="white dishes">
                {this.props.isLoading ? <div className="loadermain" style={{ display: 'block' }}>
                    <div className="loader" ></div>
                </div> : <div className="loadermain" style={{ display: 'none' }}>
                        <div className="loader" ></div>
                    </div>}
                {/* <HeaderMenu /> */}
                {/* Old header */}
                <HeaderWhiteMenuHalf isHideOrderType={true} isOffer={true}
                    changeLanguageEvent={this.languageChangeClickEvent} isCategory={true} />

                <div className="content_wrap">
                    <div className="container">
                    <div class="hero__block">
                    <div class="hero__col 
                            border-raspberry">
                    <div>
                    <p class="heading--md">
                    What’s better than one cocktail? You can enjoy 2-for-1
                    on selected Cubana cocktails everyday from 4-7pm during Happy Hour at Cubana Waterloo
                    </p>
                    </div>
                    </div>
                    <div class="hero__content" >
                    <div>
                    <div>That’s some of the best Happy Hour cocktails – including Cubana’s legendary Mojitos and cocktails prepared with fresh, tropical fruits and juices – from just £5.50 for two and two jugs from £14.00!</div>
                    </div>
                    <div></div>
                    <div>Cubana’s Happy Hour is from 4-7pm, seven-days a week at Cubana Waterloo – available from our Street Bar or inside Cubana whether you are eating as well or just drinking</div>
                    <div></div>
                    <div>Come and try our lunchtime Cantina Lunch – freshly-prepared mains for £7 and two-courses for just £10 – Mondays to Thursdays and Saturdays at Cubana Waterloo and Thursdays at Cubana Smithfield</div>
                    <div></div>
                    <div>Cubana’s Pre-theatre Menu is served from 3.30-6.30pm – two-courses for £12 and three-courses £14 – Mondays-Thursdays and Saturdays at Cubana Waterloo</div>
                    <div></div>
                    <div></div>
                    </div>
                    </div>
                        {/* <div className="user oList">

                            <div className="row">
                                <div className="col-md-12  col-lg-12 center">
                                <div class="OfferList">
                    

                        <div class="data-table">
                            {(this.state.offersArray.length) ? this.renderCardsList() : <div className="listError">
                                <p>No data found</p>
                            </div>}
                        </div>
                </div>
                                </div>
                                </div>
                                </div> */}
                                <div class="flex-wrp">
                                    
                                {(this.state.offersArray.length) ? this.renderCardsList() : <div className="listError">
                                {/* <p>No data found</p> */}
                            </div>}
                                    {/* <div class="flexible-box__row">
                                    <div class="flexible-box__col heading">
                                    <h2 class="heading--xl raspberry">
                                    Freshly-prepared dishes! 
                                    </h2>
                                    <p class="heading--lg raspberry">Takeaway, Delivery and Click&amp;Collect – every day!</p>
                                    </div>
                                    <div class="flexible-box__col image"></div>

                                    </div>

                                    <div class="flexible-box__row">
                                    <div class="flexible-box__col heading">
                                    <h2 class="heading--xl blue">
                                    Freshly-prepared dishes! 
                                    </h2>
                                    <p class="heading--lg blue">Takeaway, Delivery and Click&amp;Collect – every day!</p>
                                    </div>
                                    <div class="flexible-box__col image"></div>

                                    </div> */}
                                    </div>
                    </div>
                </div>

                            
                
                <FooterMenu />
            </div>
        )
    }

}

function mapStateToProps(state) {
    console.log('isLoaging SavedCards ------- ' + state.HGReducers.isLoading);
    return {
        isLoading: state.HGReducers.isLoading,
        data: state.HGReducers.data,
        error: state.HGReducers.error
    }
}

const mapDispatchToProps = dispatch => ({
    getOffersList:(customerId, locationId, languageId) => dispatch(HGActions.initGetBestOfferListAPI(customerId, locationId, languageId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Offers);